.content-item-loader {
  @include size(100%, 100%);
  top: 0;
  position: fixed;
  z-index: 999;
  background-color: rgba(255,255,255, 0.15);

  > div {
    top: 50%;
    position: absolute;
    left: 50%;
    padding: 4px;

    > div {
      margin-left: -50%;
      margin-top: -50%;
      background-repeat: no-repeat;
      background-size: 50%;
      background-image: $loader;
      background-color: $loader-bg;
      border-radius: 6.25rem;
      background-position: 50% 50%;
    }
  }
}

circle {
    stroke-width: 3 !important;
    stroke: darken($brand-primary, 100%) !important;
    margin: -4px;
}


// Button loader indicator

$offset: 187;
$duration: 1.4s;

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

@keyframes dash {
    0% { stroke-dashoffset: $offset; }

    50% {
        stroke-dashoffset: $offset*0.25;
        transform:rotate(135deg);
    }

    100% {
        stroke-dashoffset: $offset;
        transform:rotate(450deg);
    }
}

.spinner {
    display: none;
    animation: rotator $duration linear infinite;

    &.visible {
        position: relative;
        top: 2px;
        display: inline-block;
    }

    .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        stroke: $white !important;
        animation: dash $duration ease-in-out infinite;
    }

    circle {
        stroke-width: 8 !important;
    }
}
