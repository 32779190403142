//== Position Mixin
//
// With the position mixin elements can be easy positioned across the document.
// Supports position fixed with an optional boolean value
//
// @example: .pos-t-l(40px, 20px)
// @output:
//      position: absolute;
//      top: 40px;
//      left: 20px;
// --------------------------------------------------

	@mixin position-type($fixed) {
		@if ($fixed == false) {
			position: absolute;
		} @else if ($fixed == true) {
			position: fixed;
		}
	}
	
	@mixin position-reset($relative) {
		@if ($relative == true) {
			position: static;
		} @else if ($relative == false) {
			position: relative;
		}
	}

// Position types
// --------------------------------------------------

	@mixin pos-t-l($pos-top: 0, $pos-left: 0, $fixed: false) {
		@include position-type($fixed);
		top: $pos-top;
		left: $pos-left;
	}

	@mixin pos-t-r($pos-top: 0, $pos-right: 0, $fixed: false) {
		@include position-type($fixed);
		top: $pos-top;
		right: $pos-right;
	}

	@mixin pos-b-l($pos-bottom: 0, $pos-left: 0, $fixed: false) {
		@include position-type($fixed);
		bottom: $pos-bottom;
		left: $pos-left;
	}

	@mixin pos-b-r($pos-bottom: 0, $pos-right: 0, $fixed: false) {
		@include position-type($fixed);
		bottom: $pos-bottom;
		right: $pos-right;
	}

	@mixin pos-x($pos-right: 0, $pos-left: 0, $fixed: false) {
		@include position-type($fixed);
		right: $pos-right;
		left: $pos-left;
	}

	@mixin pos-y($pos-top: 0, $pos-bottom: 0, $fixed: false) {
		@include position-type($fixed);
		top: $pos-top;
		bottom: $pos-bottom;
	}

	@mixin pos-a($pos-top: 0, $pos-right: 0, $pos-bottom: 0, $pos-left: 0, $fixed: false) {
		@include position-type($fixed);
		top: $pos-top;
		right: $pos-right;
		bottom: $pos-bottom;
		left: $pos-left;
	}

	@mixin pos-l($pos-top: 0, $pos-bottom: 0, $pos-left: 0, $fixed: false) {
		@include position-type($fixed);
		top: $pos-top;
		bottom: $pos-bottom;
		left: $pos-left;
	}

	@mixin pos-r($pos-top: 0, $pos-bottom: 0, $pos-right: 0, $fixed: false) {
		@include position-type($fixed);
		top: $pos-top;
		bottom: $pos-bottom;
		right: $pos-right;
	}

	@mixin pos-t($pos-top: 0, $pos-right: 0, $pos-left: 0, $fixed: false) {
		@include position-type($fixed);
		top: $pos-top;
		right: $pos-right;
		left: $pos-left;
	}

	@mixin pos-b($pos-bottom: 0, $pos-right: 0, $pos-left: 0, $fixed: false) {
		@include position-type($fixed);
		bottom: $pos-bottom;
		right: $pos-right;
		left: $pos-left;
	}
	
	@mixin pos-x-c($fixed: false) {
		@include position-type($fixed);
		transform: translate(-50%, 0);
		left: 50%;
	}

	@mixin pos-y-c($fixed: false) {
		@include position-type($fixed);
		transform: translate(0, -50%);
		top: 50%;
	}

	@mixin pos-c-c($fixed: false) {
		@include position-type($fixed);
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
	}

	@mixin pos-reset($relative: false) {
		@include position-reset($relative);
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
	}
	