
@use "sass:math";
.result {
    @include make-col-ready();
    @include make-col(12);
    position: relative;
    margin-bottom: $grid-gutter-width-base;

    .result-inner {
        background: $result-body;
    }

    .inclusive-stay-wrapper {
        position: relative;
        height: 90px;
        .inclusive-stay-absolute-position {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    @media (max-width: 768px) {
        .inclusive-stay-wrapper {
            height: 20px;
        }
    }
    .result-left {
        @include make-col-ready();
        @include media-breakpoint-up(md) {
            @include make-col(4);
        }


        .carousel {
            .carousel-item  {
                img {
                    @include media-breakpoint-up(lg) {
                        max-height: 320px;
                        width: 100%;
                        object-fit: cover;
                    }

                    @include media-breakpoint-down(lg) {
                        max-height: none;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                a {
                    width: inherit;
                }
            }
        }
    }

    .result-right {
        .inner {
            padding-top: $result-inner-top-offset;
        }

        .date {
            @include media-breakpoint-only(xl) {
                margin: 5px 0 !important;
            }
        }

        .list-checked {
            font-size: 0.9rem;

            li {
                padding-left: 25px;
                font-size: 0.9375rem;
                margin-bottom: 5px;

                @include media-breakpoint-down(md) {
                    font-size: 13px;
                }


                .material-icons {
                    position: relative;
                    top: -2px;
                    margin-left: 3px;
                    font-size: 1.25rem;
                    cursor: pointer;
                    color: $taupe-light;
                }
            }
        }

        .btn-holder {
            position: relative;
            margin: $result-btn-spacing !important;
            background-color: #fff;

            @include media-breakpoint-only(lg) {
                margin: 5px 0 !important;
            }

            @include media-breakpoint-only(lg) {
                margin-top: round(($spacer-y * $spacer-ratio-sm));
                font-size: 12px;
            }

            @include media-breakpoint-only(md) {
                font-size: 13px;
            }

            @include media-breakpoint-down(sm) {
                display: inline-block;
                font-size: 12px;
            }
        }

        .btn-link-holder {
            margin-top: 10px;

            .btn-link {
              font-size: 16px;
            }
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            @include make-col-ready;
            position: static;
            padding-right: 30px !important;
            padding-bottom: 15px;
            padding-left: 30px !important;

            @include media-breakpoint-down(sm) {
                padding-top: 15px;
            }

            .price-tag {
                @include make-col(6);
                padding-bottom: ($padding-large-vertical * 2);
                background-size: $price-bg-size-sm;
                top: 0;
                right: 0;
            }

            .date {
                margin-top: 0 !important;

                span {
                    font-size: 1rem;
                }
            }

            .result-holder {
                @include make-col(12);
                margin-top: 0 !important;
                padding-left: 0;
                padding-right: 0;
            }

            .btn {
                width: 100%;
                margin-top: 1.25rem !important;
                display: block;
                font-size: 0.875rem;

                @include media-breakpoint-down(sm) {
                    width: auto;
                    margin-top: 0 !important;
                    display: inline-block;

                    &:nth-child(2) {
                        position: absolute;
                        right: 15px;
                        margin-left: 0 !important;
                    }
                }
            }

            .text-discount {
                width: 100%;
                display: block;
                margin-top: math.div($spacer, 1.5) !important;
            }
        }
    }

    .properties {
        @extend .p-0;

        margin: 5px 0 10px !important;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
            // max-height: 60px;
        }

        @include media-breakpoint-down(sm) {
            max-height: none;

            .property {
                @include make-col(12);
            }
        }
    }

    &.secret {
        .result-right {
            @include media-breakpoint-up(md) {
                @include make-col(8);
            }
        }
    }

    h2 {
        text-transform: none;
        font-weight: bold;
        font-size: 26px;

        a {
            text-decoration: none;
        }

        @include media-breakpoint-down(lg) {
            font-size: 20px;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: $grid-gutter-width-base * 0.5;
        }
    }

    p {
        @extend .m-y-sm;

        i {
            color: $brand-tertiary !important;
        }
    }

    .result-links {
        @extend .m-t-md;

        @include media-breakpoint-only(lg) {
            margin-top: round(($spacer-y * $spacer-ratio-sm));
            font-size: $font-size-small;
        }
    }

    .btn-link {
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;
    }

    &.secret {
        .inner {
            padding-right: $grid-gutter-width;
        }

        .input-group {
            margin-top: $grid-gutter-width;
        }

        .placeholder {
            @include size(200px, 200px);
            @include pos-c-c;
            @include retina-image($result-secret-deal, $result-secret-deal2x, 200px, 200px);
            display: block;
            background-size: 100%;
        }
    }
}

.result-secret {
    .result-left {
        position: relative;

        .outline {
            @include size($secret-deal-sb-width, $secret-deal-sb-height);
            @include pos-t-l($secret-deal-sb-top, $secret-deal-sb-left);
            z-index: 20;
            display: block;
            background: url($secret-deal-outline);
            background-size: cover;

            .grid & {
                height: $secret-deal-sb-grid-height;
                top: $secret-deal-sb-grid-top ;
                left: $secret-deal-sb-grid-left;
            }
        }
    }
}

.date {
    position: relative;
    padding-left: ($padding-base-horizontal * 2.5);
    font-size: 1rem;

    &:before {
        @include mi("\E8DF"); //.mi("today");
        position: absolute;
        top: 0;
        left: 0;
        color: $brand-secondary;
    }

    @include media-breakpoint-down(lg) {
        span {
            display: block;
            font-size: $font-size-small;
        }
    }
}



// Disabled (vol) results
.row-disabled {
    .price-tag {
        @extend .p-3;
        background-color: $price-tag-background-unavailable;

        font-size: 12px;
        font-weight: 700;

        @include media-breakpoint-only(lg) {
            font-size: 18px;

            .grid & {
                font-size: 14px;
            }
        }

        @include media-breakpoint-only(md) {
            font-size: 16px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }

        .zero-top {
            top: 0 !important;
        }
    }

    .date-holder {
        display: none;
    }
}


.grid, .map {
    .row-disabled {
        .price {
            color: $black !important;
            padding: $price-grid-full-padding !important;
        }
    }
}

.grid, .map, .map-wrapper {
    position: relative;

    .result {
        background: transparent;

        &.secret {
            .result-right {
                @include media-breakpoint-up(md) {
                    @include make-col(12);
                }
            }
        }
    }

    .result-right {
        .btn-holder {
            @extend .m-t-0;
        }

        .pricing {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .extra {
            @include media-breakpoint-down(sm) {
                display: block !important;
            }
        }

        .date {
            @extend .m-y-sm;
            min-height: 42px;
        }

        @include media-breakpoint-down(sm) {
            .price-tag {
                width: 124px;
            }

            .date {
                font-size: $font-size-base;

                span {
                    font-size: inherit;
                }
            }

            .result-holder {
                width: 100%;
            }
        }
    }

    .result-holder {
        .btn {
            @extend .m-b-sm;
        }
    }
}

.map-wrapper {
    .result {
        width: 315px;
        @include pos-t-r(70px, 0px);
        z-index: 100;

        .text-discount {
            word-break: normal;
        }
    }
}

.result-number {
    @include retina-image('/img/map/result-number-tooltip.png', '/img/map/result-number-tooltip@2x.png', 65px, 84px);
    width: 65px;
    height: 84px;

    .laurel & {
        @include retina-image('/img/map/result-number-tooltip-db.png', '/img/map/result-number-tooltip@2x-db.png', 65px, 84px);
    }

    position: absolute;
    top: -66px;
    left: 7px;

    .result-id {
        @include pos-t-l(50%, 50%);
        transform: translate(-50%, -62%);
        font-size: 26px;
        color: #fff;
    }
}

.result-close {
    position: absolute;
    z-index: 99;
    top: -15px;
    right: -15px;
    font-size: 30px !important;
    cursor: pointer;
}

.result-footer {
    @extend .py-2;
    background: #ffeb9a;

    p {
        display: inline-block;
    }
}


// Styling fixes grid

.result {
    // background: #fff;

    .row-disabled {
        .price {
            color: $body-color;
        }
    }
}

.result-right {
    min-height: 0;
    .price-holder {
        &.flex-end {
          justify-content: flex-end;
          width: 100%;
        }
      }
}

.result-holder {
    height: 80px;
    margin-bottom: 10px;
    background-color: #fff;
    .btn {
        position: absolute;
        bottom: 1.875rem;
        left: 1.25rem;
    }
}

.price-tag {
    width: 100%;

    .price {
        font-size: 1.55rem;
        text-align: left;
        padding-left: 5px;

        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
    }

    s {
        text-align: left;
        padding-left: 5px;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }
}


.result-left {
    @include make-col-ready();
    @include media-breakpoint-only(xl) {
        @include make-col(4);
    }

    @include media-breakpoint-only(lg) {
        @include make-col(3.7);
    }

    @include media-breakpoint-only(md) {
        @include make-col(4);
    }

    .price-holder {
        display: none;

        @include media-breakpoint-down(sm) {
            display: block !important;
        }

    }

    @include media-breakpoint-only(md) {
        padding-right: 0 !important;
    }

    @include media-breakpoint-down(sm) {
        .price-holder {
            @include pos-t-r;
            top: auto;
            bottom: 0;
            display: block;
        }

        .text-discount {
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            font-size: 14px !important;
            font-weight: 400;
        }
    }

    @include media-breakpoint-only(sm) {
        .price-holder {
            width: 40%;
        }
    }

    @include media-breakpoint-only(xs) {
        .price-holder {
            width: 60%;
        }
    }

    @media (max-width: 320px) {
        .price-holder {
            width: 75%;
        }
    }
}

.result-right {
    position: relative;

    @include media-breakpoint-only(xl) {
        @include make-col(8);
        @include make-col-ready();
    }

    @include media-breakpoint-only(lg) {
        @include make-col(8.3);
        @include make-col-ready();
    }

    @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-ready();

        .details {
            @include make-col-ready();
            @include make-col(5.5);
            margin-top: 25px;
            // border-right: 2px solid $beige-dark;

            h2 {
                padding-bottom: 5px;
            }

            @include media-breakpoint-only(lg) {
                @include make-col(7);
                border-right: 0;
            }

            @include media-breakpoint-only(md) {
                @include make-col(7);
                margin-top: 0 !important;
                padding: 15px 0 15px 15px !important;
                border-right: 0;

                h2 {
                    padding-bottom: 0;
                }

                p {
                    height: 65px;
                    overflow: hidden;
                    margin-bottom: math.div($grid-gutter-width-base, 6) !important;
                    font-size: 14px !important;
                }

                .properties {
                    min-height: 0;
                    margin: 0 !important;
                }

                .property {
                    min-height: 0;
                    margin-bottom: $grid-gutter-width * 0.1;
                    font-size: 12px;
                }
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }

        .extra {
            @include make-col-ready();
            @include make-col(2.85);
            margin-top: 20px;
            padding-right: 10px !important;

            .extra-header {
                font-size: 15px;
                margin-bottom: 5px;
            }

            @include media-breakpoint-between(md, lg) {
                display: none;
            }
        }

        .pricing {
            @include make-col-ready();
            @include make-col(3.65);

            @include media-breakpoint-only(lg) {
                @include make-col(5);

                .details-included {
                    display: block;
                }
            }

            @include media-breakpoint-only(md) {
                @include make-col(5);

                .price-tag {
                    &.compact {
                        padding: math.div($grid-gutter-width-base, 3);
                    }

                    .price {
                        font-size: 1.5rem;
                    }

                    .text-discount {
                        font-size: 12px !important;
                    }
                }

                .details-included {
                    display: block !important;
                    margin-top: 2px;
                }
            }
        }

        @include media-breakpoint-only(md) {
            .btn-row {
                padding-bottom: 15px;

                .btn {
                    padding: 5px 10px;
                    font-size: 12px;
                }

                .btn-link {
                    padding-left: 5px;
                    font-size: 14px !important;
                }
            }
        }
    }

    .pricing {
        .details-included {
            @extend .hidden-sm-down;

            @include media-breakpoint-only(lg) {
                font-size: 16px !important;
                margin: 10px 10px 3px !important;
                padding: 0 !important;
            }
        }
    }

    .details {
        .date-holder {
            display: none;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            @include make-col-ready;

            p {
                display: none;
            }

            .date-holder {
                display: block;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .extra, .price-holder, .date-holder {
            display: none;
        }
    }

    .availability {
        margin: .75rem !important;
        font-size: 16px;

        .grid & {
            @include pos-t-r;
            width: 40%;
            top: auto;
            right: 5px;
            bottom: -4px;
            text-align: right;

            @include media-breakpoint-only(lg) {
                bottom: 5px;
                font-size: 14px;
            }

            @include media-breakpoint-only(md) {
                position: static;
                margin: 0 0 0 15px !important;
            }
        }

        @include media-breakpoint-only(lg) {
            margin: 3px 10px !important;
        }

        @include media-breakpoint-only(md) {
            margin: 0 0 0 10px !important;
            font-size: 12px;
        }

        @include media-breakpoint-down(sm) {
            @include pos-t-r;
            width: 40%;
            top: auto;
            right: 20px;
            bottom: 67px;
            text-align: right;
        }
    }
}

.date-holder {
    background: #fff;
    padding: 10px 15px;

    .date {
        font-size: 15px;

        .grid & {
            font-size: 16px;

            @include media-breakpoint-only(md) {
                font-size: 12px;

                span {
                    font-size: inherit;
                }
            }
        }
    }

    @include media-breakpoint-only(md) {
        padding: 5px 0 5px 10px;

        .date {
            margin: 0;
            padding-left: 25px;

            &:before {
                top: 4px;
                font-size: 18px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: math.div($grid-gutter-width-base, 3);
        padding: 0 0 math.div($grid-gutter-width-base, 3);
        border-bottom: 2px solid $white;
        background: transparent;
    }
}

.details-included {
    display: none;
    cursor: pointer;

    .material-icons {
        color: $main;
        position: relative;
        top: -2px;
        margin-left: 2px;
        cursor: pointer;
    }

    @include media-breakpoint-only(md) {
        margin: 10px 0 2px 10px !important;
        padding: 0 !important;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        display: block;
        margin-bottom: 10px;
        padding: 0 15px !important;
        text-align: right;
        font-size: 16px;
    }
}

.btn-row {
    @include size(100%, auto);
    @include pos-b-l;
    padding: 0 0 10px 10px;

    @include media-breakpoint-down(sm) {
        width: auto;
        position: static;
        padding: 0;

        > div:first-child {
            // display: none;
        }

        .btn-compare {
          display: none !important;
        }
    }
}


// Button compare in search result
.btn-compare {
    text-decoration: underline !important;
    text-transform: none !important;

    @include media-breakpoint-only(md) {
        .result-highlight & {
            &:hover, &:focus {
                color: $white !important;
            }
        }
    }

    .result & {
        padding-left: 5px;
        font-size: 16px !important;
    }
}


//Grid
.grid {
    .row-cs {
        margin-right: -20px;
        margin-left: -20px;
    }

    .result {
        @include media-breakpoint-down(sm) {
            flex: 0 0 100% !important;
            max-width: 100% !important;
            flex: none;
        }

        h2 {
            @include media-breakpoint-down(sm) {
                font-size: 24px;
            }
        }
    }

    .btn-compare {
        font-size: 14px !important;
        @include media-breakpoint-only(sm) {
            display: none !important;
        }
    }

    .extra {
        margin-top: 5px !important;

        p {
            display: none !important;
        }

        .list-checked {
            height: 100%;
            overflow: hidden;
            @include media-breakpoint-down(sm) {
                height: 100%;
            }
        }
    }

    .result {
        @include make-col(4);
        padding-right: 20px;
        padding-left: 20px;

        &:not(.result-highlight) {
            .row {
                @extend .mx-0;
            }

            .row-disabled {
                .price-tag {
                    @include media-breakpoint-only(md) {
                        padding: math.div($grid-gutter-width-base, 3) !important;
                        font-size: 14px;
                    }
                }

                .btn-row {
                    @include media-breakpoint-only(lg) {
                        bottom: 10px !important;
                    }

                    @include media-breakpoint-only(md) {
                        bottom: 10px !important;
                    }
                }
            }

            .price-tag {
                width: 200px;
                float: right;
                margin-bottom: 20px;
                min-height: 70px;

                @include media-breakpoint-down(lg) {
                    max-width: 160px;
                }

                @include media-breakpoint-between(md, lg) {
                    width: 230px;
                    padding: math.div($grid-gutter-width-base, 3);

                    .price {
                        font-size: 1.35rem;
                    }

                    .text-discount {
                        font-size: 12px !important;
                    }
                }
                @include media-breakpoint-down(sm) {
                    bottom: -25px;
                }

                @include media-breakpoint-only(md) {
                    width: 170px;
                }

                @include media-breakpoint-only(lg) {
                    .price {
                        font-size: 1.45rem;
                    }
                }
            }

            .details {
                @extend .mt-0;
                border: none;
            }

            .details-inner {
                p, ul {
                    display: none;
                }
            }

            .pricing {
                margin-bottom: $spacer-x * .5;
                padding-bottom: $spacer-x * .5;

                @include media-breakpoint-down(sm) {
                    margin: 0;
                }

                .clearfix {
                    display: flex;
                }

                .details-included {
                    display: none !important;
                }
            }

            .date-holder {
                @include make-col(12);
                @extend .p-0;
                background: none;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 0;
                    border-bottom: none;
                }

                .date-none {
                    @include media-breakpoint-down(sm) {
                        font-size: .9375rem;
                    }
                }
            }

            .price-holder {

                @include media-breakpoint-down(sm) {
                    display: block !important;
                    position: relative !important;
                }

                .material-icons {
                    @include media-breakpoint-down(sm) {
                        margin-top: 5px;
                    }
                }
                .price-tag {
                    @include media-breakpoint-down(sm) {
                        bottom: 0px !important;
                    }

                    .text-discount {
                        @include media-breakpoint-down(sm) {
                            height: auto;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-size: 14px;
                            right: 0px !important;
                            text-align: right;
                            padding-right: 10px;
                            margin-top: 0px !important;
                            width: 50%;
                        }
                    }
                }
            }

            @include media-breakpoint-only(lg) {
                .date {
                    span {
                        font-size: .875rem;
                    }
                }
            }

            @include media-breakpoint-only(md) {
                .date {
                    span {
                        font-size: .875rem !important;
                    }
                }
            }

            .avalability {
                @include make-col-offset(1.5);
                @include make-col(3.5);
                @extend .m-0;
                @extend .p-0;
                @extend .text-left;

                @include media-breakpoint-only(lg) { font-size: 14px; }
            }

            .result-left,
            .result-right {
                @include make-col(12);
                @extend .px-0;
            }

            .result-left {
                .carousel-item {
                    max-height: 200px;

                    img {
                        max-width: 100%;
                        width: 100%;
                        height: auto;
                        max-height: 200px;
                        object-fit: cover;
                        @include media-breakpoint-down(md) {
                            max-height: 150px;
                        }
                        @include media-breakpoint-down(sm) {
                            max-height: 200px;
                        }
                    }
                }

                .price-holder {
                    display: block;
                    @include media-breakpoint-down(sm) {
                        position: relative;
                        display: block !important;
                        margin-bottom: 25px;
                    }
                }
            }

            .result-right {
                padding-bottom: $grid-gutter-width-base * 0.5;

                @include media-breakpoint-only(xl) {
                    height: 465px;
                }
                @include media-breakpoint-only(lg) {
                    height: 520px;

                    .extra {
                        display: block !important;

                        .list-checked {
                            height: 100px;
                            overflow: hidden;
                        }
                    }
                }

                .price-holder {
                    @include media-breakpoint-only(sm) {
                        position: relative;
                        display: block !important;
                        margin-bottom: 25px;
                    }
                }
                @include media-breakpoint-only(md) {
                    height: 450px;

                    .extra {
                        display: block !important;
                        .list-checked {
                            height: 67px;
                            overflow: hidden;
                            margin-bottom: 0;
                        }
                    }

                    .pricing {
                        .clearfix {
                            display: block;
                        }
                    }

                    .details {
                        h2 {
                            margin-top: 5px;
                        }
                    }

                    .availability {
                        width: 100%;
                        margin: 10px 0 0 0 !important;
                        font-size: 14px;
                        text-align: left;
                    }
                }

                // @include media-breakpoint-only(sm) {
                //     height: 400px;
                // }

                .btn-row {
                    @extend .p-0;
                    @extend .mt-1;
                    position: relative;

                    @include media-breakpoint-between(md, lg) {
                       // margin-top: 0 !important;

                        .btn {
                            display: block;
                            margin-left: 0 !important;

                            &:first-child {
                                margin-bottom: math.div($grid-gutter-width-base, 6);
                            }
                        }
                    }

                    @include media-breakpoint-only(lg) {
                        .btn {
                            &:first-child {
                                margin-bottom: math.div($grid-gutter-width-base, 3);
                            }
                        }
                    }

                    @include media-breakpoint-between(md, lg) {
                        // position: absolute !important;
                        // bottom: 5px;

                        .row-disabled & {
                            bottom: 10px !important;
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        margin-top: 70px !important;
                    }
                }

                .btn-acco {
                    background: transparent;
                    color: black;
                    span {
                        html[lang="de"] & {
                            display: none;
                        }
                    }
                }

                .details, .extra, .pricing {
                    @include make-col-ready;
                    @include make-col(12);
                }

                .details {
                    padding-top: 15px !important;
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }

                .details-included {
                    width: 100%;
                    display: flex;
                    // display: inline-flex;
                    // flex-direction: row-reverse;

                    padding: 0 15px!important;

                    @include media-breakpoint-up(xl) {
                        width: 144px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                        text-align: left;
                    }

                    .material-icons {
                        margin-right: 5px;
                        margin-top: 3px;
                    }

                    @include media-breakpoint-between(md, lg) {
                        @include make-col(12);
                        margin-left: 0 !important;
                        padding-right: 10px !important;
                        text-align: right;
                    }

                    @include media-breakpoint-only(lg) {
                        font-size: 14px;
                        display: flex;
                        width: 188px;
                        text-align: left;
                        margin-bottom: 10px;
                    }

                    @include media-breakpoint-only(md) {
                        width: 181px;
                        display: flex;
                        padding: 0 15px!important;
                        margin-bottom: 10px!important;
                    }
                }

                .price-holder {
                    @include media-breakpoint-up(xl) {
                        display: flex;
                    }
                }

                @media only screen and (max-width: 767px){
                    .btn-row {
                        margin-top: 45px !important;
                        width: 100%;
                        .col-md-12 {
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .row-cs {
            margin-right: -$grid-gutter-width-base * 0.5;
            margin-left: -$grid-gutter-width-base * 0.5;
        }

        .result {
            padding-right: $grid-gutter-width-base * 0.5;
            padding-left: $grid-gutter-width-base * 0.5;
        }
    }

    @include media-breakpoint-down(md) {
        .row-cs {
            margin-right: math.div(-$grid-gutter-width-base, 3);
            margin-left: math.div(-$grid-gutter-width-base, 3);
        }

        .result {
            padding-right: math.div($grid-gutter-width-base, 3);
            padding-left: math.div($grid-gutter-width-base, 3);
        }
    }

    .result-highlight {
        @include make-col(12);

        .result-left {
            .price-holder {
                // display: none;
            }
        }

        .result-right {
            .availability {
                margin: 0 0 0 10px !important;
            }
        }
    }
}

.grid-compare {
    .row {
        margin-right: -$grid-gutter-width * 0.5;
        margin-left: -$grid-gutter-width * 0.5;
    }

    .result {
        padding-right: $grid-gutter-width * 0.5 !important;
        padding-left: $grid-gutter-width * 0.5 !important;

        @include media-breakpoint-between(lg, xl) {
            @include make-col(3);
        }

        @include media-breakpoint-down(md) {
            @include make-col(4);
        }

        &:not(.result-highlight) {
            .pricing {
                @extend .mb-0;
                @extend .pb-0;
            }
        }

        .result-inner {
            background: transparent;
        }

        h4 {
            height: 32px;
            overflow: hidden;
            font-weight: 600;
        }

        .date {
            padding: 10px 15px 10px 40px;

            @include media-breakpoint-down(md) {
                margin-top: 10px;
            }

            &:before {
                top: 11px;
                left: 10px;
            }
        }

        .details-included {
            @include media-breakpoint-down(md) {
                margin: 0 0 0 10px !important;
            }
        }

        .btn {
            @extend .mb-0;
        }

        .compare-remove {
            position: absolute;
            right: 10px;
            top: 5px;
            color: $btn-primary-color;
            font-size: 1.5rem;
            cursor: pointer;
        }
    }

    .thumbnail-add {
        font-size: $font-size-h4;
        color: $beige;
    }

    .row-disabled {
        .extra-info {
            height: 188px;

            @include media-breakpoint-only(lg) {
                height: 181px;
            }

            @include media-breakpoint-down(md) {
                height: 183px;
            }
        }

        .price-tag {
            @extend .p-0;
            width: 100% !important;
            position: relative !important;
            top: 50%;
            transform: translateY(-55%);
            background: transparent;
            text-align: center;

            @include media-breakpoint-only(lg) {
                font-size: 20px;
            }
        }

        .pricing {
            display: none;
        }
    }
}

.result-highlight {
    .result-inner {
        @include media-breakpoint-down(md) {
            background-color: $green;
        }
    }

    .result-left {
        @include make-col(6);
        @extend .pr-0;

        .carousel-control {
            transform: translateY(-18px);
        }

        @include media-breakpoint-only(md) {
            @include make-col(4);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding-right: $grid-gutter-width-base * 0.5 !important;
        }
    }

    .result-right {
        @include make-col(6);
        @extend .pl-0;

        @include media-breakpoint-only(md) {
            @include make-col(8);
            padding-left: $grid-gutter-width-base * 0.5 !important;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding-left: $grid-gutter-width-base !important;
            padding-right: $grid-gutter-width-base !important;
        }

        .details {
            @extend .mt-0;
            border-right: 0;

            @include media-breakpoint-between(lg, xl) {
                @include make-col(12);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            h2 a, p {
                color: #fff !important;
            }
        }

        .details-inner {
            @extend .bg-green;
            padding: 25px 30px;

            @include media-breakpoint-only(lg) {
                padding: 20px 10px 10px 20px;
            }

            @include media-breakpoint-down(md) {
                padding: 0;
                background: transparent;
            }
        }

        .property {
            &:before, i {
                color: $green-light;
            }
        }

        .extra {
            @include make-col(7);
            @extend .mt-0;
            padding: 30px 0 30px 45px !important;

            @include media-breakpoint-only(lg) {
                display: block;
                padding: 20px 0 20px 35px !important;
            }

            .list-checked {
                font-size: inherit;

                li {
                    font-size: 16px;

                    @include media-breakpoint-only(lg) {
                        font-size: 14px;
                    }
                }
            }
        }

        .pricing {
            @include media-breakpoint-only(lg) {
                .price-tag {
                    .price {
                        font-size: 1.5rem;
                    }

                    .text-discount {
                        font-size: 12px !important;
                    }
                }

                .date-holder {
                    padding: 10px;
                }

                .details-included {
                    display: none;
                }
            }
        }

        .availability {
            @include media-breakpoint-up(md) {
                width: 100%;
                position: static;
                text-align: left;
            }

            @include media-breakpoint-only(lg) {
                font-size: 14px;
                margin: 8px 10px !important;

                .grid & {
                    margin: 10px 0 0 10px !important;
                }
            }
        }

        .extra-header {
            font-size: 18px;

            @include media-breakpoint-only(lg) {
                font-size: 15px;

                // .d-block {
                //     display: inline-block !important;
                // }
            }
        }

        .btn-compare {
            padding-left: 16px; // Resetting original padding
            margin-left: 4px; // Resolving missing spacing

            @include media-breakpoint-only(lg) {
                padding-left: 5px;
            }

            @include media-breakpoint-only(md) {
                padding-left: 0;
                color: $white;
            }
            @include media-breakpoint-only(sm) {
                display: none !important;
            }
        }

        .pricing {
            @include make-col(5);
        }

        .text-discount {
            top: 30px !important;
        }
    }

    @include media-breakpoint-down(md) {
        .date-holder {
            border-color: $green-light;
        }

        .date:before {
            color: $green-light;
        }

        .availability {
            color: $white;
        }

        .btn-row {
            .btn-primary {
                background-color: transparent !important;
                border-color: rgba($white, .5) !important;
                color: $white !important;

                &:hover {
                    background-color: $white !important;
                    border-color: $white !important;
                    color: $black !important;
                }
            }
        }
    }

    .details-included {
        color: $white;
    }

    .row-disabled {
        .price-tag {
            padding: 10px 15px 15px !important;
            background: $price-tag-background-unavailable;
            color: $white;
        }
    }
}

.text-discount {
    @include size(40%, auto);
    @include pos-t-r(50%, 0);
    transform: translateY(-50%);
    font-size: 14px;

    @include media-breakpoint-down(md) {
        font-size: 13px;
    }
}

.date-none {
    font-size: 1rem;
    @include media-breakpoint-down(md) {
        font-size: 13px;
    }
}

// LIST
.list {
    .list-checked {
        li {
            @include media-breakpoint-down(md) {
                font-size: 14px !important;
            }
            @include media-breakpoint-down(md) {
                font-size: 13px !important;
            }
        }
    }
    .result-left {
        flex: 0 0 39%;
        max-width: 39%;
        @include media-breakpoint-down(md) {
            flex: 0 0 35%;
            max-width: 35%;
        }
    }

    .result-right {
        flex: 0 0 61%;
        max-width: 61%;

        @include media-breakpoint-down(md) {
            flex: 0 0 65%;
            max-width: 65%;
        }

        .clearfix {
            margin-top: 20px;
            @include media-breakpoint-down(md) {
                margin-top: 5px;
            }
        }

        .extra {
            padding-left: 0px;
            position: relative;
            width: 100%;
            min-height: 1px;
            height: 100%;
            margin-top: 20px;
            padding-right: 10px!important;
            overflow: hidden;
            flex: none !important;
            max-width: 100%;

            @include media-breakpoint-only(lg) {
                height: 140px;
            }

            @include media-breakpoint-down(lg) {
                display: block;
                padding-left: 0px !important;
            }

            @include media-breakpoint-down(md) {
                height: 70px;
                margin-bottom: 10px;
                font-size: 12px;
            }

            p {
                display: none !important;
            }
        }

        .details {
            flex: 0 0 52%;
            max-width: 52%;

            h2 {
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
            }
        }

        .pricing-list {
            width: 40%;
            position: relative;
            @include media-breakpoint-down(lg) {
                width: 45%;
            }

            .details-included {
                width: 65%;
            }
        }

        .price-holder {
            width: 100%;
        }

        .price-tag {
            position: absolute;
            right: -40px;
            width: 190px;
            @include media-breakpoint-down(lg) {
                right: -4px;
                width: 200px;
            }

            @include media-breakpoint-down(md) {
                right: 0;
                width: 195px;
                height: 60px;
                padding: 8px 0px 8px 15px;
            }
        }

    }

    .top-holder {
        display: flex;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            margin-bottom: 0px;

        }

        .date-holder {
            background: none;
            padding: 10px 0px 10px 15px ;
            width: 360px;
        }
        .availability {
            text-align: right;
            padding: 0px !important;
            margin-right: 0px !important;

            @include media-breakpoint-down(md) {
                width: 70px;
                // padding-top: 10px !important;
                // padding-right: 5px !important;
            }
        }

    }

    .bottom-holder {
        position: relative;
        display: flex;
        @include media-breakpoint-down(lg) {
            display: block;
        }
    }

    .details-included {
        display: flex;
        flex-direction: row-reverse;

        .material-icons {
            margin-right: 5px;
            margin-top: 3px;
        }

        @include media-breakpoint-down(lg) {
            display: flex;
            flex-direction: row-reverse;
            margin-left: 15px;
            width: 210px;
            text-align: right;
        }

        @include media-breakpoint-down(md) {
            width: auto;
            margin-left: 10px !important;
            margin-top: 0px !important;
            display: flex;
            flex-direction: row-reverse;
            width: 151px;
        }
    }
}

.grid {
    .label-new {
        background-image: url('https://d33b12c77av9bg.cloudfront.net/img/label_new.png');
        position: absolute;
        top: 0;
        z-index: 9;
        right: 0;
        background-size: cover;
        height: 50px;
        width: 50px;
        @include media-breakpoint-down(lg) {
            height: 45px;
            width: 45px;
        }

        @include media-breakpoint-down(sm) {
            height: 60px;
            width: 60px;
        }
    }

    .label-restyled {
        background-image: url('https://d33b12c77av9bg.cloudfront.net/img/label_restyled.png');
        position: absolute;
        top: 0;
        z-index: 9;
        right: 0;
        background-size: cover;
        height: 50px;
        width: 50px;
        @include media-breakpoint-down(lg) {
            height: 45px;
            width: 45px;
        }

        @include media-breakpoint-down(sm) {
            height: 60px;
            width: 60px;
        }
    }
}

.list {
    .label-new {
        background-image: url('https://d33b12c77av9bg.cloudfront.net/img/label_new.png');
        position: absolute;
        top: 0;
        z-index: 9;
        right: 0;
        background-size: cover;
        height: 50px;
        width: 50px;
        @include media-breakpoint-down(lg) {
            height: 45px;
            width: 45px;
        }

        @include media-breakpoint-down(sm) {
            height: 60px;
            width: 60px;
            right: 0px;
        }
    }

    .label-restyled {
        background-image: url('https://d33b12c77av9bg.cloudfront.net/img/label_restyled.png');
        position: absolute;
        top: 0;
        z-index: 9;
        right: 0;
        background-size: cover;
        height: 50px;
        width: 50px;
        @include media-breakpoint-down(lg) {
            height: 45px;
            width: 45px;
        }

        @include media-breakpoint-down(sm) {
            height: 60px;
            width: 60px;
            right: 0px;
        }
    }
}

.details-included {
    &.small {
        font-size: 80%;
        font-weight: normal;
    }
}
