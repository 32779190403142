//== Background -> Mask
// --------------------------------------------------

	@mixin create-mask($mask, $background) {
		@if $background == none {
			-webkit-mask: $mask;
			mask: $mask;
		} @else {
			background: $background;
			-webkit-mask: $mask;
			mask: $mask;
		}
	}

	@mixin mask($mask, $background: none) {
		@include create-mask($mask, $background)
	}

	@mixin mask-position($position) {
		-webkit-mask-position: $position;
        mask-position: $position;
	}

    .bg-pos-top {
        .image-bg {
            background-position: center top !important;
        }
    }

    .bg-pos-center {
        .image-bg {
            background-position: center !important;
        }
    }

    .bg-pos-bottom {
        .image-bg {
            background-position: center bottom !important;
        }
    }