.affix {
    @include transition('.15s ease-in-out');
    width: 100%;
    top: 0;
    left: 0;
    z-index: $zindex-affix;
    background: white;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.05);
    opacity: 1;

    .acco-form {
        @extend .pl-0;

        .column-legend {
            @extend .hidden-xs-up;
        }

        @include media-breakpoint-down(lg) {
            padding-left: 0 !important;
            padding-right: $panel-body-padding;
        }
    }

    .preference-data {
        .price-large {
            .price-label {
                top: 10px;
                right: auto;
                left: -93px;
                width: auto;
                width: 80px;
                text-transform: uppercase;
                font-size: .85rem;
                color: $black !important;

                span {
                    top: 0px;
                    right: 0px;
                    font-size: 1.35rem;
                }
            }
        }

        &.disabled {
            .price-tag {
                font-size: 16px;
            }
        }
    }
}

.affix-form {
    position: relative;
    z-index: 1000;
    @include media-breakpoint-down(sm) {
        //@include make-row;
    }

    &.affix {
        .alert-danger {
            margin-bottom: 0;
        }
    }
}

.affix-bottom {
    position: absolute;
    opacity: 0;
}

@include media-breakpoint-down(sm) {
    .acco-form {
        .btn {
            height: auto;

            .amount {
                display: inline-block;
                font-style: normal;
                font-size: $font-size-large;
                color: white;
            }

            .affix-hide {
                display: none !important;
            }
        }
    }
}

// Accommodation affix
.gallery-holder {
    .container {
        @extend .px-0;
        width: auto;
    }

    &.affix-top {
        .preference-toggle {
            display: none;
        }
    }

    &.affix {
        @include size(100%, 62px);
        position: absolute;
        top: 0px;
        left: 0;
        z-index: $zindex-affix;
        position: fixed;
        background: #fff;

        .filters-accommodation {
            @include make-col(12);
            @extend .mt-0;
            top: 62px;

            & [class*="column-"] {
                padding-right: 15px !important;
            }

            .travel-company {
                .dropdown-value {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .container {
            @include media-breakpoint-down(xl) {
                padding: 0 $grid-gutter-width-base * 0.5 !important;
            }

            @include media-breakpoint-only(xl) {
                width: 1140px;
            }

            @include media-breakpoint-only(lg) {
                width: 960px;
            }

            @include media-breakpoint-down(md) {
                width: 720px;
            }
        }

        .gallery-preferences {
            @include media-breakpoint-only(xl) {
                @include make-col-ready;
                @include make-col(4.5);
            }

            @include media-breakpoint-only(lg) {
                @include make-col-ready;
                @include make-col(4.75);
            }

            @extend .float-right;
            @extend .pr-0;

            & label[class*="label-"],
            .filters {
                display: none;
            }
        }

        .gallery-header, .gallery-footer {
            @extend .hidden-xs-up;
        }

        .gallery-body {
            @extend .p-0;
            min-height: 0;
            position: relative;
            background: transparent;

            .form-dropdown-box {
                background: #fff;
            }

            .form-dropdown {
                color: $body-color;
                border-color: $gray-lighter;
                border: none;
                background-color: $white;
            }

            &.show {
                .filters {
                    display: block;
                }
            }
        }

        .gallery-nav {
            display: block;
            margin-left: -$grid-gutter-width-base * 0.5;

            li {
                @extend .list-inline-item;
            }

            @include media-breakpoint-only(md) {
                display: none;
            }
        }

        .preference-selector {
            @include make-col-ready;
            @include make-col(12);
            @extend .hidden-sm-down;
            top: 62px;
            right: 0;
            height: 0;
            min-height: 0;
            padding: 0;
            background: #fff;
            box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
            overflow: hidden;
            opacity: 0;
            visibility: hidden;

            &.preference-open {
                min-height: 1px;
                height: auto;
                padding: 0 15px;
                overflow: visible;
                opacity: 1;
                visibility: visible;
            }

            @include media-breakpoint-down(lg) {
                width: 277px;
                right: 45px;
            }
        }

        .preference-toggle {
            @include size(46px, 34px);
            @include transition(.15s ease-in-out height);
            position: relative;
            display: block;
            top: 0;
            left: 50%;
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
            background: #fff;
            cursor: pointer;
            transform: translate(-50%, 0);

            span {
                @include pos-t-l(50%, 50%);
                transform: translate(-50%, -50%);
                font-size: $font-size-lead;

                &:before {
                    @include mi('\e313'); //.mi("keyboard_arrow_down");
                    color: $orange;
                    font-size: 2rem;
                }
            }


            &.preference-toggle-open {
                box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);

                span {
                    top: 50%;

                    &:before {
                        @include mi('\e316'); //.mi("keyboard_arrow_up");
                        color: $orange;
                        font-size: 2rem;
                    }
                }
            }
        }

        .preference-data {
            @include make-col(12);
            @extend .ml-0;
            height: 62px;
            position: static;
            padding-right: 15px !important;
            padding-left: 15px !important;

            // @include media-breakpoint-down(lg) {
            //     position: relative;
            //     right: 15px;
            // }

            &.disabled {
                .price-tag {
                    @include size(100%, 62px);
                    @extend .p-0;
                    text-align: center;
                    line-height: 62px;
                    font-size: 14px !important;
                    font-weight: bold;
                    background: $beige-light;
                }
            }

            .price-large {
                @include size($price-width-affix, $price-height-affix);
                background-position: $price-bg-pos-affix;
                height: 50px !important;

                .price {
                    font-size: 1.5rem !important;
                }
            }

            &.preference-data-open {
                @include pos-t-r(0, 0);

                @include media-breakpoint-down(lg) {
                    right: -.5px;
                }
            }

            .alert {
                @extend .m-0;
            }
        }

        .price-tag {
            @include size(auto, 62px);
            @include pos-t-l();
            @extend .my-0;
            right: auto;
            padding: 14px 15px ($padding-large-vertical * 2);

            .expanded & {
                padding-bottom: 25px;
            }

            .price {
                @extend .text-center;
            }

            s {
                @extend .hidden-xs-up;
            }
        }

        .details-included {
            @include size(100px, auto);
            @include pos-t-l(0, -110px);
            @extend .m-0;
            width: 120px;
            font-size: 14px;
        }

        .text-discount {
            @include size(100px, auto);
            @include pos-t-r(50%, -115px);
        }

        .price-label {
            @include pos-t-l(19px, -100px);
            width: auto;
            right: auto;
            padding-right: $panel-body-padding;
        }

        .btn {
            @include pos-t-r(10px, 0px);
        }

        .btn-link {
            @extend .hidden-xs-up;
        }

        .btn-booking {
            @include pos-t-r(9px, 0);
            left: auto;
            height: 46px;

            span {
                display: none;
            }
        }

        .rating-holder {
            left: 0 !important;
            background: transparent;

            .rating-rate {
                margin-top: -2px;
                font-size: 24px !important;
                color: $body-color !important;
            }

            .rating-paws, .rating-leafs {
                @extend .mt-0;

                .material-icons {
                    font-size: 24px !important;
                }
            }
        }

        .zoover-rating {
            margin-right: $grid-gutter-width;
        }

        .alert {
            @extend .py-0;
            height: 62px;
            line-height: 62px;
            text-align: center;

            &.alert-danger {
                @extend .hidden-xs-up;
            }
        }

        .form-dropdown {
            .dropdown-menu {
                width: 100%;
                left: 0 !important;
                background: $white;
                box-shadow: none;
            }
        }

        .dropdown-input {
            .input-group-btn {
                .btn {
                    position: static;
                }
            }
        }

        @include media-breakpoint-only(lg) {
            .dropdown-menu {
                padding: 15px !important;
            }

            .datepicker {
                .datepicker-switch {
                    font-size: 1rem;
                }

                table tr td {
                    @include size(43px, 43px);
                    font-size: 16px;
                }

                .next,
                .prev {
                    @include size(43px, 43px);

                    i {
                        top: 4px;
                        font-size: 32px;
                    }
                }
            }
        }
    }
}
