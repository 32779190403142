/* 
** Table of contents
** Use following values for presets in CardGroupBlock
**
** card-group-mosaic
** card-group-mosaic mosaic-reverse
** card-group-bss
** card-group-bss bss-reverse
** card-group-xl
** card-group-xl xl-reverse
** card-group-lg
** card-group-md
** card-group-sm
** card-group-overlay-md
** card-group-overlay-sm
** Panorama card group
** Panorama full width card group
** Card group animal description
*/

.card-group {
  @include media-breakpoint-down(md) {
    flex-flow: column wrap;
    flex-direction: column !important;

    .card {
      @include make-col(12);
    }
  }
}

// Mosaic
.card-group-mosaic {
  display: flex;
  align-items: flex-start;

  .card {
    @extend .flex-off;
    align-self: stretch;

    &.active {
      .card-block {
        background: transparent;
      }
    }

    &:nth-child(1) {
      @extend .flex-on;

      .card-header {
        h2,
        h3,
        h4,
        p {
          @extend .h1;
          @extend .mb-3;
          text-transform: uppercase;
          font-family: $font-family-secondary;
          font-weight: 300;
          font-size: 3rem;
          color: $white;

          @include media-breakpoint-down(xs) {
            font-size: 2rem !important;
          }
        }
      }

      .card-block {
        @extend .card-img-overlay;
        @extend .text-center;
        width: 75%;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1.25rem;
        max-height: none;
      }

      .card-text {
        p {
          color: $white;
        }
      }

      .btn {
        position: static;
      }
    }

    &:nth-child(2) {
      @extend .card-landscape;
      @extend .mrtl;
      @extend .mx-0;
    }

    &:nth-child(3) {
      @extend .card-square;
    }
  }

  .card-wrap {
    @extend .flex-on;
    .card {
      &:nth-child(1) {
        min-height: 278px;
        @include media-breakpoint-only(lg) {
          min-height: 233px;
        }
        @include media-breakpoint-only(md) {
          min-height: 258px;
        }
        @include media-breakpoint-only(sm) {
          min-height: 173px;
        }
        @include media-breakpoint-only(xs) {
          min-height: 145px;
        }
      }
      &:nth-child(1) {
        .card-block {
          width: 100%;
          transform: translate(0);
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          text-align: left !important;

          .btn {
            bottom: 1.875rem;
          }
        }
      }

      .card-header {
        h2,
        h3,
        h4,
        p {
          font-family: $headings-font-family;
          font-weight: $headings-font-weight;
          font-size: $h4-font-size;
          text-transform: none !important;

          @include media-breakpoint-down(xs) {
            font-size: 1.125rem !important;
          }
        }
      }

      .card-block {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        @include media-breakpoint-only(xl) {
          max-height: 277px;
        }
        @include media-breakpoint-only(md) {
          max-height: none;
        }

        .card-text {
          display: flex;
          flex: 1;
          flex-direction: column;

          p:nth-child(n + 2):last-child {
            display: flex;
            flex: 1;
            align-items: flex-end;

            a.btn {
              position: relative;
              top: 0;
            }
          }
        }
      }

      .btn {
        position: absolute;
      }
    }
  }

  &.mosaic-reverse {
    @extend .row-reverse;

    .card {
      &:nth-child(2) {
        @extend .mltr;
      }
    }

    @include media-breakpoint-only(md) {
      flex-flow: column wrap;
      flex-direction: column-reverse !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .card {
      &:nth-child(1) {
        .card-header {
          h2,
          h3,
          h4,
          p {
            font-size: 2rem;
          }
        }
      }

      .btn {
        padding: $btn-padding-y-sm $btn-padding-x-sm;
        font-size: $font-size-sm;
      }
    }

    .card-wrap {
      .card {
        &:nth-child(1) {
          .card-header {
            h2,
            h3,
            h4,
            p {
              font-size: $h4-font-size;
            }
          }

          .btn {
            bottom: 1.25rem;
          }
        }

        &:nth-child(2) {
          .card-header {
            h2,
            h3,
            h4,
            p {
              font-size: $h6-font-size;
            }
          }
        }
      }
    }

    &.mosaic-reverse {
      flex-direction: column-reverse !important;
    }
  }
}

// Big small small
.card-group-bss {
  .card {
    -webkit-flex: 1 !important;

    .card-block {
      max-height: 277px;
      overflow: hidden;

      @include media-breakpoint-only(xl) {
        max-height: 277.5px;
      }
      @include media-breakpoint-only(lg) {
        max-height: 232.5px;
      }
      @include media-breakpoint-only(md) {
        max-height: none;
      }

      .card-header {
        h2,
        h3,
        h4,
        p {
          font-weight: $headings-font-weight;
        }
      }

      .btn {
        font-family: "sofia-pro" !important;
      }
    }

    &:nth-child(1) {
      @extend .card-square;

      .card-media {
        @include media-breakpoint-down(md) {
          -webkit-flex: 1;
        }
      }

      .card-block {
        @extend .text-center;
        width: 75%;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: none;
        background-color: transparent;

        .btn {
          position: static;
        }
      }

      .card-header {
        h2,
        h3,
        h4,
        p {
          @extend .h1;
          @extend .text-uppercase;
          font-family: $font-family-secondary;
          font-weight: 300;
          font-size: 3rem;
          margin-bottom: 0 !important;

          @include media-breakpoint-down(sm) {
            font-size: 2rem;
          }
        }
      }

      .card-text {
        @extend .hidden-xs-up;
      }
    }

    &:nth-child(2) {
      @extend .card-portrait;
      @extend .card-sm;

      .card-media,
      .card-block {
        @include media-breakpoint-down(sm) {
          @include make-col(6);
        }
      }
    }

    &:nth-child(3) {
      @extend .card-portrait;
      @extend .card-sm;

      .card-media,
      .card-block {
        @include media-breakpoint-down(sm) {
          @include make-col(6);
        }
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      @include media-breakpoint-only(sm) {
        max-height: 263px;
      }
      @include media-breakpoint-only(xs) {
        min-height: 143px;
      }

      .card-block {
        .btn {
          position: absolute;
        }
      }
    }
  }

  &.bss-reverse {
    @extend .row-reverse;
  }

  @include media-breakpoint-down(md) {
    .card {
      flex-direction: column;

      &:nth-child(2),
      &:nth-child(3) {
        flex-direction: row;

        .card-media,
        .card-block {
          @include make-col(6);
        }

        .btn {
          font-size: $font-size-sm;
          padding: $btn-padding-y-sm $btn-padding-x-sm;
        }
      }
    }

    &.bss-reverse {
      flex-direction: column-reverse !important;
      display: flex;
    }
  }

  @include media-breakpoint-down(sm) {
    .card {
      flex-direction: row;

      &:nth-child(2),
      &:nth-child(3) {
        .card-media {
          @include make-col(4.9);

          picture {
            height: 100%;

            img {
              min-height: 100%;
              object-fit: cover;
            }
          }
        }

        .card-block {
          @include make-col(7.1);
        }

        .card-header {
          h2,
          h3,
          h4,
          p {
            font-size: 0.9375rem !important;
            line-height: 19.5px !important;
          }
        }

        .btn {
          position: static;
          font-size: 0.625rem;
          padding: $btn-padding-y-sm $btn-padding-x-sm;
        }
      }

      .card-block {
        width: 85% !important;
        padding: 15px;

        &:first-child {
          padding: 1.25rem 0;
        }
      }
    }

    &.bss-reverse {
      flex-direction: column-reverse !important;
    }
  }

  @include media-breakpoint-only(xs) {
    .card {
      .card-block {
        width: 100% !important;
      }
    }
  }
}

// iphone 5 fix for BSS and SSB
// @media (max-width: 320px) {
//     .card-group-bss {
//         .card {
//             &:nth-child(2), &:nth-child(3) {
//                 .card-header {
//                     max-height: 62px;
//                     min-height: 62px;
//                     display: block;
//                 }
//             }

//             .btn {
//                 white-space: normal;
//                 position: relative;
//                 bottom: 0;
//             }
//         }
//     }
// }

// Big squares
.card-group-xl {
  .card-wrap {
    flex: 1;
    -webkit-flex: 1;
  }

  .card {
    -webkit-flex: 1 !important;
    &:nth-child(1) {
      @extend .card-square;
      max-width: 100% !important;

      .card-block {
        @extend .card-img-overlay;
        @extend .text-center;
        width: 75%;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;

        .btn {
          position: static;
        }
      }

      .card-header {
        h2,
        h3,
        h4,
        p {
          @extend .h1;
          @extend .text-uppercase;
          font-family: $font-family-secondary;
          font-weight: 300;
          font-size: 3rem;
        }
      }
    }

    &:nth-child(2) {
      @extend .no-image;

      .card-block {
        padding: 2.5rem !important;
        max-height: 555px;
        overflow: hidden;

        @include media-breakpoint-only(lg) {
          max-height: 465px;
        }
        @include media-breakpoint-only(md) {
          max-height: 345px;
        }
      }

      .card-header {
        h2,
        h3,
        h4,
        p {
          @extend .h1;
          @extend .text-uppercase;
          @extend .text-center;
          @extend .mb-4;
          font-family: $font-family-secondary;
          font-weight: 300;
          font-size: 3rem;
        }
      }

      .btn {
        position: absolute;
        bottom: 2.5rem;
        left: 2.5rem;
      }
    }
  }

  &.xl-reverse {
    @extend .row-reverse;
  }

  @include media-breakpoint-only(lg) {
    .card {
      &:nth-child(2) {
        .card-block {
          padding: 1.875rem 1.875rem 4.5rem !important;
        }

        .card-header {
          h2,
          h3,
          h4,
          p {
            font-size: 2rem !important;
          }
        }

        .btn {
          bottom: 1.875rem;
          left: 1.875rem;
        }
      }
    }
  }

  @include media-breakpoint-only(md) {
    .card {
      &:nth-child(2) {
        .card-block {
          max-height: none;
          padding: 2.5rem 2.5rem 5.5rem !important;
        }
      }
    }

    &.xl-reverse {
      flex-flow: column wrap;
      flex-direction: column-reverse !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .card {
      .card-header {
        h2,
        h3,
        h4,
        p {
          font-size: 2rem !important;
        }
      }

      &:nth-child(2) {
        .card-block {
          max-height: none;
          padding: 1.25rem 1.25rem 4rem !important;
        }

        .btn {
          bottom: 1.25rem;
          left: 1.25rem;
        }
      }
    }

    &.xl-reverse {
      flex-direction: column-reverse !important;
    }
  }

  @include media-breakpoint-down(xs) {
    &.xl-reverse {
      display: flex;
    }
  }
}

// Thumbnails LG
.card-group-lg {
  .card {
    @include make-col(4);

    @include media-breakpoint-down(md) {
      @include make-col(12);
    }

    .card-block {
      max-height: 277px;
      overflow: hidden;

      @include media-breakpoint-only(xl) {
        max-height: 365px;
      }
      @include media-breakpoint-only(lg) {
        max-height: 300px;
      }
      @include media-breakpoint-only(md) {
        max-height: none;
      }

      .tab-content & {
        @include media-breakpoint-only(xl) {
          max-height: 325px;
        }
      }
    }
  }
}

// Thumbnails MD
.card-group-md {
  .card {
    @include make-col(3);

    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }

    .card-block {
      max-height: 277px;
      overflow: hidden;

      @include media-breakpoint-only(xl) {
        max-height: 365px;
      }
      @include media-breakpoint-only(lg) {
        max-height: 300px;
      }
      @include media-breakpoint-only(md) {
        max-height: none;
      }

      .tab-content & {
        @include media-breakpoint-only(xl) {
          max-height: 325px;
        }
      }
    }
  }
}

// Thumbnails SM
.card-group-sm {
  .card {
    @extend .card-landscape;
    @extend .card-sm;
    flex-direction: row;
    -webkit-flex: 1 !important;

    .card-block {
      position: relative;
      padding: 1.25rem !important;
    }
  }

  .carousel-item.active {
    display: flex !important;
  }

  .card-header {
    h2,
    h3,
    h4,
    p {
      @extend .h5;
    }
  }

  .btn {
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  @include media-breakpoint-only(lg) {
    .card {
      .card-block {
        padding: 1rem !important;
      }

      .btn {
        padding: $btn-padding-y-sm $btn-padding-x-sm;
        font-size: $font-size-sm;
      }
    }
  }

  @include media-breakpoint-only(md) {
    .card {
      .card-block {
        padding: 1.875rem 1.875rem 4rem !important;
      }

      .card-header {
        h2,
        h3,
        h4,
        p {
          font-size: $h4-font-size;
        }
      }

      .btn {
        bottom: 1.875rem;
        left: 1.875rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .card {
      .card-header {
        h2,
        h3,
        h4,
        p {
          font-size: $h6-font-size;
        }
      }

      .btn {
        padding: $btn-padding-y-sm $btn-padding-x-sm;
        font-size: $font-size-sm;
      }
    }
  }
}

// Thumbnails: combined styles (LG, MD)
.card-group-lg,
.card-group-md {
  .card-block {
    padding-bottom: 5rem;
  }
}

// Carousel
.card-carousel {
  .card-header {
    h4 {
      font-weight: 600;
    }
  }
  .card-block {
    height: 277px;
  }
}

// Tiles MD
.card-group-overlay-md {
  .card {
    @include make-col(4);
    .card-block {
      .btn {
        border: none !important;
        text-transform: none !important;
        bottom: 10px;
        left: 5px;
        font-size: 1rem;
        &:before {
          @include mi("\E315");
          color: $white;
          font-size: 1.4rem;
          vertical-align: top;
        }
        &:hover {
          background-color: transparent !important;
          color: $white !important;
        }
      }
    }
  }
}

// Tiles SM
.card-group-overlay-sm {
  .card {
    @include make-col(3);
    .card-block {
      .btn {
        border: none !important;
        text-transform: none !important;
        bottom: 10px;
        left: 5px;
        font-size: 1rem;
        &:before {
          @include mi("\E315");
          color: $white;
          font-size: 1.4rem;
          vertical-align: top;
        }
        &:hover {
          background-color: transparent !important;
          color: $white !important;
        }
      }
    }
    &:hover {
      .card-block {
        .btn {
          display: none;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .card-media {
      img {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        max-height: 360px;
      }
    }
  }
}

// Tiles: combined styles (MD, SM)
.card-group-overlay-md,
.card-group-overlay-sm {
  .collection-item,
  .card {
    @extend .card-inverse;
    overflow: hidden;

    .card-block {
      @extend .card-img-overlay;
      top: auto;

      .card-header {
        h2,
        h3,
        h4,
        p {
          @extend .mb-0;
        }
      }

      .card-text {
        @extend .mt-3;
        @extend .mb-0;
        display: none;
      }

      .btn {
        display: none;
      }
    }

    &:hover {
      .card-block {
        top: 0;

        .card-text {
          display: block;
        }

        .btn {
          display: block !important;
        }
      }
    }

    .btn {
      @extend .hidden-xs-up;
    }
  }

  @include media-breakpoint-down(md) {
    flex-flow: row wrap;
    flex-direction: row !important;
    justify-content: space-around;

    .card {
      @include media-breakpoint-only(md) {
        @include make-col(6);
      }

      @include media-breakpoint-down(sm) {
        @include make-col(12);
      }
    }
  }
}

// Panorama card group
.card-group-pano {
  .card {
    flex: 0 0 100%;
    flex-direction: row;
    display: flex !important;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      -webkit-flex: 1;
    }
  }

  .card-media {
    flex: 0 0 65%;
    @include media-breakpoint-down(md) {
      -webkit-flex: 1;
    }

    img {
      min-width: 100%;
    }
  }

  .card-block {
    flex: 0 0 35%;
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;

    @include media-breakpoint-only(xl) {
      max-height: 360px;
    }
    @include media-breakpoint-only(lg) {
      max-height: 302px;
    }

    @include media-breakpoint-down(md) {
      -webkit-flex: 1;
    }

    .btn {
      position: absolute !important;

      @include media-breakpoint-down(md) {
        position: static !important;
      }
    }
  }

  &.pano-reverse {
    .card {
      @extend .row-reverse;

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse !important;
      }
    }
  }
}

// Panorama full-width
.container-smooth {
  .card-group-pano {
    .card {
      display: flex !important;

      @include media-breakpoint-only(xl) {
        max-height: 360px;
        overflow: hidden;
      }
      @include media-breakpoint-only(lg) {
        max-height: 302px;
        overflow: hidden;
      }

      @include media-breakpoint-down(md) {
        -webkit-flex: 1;
      }

      .card-block {
        @include media-breakpoint-up(md) {
          max-height: 360px;
        }
      }

      .card-media {
        img {
          object-fit: cover;
          height: 360px;
          @include media-breakpoint-only(lg) {
            max-height: 302px;
          }
          @include media-breakpoint-down(md) {
            height: 345px;
          }
          @include media-breakpoint-down(sm) {
            height: 175px;
          }
        }
      }
    }
  }
}

// Card group animal description

.card-group-animal {
  align-items: initial;

  .card-wrap {
    display: flex;
    flex-direction: column;

    &:nth-child(1) {
      .card {
        height: 100%;
      }
    }

    &:nth-child(2) {
      .card {
        &:nth-child(1) {
          min-height: 0;
        }
      }
    }

    .gallery-image {
      cursor: pointer;
    }

    .gallery-single {
      height: 100%;

      .gallery-image,
      .inner {
        height: 100%;
      }

      .gallery-image {
        cursor: default;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .card {
    &:nth-child(1) {
      .card-block {
        position: relative;

        @include media-breakpoint-up(lg) {
          height: 148px;
          max-height: 148px;
        }
      }
    }

    &:nth-child(2) {
      .card-block {
        position: relative;
        flex: 0 0 100% !important;
        max-width: 100% !important;

        @include media-breakpoint-up(md) {
          max-height: 350px;

          > div {
            display: flex;
            flex-flow: column wrap;
            height: 100%;
            max-height: 100%;
          }
        }

        @include media-breakpoint-only(md) {
          > div {
            flex-direction: row;
          }
        }

        @include media-breakpoint-up(lg) {
          height: 317px;
          max-height: 317px;
        }

        @include media-breakpoint-up(xl) {
          height: 407px;
          max-height: 407px;
        }
      }
    }
  }

  @include media-breakpoint-only(md) {
    .gallery {
      margin: 0px;
    }

    .gallery-image {
      max-width: 100%;
      flex: none;
    }
  }
}

// Card group arrangement
.card-group-arrangement {
  align-items: initial;

  .card-wrap {
    display: flex;
    flex-direction: column;
    flex: 1 0 50% !important;
  }

  .card {
    min-height: unset !important;

    & .card-block {
      position: relative !important;
      flex: 0 0 100% !important;

      @include media-breakpoint-only(xl) {
        max-height: 277.5px !important;
        height: 277.5px !important;
      }

      @include media-breakpoint-only(lg) {
        max-height: 232.5px !important;
        height: 232.5px !important;
      }

      & .property-description > p {
        line-height: 1.05rem;
        margin-bottom: 4px;
        font-size: 0.875rem;
      }
    }

    &:nth-child(2) {
      .card-block {
        position: relative;
        flex: 0 0 100% !important;
        max-width: 100% !important;

        @include media-breakpoint-up(md) {
          > div {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            flex-flow: wrap;
            height: 100%;
            max-height: 100%;
          }
        }

        @include media-breakpoint-only(md) {
          > div {
            flex-direction: row;
          }
        }
      }
    }
  }

  @include media-breakpoint-only(md) {
    .gallery {
      margin: 0px;
    }

    .gallery-image {
      max-width: 100%;
      flex: none;
    }
  }
}

// General styles
.card-group-mosaic,
.card-group-ssb,
.card-group-bss,
.card-group-lg,
.card-group-md,
.card-group-sm,
.card-group-overlay-sm,
.card-group-overlay-md,
.card-group-pano {
  .card-media {
    @include media-breakpoint-only(lg) {
      align-self: stretch;
      height: 100%;
    }

    picture,
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .card-block {
    .btn {
      position: absolute;
      bottom: 1.875rem;

      @include media-breakpoint-down(sm) {
        bottom: 1.25rem;
      }
    }
  }
}

.card-group {
  .card-header {
    font-size: $h4-font-size;
    font-weight: $headings-font-weight;

    h2,
    h3,
    h4,
    p {
      font-weight: $headings-font-weight;
    }
  }

  .card-block {
    overflow: hidden;

    @include media-breakpoint-only(md) {
      // padding: 1.875rem;
    }
  }
}

.card-group,
.card-carousel {
  .carousel-item {
    align-items: stretch;
  }
}

.card-carousel {
  .carousel-control {
    position: absolute;
    transform: translate(0);
    border: 2px solid $white;

    @include media-breakpoint-only(md) {
      top: 468px;
    }
    @include media-breakpoint-only(sm) {
      top: 325px;
    }
    @include media-breakpoint-only(xs) {
      top: 209px;
    }
    @media (max-width: 575px) {
      top: 168px;
    }
  }

  .btn {
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;

    @include media-breakpoint-down(sm) {
      bottom: 1.25rem;
      left: 1.25rem;
    }
  }

  @include media-breakpoint-only(md) {
    .card-block {
      height: 250px;
      padding: 1.875rem;
    }
  }
}

//Special addition for Ipad portrait mode
.tab-pane {
  .card-group-md {
    flex-flow: row wrap !important;
  }
}

//IE 11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /*general fixes*/
  html,
  body {
    overflow-x: hidden;
  }

  .img-fluid {
    flex-shrink: 0;
    // min-height: 100%;
  }

  .footer-sm {
    .list-inline {
      li {
        margin-right: 35px;
        @include media-breakpoint-down(lg) {
          margin-right: 10px;
        }
      }
    }
  }

  .card-group .card {
    //flex: 1 0 0%;
  }

  .tab-pane {
    .card-group-md {
      .card {
        .card-media {
          @include media-breakpoint-only(xl) {
            max-height: 181px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 150px;
          }
        }

        .card-block {
          ul {
            list-style: none !important;
          }
        }
      }
    }
  }

  .card-group-md .CollectionItemOverlay .collection-item,
  .CollectionItemOverlay .card-group-md .collection-item {
    @include make-col(3);

    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }
  }

  .form-dropdown .dropdown-menu {
    width: 290px;
  }

  /* modal img */
  .modal-lg {
    transform: translate(0px, 52%) !important;

    .modal-content {
      display: block;
    }
  }

  .modal-open .modal {
    overflow-y: hidden;
  }

  /* Logo */
  .navbar-brand {
    .navbar-brand-image {
      width: 118px;
      height: 98px;
    }
  }

  .affix.navbar.navbar-brand-image {
    width: 101px;
    height: 83px;
  }

  /* Styleguide */
  .card-group-mosaic {
    overflow: hidden;
    @include media-breakpoint-only(xl) {
      max-height: 555px;
    }
    @include media-breakpoint-only(lg) {
      max-height: 465px;
    }
    @include media-breakpoint-down(md) {
      min-height: 1380px;
    }
    .card {
      .card-media {
        overflow: hidden;
        @include media-breakpoint-only(xl) {
          max-height: 555px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 465px;
        }
        @include media-breakpoint-down(md) {
          min-height: 690px;
        }
      }
    }
    .card-wrap {
      &:nth-child(1) {
        .card-media {
          overflow: hidden;
          @include media-breakpoint-only(xl) {
            max-height: 277.5px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 232.5px;
          }
          @include media-breakpoint-down(md) {
            min-height: 172px;
          }
        }
        .card-block {
          overflow: hidden;
          @include media-breakpoint-only(xl) {
            max-height: 277.5px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 232.5px;
          }
          @include media-breakpoint-down(md) {
            min-height: 172px;
          }
        }
      }
      &:nth-child(2) {
        .card-media {
          overflow: hidden;
          @include media-breakpoint-only(xl) {
            max-height: 277.5px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 232.5px;
          }
          @include media-breakpoint-down(md) {
            min-height: 172px;
          }
        }
        .card-block {
          overflow: hidden;
          @include media-breakpoint-only(xl) {
            max-height: 277.5px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 232.5px;
          }
          @include media-breakpoint-down(md) {
            min-height: 172px;
          }
        }
      }
    }
  }

  //BIG small small
  .card-group-bss {
    overflow: hidden;
    @include media-breakpoint-only(xl) {
      max-height: 555px;
    }
    @include media-breakpoint-only(lg) {
      max-height: 465px;
    }
    @include media-breakpoint-only(md) {
      max-height: 1380px;
    }
    .card {
      .card-media {
        @include media-breakpoint-only(xl) {
          max-height: 555px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 465px;
        }
      }
      &:nth-child(2) {
        .card-media {
          @include media-breakpoint-only(xl) {
            max-height: 277.5px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 232.5px;
          }
          @include media-breakpoint-down(md) {
            min-height: 172px;
          }
        }
        .card-block {
          @include media-breakpoint-only(xl) {
            max-height: 277.5px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 232.5px;
          }
          @include media-breakpoint-down(md) {
            min-height: 172px;
          }
        }
      }

      &:nth-child(3) {
        .card-media {
          @include media-breakpoint-only(xl) {
            max-height: 277.5px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 232.5px;
          }
          @include media-breakpoint-down(md) {
            min-height: 172px;
          }
        }
        .card-block {
          @include media-breakpoint-only(xl) {
            max-height: 277.5px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 232.5px;
          }
          @include media-breakpoint-down(md) {
            min-height: 172px;
          }
        }
      }
    }
  }

  //Big squares
  .card-group-xl {
    @include media-breakpoint-only(xl) {
      max-height: 555px;
    }
    @include media-breakpoint-only(lg) {
      max-height: 465px;
    }
    @include media-breakpoint-down(md) {
      min-height: 345px;
    }
    .card {
      flex: 1 0 0%;
      .gallery {
        @include media-breakpoint-only(xl) {
          max-height: 555px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 465px;
        }
        @include media-breakpoint-down(md) {
          min-height: 345px;
        }
      }
      &:nth-child(1) {
        .card-media {
          @include media-breakpoint-only(xl) {
            max-height: 555px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 465px;
          }
          @include media-breakpoint-down(md) {
            min-height: 345px;
          }
        }
        .card-block {
          @include media-breakpoint-only(xl) {
            max-height: 555px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 465px;
          }
          @include media-breakpoint-down(md) {
            min-height: 345px;
          }
        }
      }

      &:nth-child(2) {
        .card-media {
          @include media-breakpoint-only(xl) {
            max-height: 555px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 465px;
          }
          @include media-breakpoint-down(md) {
            min-height: 345px;
          }
        }
        .card-block {
          @include media-breakpoint-only(xl) {
            max-height: 555px;
          }
          @include media-breakpoint-only(lg) {
            max-height: 465px;
          }
          @include media-breakpoint-down(md) {
            min-height: 345px;
          }
        }
      }
    }
  }

  //Thumbnails LG
  .card-group-lg {
    .card {
      @include media-breakpoint-only(xl) {
        max-height: 578px;
      }
      @include media-breakpoint-only(lg) {
        max-height: 544px;
      }
      @include media-breakpoint-down(md) {
        min-height: 550px;
      }
      .card-media {
        @include media-breakpoint-only(xl) {
          max-height: 277px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 233px;
        }
        @include media-breakpoint-down(md) {
          min-height: 550px;
        }
      }
      .card-block {
        @include media-breakpoint-only(xl) {
          max-height: 370px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 310px;
        }
        @include media-breakpoint-down(md) {
          min-height: 250px;
        }
      }
    }
  }

  //Thumbnails MD
  .card-group-md {
    .card {
      @include media-breakpoint-only(xl) {
        max-height: 578px;
      }
      @include media-breakpoint-only(lg) {
        max-height: 544px;
      }
      @include media-breakpoint-down(md) {
        min-height: 550px;
      }

      .card-media {
        @include media-breakpoint-only(xl) {
          max-height: 268px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 220px;
        }
        @include media-breakpoint-down(md) {
          min-height: 550px;
        }
      }

      .card-block {
        @include media-breakpoint-only(xl) {
          max-height: 370px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 310px;
        }
        @include media-breakpoint-down(md) {
          min-height: 250px;
        }
      }
    }
  }

  //Thumbnails SM
  .card-group-sm {
    .card {
      .card-media {
        @include media-breakpoint-only(xl) {
          max-height: 154px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 129px;
        }
        @include media-breakpoint-down(md) {
          min-height: 288px;
        }
      }
      .card-block {
        @include media-breakpoint-only(xl) {
          max-height: 154px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 129px;
        }
        @include media-breakpoint-down(md) {
          min-height: 288px;
        }
      }
    }
  }

  //Tiles MD
  .card-group-overlay-md {
    .card {
      .card-media {
        @include media-breakpoint-only(xl) {
          max-height: 277px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 233px;
        }
        @include media-breakpoint-down(md) {
          min-height: 690px;
        }
      }
      .card-block {
        @include media-breakpoint-only(xl) {
          max-height: 277px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 233px;
        }
        @include media-breakpoint-down(md) {
          min-height: 690px;
        }
      }
    }
  }

  //Tiles SM
  .card-group-overlay-sm {
    .card {
      .card-media {
        @include media-breakpoint-only(xl) {
          max-height: 208px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 174px;
        }
        @include media-breakpoint-only(md) {
          min-height: 690px;
        }
      }
      .card-block {
        @include media-breakpoint-only(xl) {
          max-height: 208px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 174px;
        }
        @include media-breakpoint-only(md) {
          min-height: 690px;
        }
      }
    }
  }

  //Pano
  .card-group-pano {
    .card {
      .card-media {
        @include media-breakpoint-only(xl) {
          max-height: 360px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 360px;
        }
        @include media-breakpoint-only(md) {
          min-height: 690px;
        }
      }
      .card-block {
        @include media-breakpoint-only(xl) {
          max-height: 360px;
        }
        @include media-breakpoint-only(lg) {
          max-height: 360px;
        }
        @include media-breakpoint-only(md) {
          min-height: 690px;
        }
        flex: 1 0 0%;
      }
    }
  }

  // Card group animal
  .card-group-animal {
    .card {
      &:nth-child(1) {
        .card-block {
          @include media-breakpoint-up(lg) {
            height: 148px;
            max-height: 148px;
          }
        }
      }

      &:nth-child(2) {
        .card-block {
          @include media-breakpoint-up(md) {
            max-height: 350px;

            > div {
              height: 100%;
              max-height: 100%;
            }
          }

          @include media-breakpoint-up(lg) {
            height: 317px;
            max-height: 317px;
          }

          @include media-breakpoint-up(xl) {
            height: 407px;
            max-height: 407px;
          }
        }
      }
    }
  }
}
// END IE 11
