
@use "sass:math";

.secret-deal {
    position: relative;

    &:before {
        @include size($secret-deal-emblem-width, $secret-deal-emblem-height);
        @include pos-t-l($secret-deal-emblem-pos-y, $secret-deal-emblem-pos-x);
        background: url($secret-deal-emblem) no-repeat;
        background-size: cover;
        content: "";
        display: block;
        transform: $secret-deal-transform;
    }

    .gallery-header {
        @extend .text-left;
        padding: 20px 30px $panel-body-padding !important;
        background-color: $secret-deal-color;

        h1 {
            @extend .text-center;
            margin-bottom: ($grid-gutter-width * 0.5);
            padding-bottom: $panel-body-padding;
            border-bottom: 1px solid darken($secret-deal-color, 15%);
            color: $secret-deal-heading-color !important;
        }

        p {
            @extend .m-b-sm;
            font-weight: bold;
        }

        ul {
            @extend .m-y-0;

            li {
                margin-bottom: math.div($grid-gutter-width, 6);

            }
        }
    }

    .gallery-footer {
        @extend .hidden-xs-up;
    }
}

.well-secret-deal {
    @include pos-t-r(-95px, 0);
    width: 100%;
    padding: $panel-body-padding;
    border: 0;
    box-shadow: none;

    .inner {
        @extend .text-center;
        padding: $padding-base-vertical 0;
        background: fade(#000, 50%);
        color: #fff !important;
        font-style: italic;
        font-size: $font-size-lead;
    }

    span {
        color: $secret-deal-color !important;
    }
}
