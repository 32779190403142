/* Slider */
div#slick-date {
    margin-bottom: 0px!important;
}
.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

//** Slick Slider

.slick-slider {
    h2, h2 a {
        color: $white !important;
    }
}

.slick-prev,
.slick-next {
    @include transition(.15s ease-in-out);
    color: $white;
    background: $brand-primary;

    &:hover,
    &:focus {
        color: $white;
        background: darken($brand-primary, 10%);
    }
}

.slider-prices, .slider-testimonial {
    .slick-slider {
        border-top-color: $beige-dark;

    }

    .slick-prev,
    .slick-next,
    .slick-arrow-left,
    .slick-arrow-right {
        color: $brand-primary;

        &:hover, &:focus {
            color: darken($brand-primary, 10%);
            background: transparent;
        }
    }
}

.slick-dots li {
    button {
        background: $white;
        border-color: $white;
        &:hover,
        &:focus {
            border-color: $brand-primary;
        }
    }

    &.slick-active button {
        background: $brand-primary;
        border-color: $brand-primary;
    }
}

.slider-prices .slider-pane {
    .slider-body {
        .slider-box {
            .slider-calculated {
                background: $green;
                color: $white !important;

                &.slider-selected {
                    background: $yellow;

                    &:hover {
                        background: darken($yellow-light, 10%)!important;
                    }
                }

                &:hover {
                    background: darken($green, 10%);
                }
            }

            .slider-label {
                color: $white !important;
            }
        }
    }

    &.lowest-price .slider-body .slider-box.top .slider-calculated {
        background-color: $yellow;
        color: $white !important;
    }

    &:hover .slider-body .slider-box.top .slider-calculated {
        background-color: $yellow;
        color: $white !important;
    }
}

.slider-pane.slick-slide.slick-current.slick-center > div > div > div > div.slider-box-heading{
    color: $black!important;
}
// Pricing slider
.slider-date {
    background: transparent;
}

.slider-content {
    background: $beige;
    border-color: $beige-dark;
}

.slider-deals {
    color: $body-color;

    h3, .sub-price {
        color: $body-color;
    }

    .inner {
        background: $beige-dark;
    }
}

.slider-lowest-price {
    &:before {
        background: $yellow-light;
    }
}

.slider-footer {
    background: $white;
    color: $black;

    h3, .sub-price {
        color: $red;
    }
}

// Thumbnails-info slick

.thumbnails-info {
    .card {
        .card-media {
            .carousel {
                &:hover {
                    .slick-next {
                        opacity: 1;
                    }
                }
                .slick-next {
                    opacity: 0;
                }
            }
        }
    }
}