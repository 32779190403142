
@use "sass:math";

.tooltip {
    @extend .pt-0;
    min-width: 150px;
    max-width: 250px;
    box-shadow: 0 -2px 3px rgba(0, 0, 0, .15);

    &.top {
        > .tooltip-arrow {
            border-top-color: #fff;
            bottom: -5px;
            border-width: math.div($grid-gutter-width, 3) math.div($grid-gutter-width, 3) 0;
            margin-left: -(math.div($grid-gutter-width, 3));
        }
    }

    > .tooltip-inner {
        @extend .text-left;
        max-width: 250px;
        padding: $panel-body-padding;
        background: #fff;
        font-size: 12px;

        h4 {
            @extend .mt-0;
            color: $brand-tertiary !important;
            font-size: $font-size-h5;
            font-weight: 700 !important;
            -webkit-font-smoothing: antialiased;
        }

        ul {
            @extend .mb-0;
        }
    }
}
