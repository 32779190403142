@import "button_migrate";
@import "button_themes";

// &anchor entire a { }
a {
  &.btn {
    &.btn-full-white {
      @extend .text-uppercase;
      @extend .font-weight-bold;
      font-size: $font-size-sm;
      cursor: pointer;
      padding: 0.65rem 1rem;
      border-width: 2px !important;
    
      &:focus {
        box-shadow: none !important;
      }
    
      &[class*='-outline'] {
        border-width: 2px;
      } 
    }
    &.btn-white {
      border-color: rgba($white, .5);
    }
  }
}

// &anchor entire a { }
a {
  &.btn {
    @extend .text-uppercase;
    @extend .font-weight-bold;
    font-size: $font-size-sm;
    cursor: pointer;
    padding: 0.65rem 1rem;
    border-width: 2px !important;
  
    &:focus {
      box-shadow: none !important;
    }
  
    &[class*='-outline'] {
      border-width: 2px;
    }
  }
}

.btn {
  @extend .text-uppercase;
  @extend .font-weight-bold;
  font-size: $font-size-sm;
  cursor: pointer;
  padding: 0.65rem 1rem;
  border-width: 2px !important;

  &:focus {
    box-shadow: none !important;
  }

  &[class*='-outline'] {
    border-width: 2px;
  }

  &.btn-booking {
    background-color: $btn-booking;
    border-color: $btn-booking-border;
    color: $btn-booking-color;
    padding-right: 40px;
    position: relative;
  
    &:hover {
        background-color: $btn-booking-hover;
        border-color: $btn-booking-hover-border;
        color: $btn-booking-hover-color;   
    }
  
    &.disabled {
        background-color: $btn-booking;
    }
  
    i {
      position: absolute;
      top: 9px;
      right: 14px;
      font-size: 19px;
    }
  }
}

.btn-link-white {
  border: none;
  display: block !important;

  &:hover {
    background-color: $white;
    color: #000 !important;
  }
}

.btn-group-vertical {
  .btn + .btn {
    margin-left: 0 !important;
  }
}

.btn-icon-after {
  position: relative;
  padding-right: 2.75rem;

  i {
    position: absolute;
    right: .563em;
    top: 0.5rem;
  }
}

.btn-filter {
  padding-left: 2.5rem;

  &:hover, &:focus {
    background: transparent;
    color: $black;
  }

  i {
    position: absolute;
    top: .575rem;
    left: 1.875rem;
  }
}

nav .navbar-main > .navbar-nav li .btn-tickets {
    // Following design exactly
    position: relative;
    bottom: 2px;
    padding: 8px 15px 9px;
    font-size: 18px !important;
}

button {
  font-family: sans-serif;
  cursor: pointer;
}

.btn-mail {
  max-width: 255px;
  width: 100%;
  padding: 10px !important;
  font-size: 0.92rem !important;
  cursor: pointer;
}

@media (max-width: 991px) and (min-width: 768px) {
  .btn-mail {
      max-width: 345px;
  }
}

@include media-breakpoint-down(lg) {
  .btn-mail {
    max-width: 100%;
  }
}
