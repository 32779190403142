.bg-main {
    color: $white;
    background: $booking-row !important;
    .darken {
        background: darken($brand-primary, 5%);
    }

    .lighten {
        background: $booking-step;
    }
}

.bg-warning {
    color: $brown;
    
    .darken {
        background: darken($brand-warning, 5%);
    }
}

.bg-white {
    background: $white;
}

.bg-faded {
    background: $beige;
}

.bg-faded-light {
    background: $faded-light;
}

.bg-yellow-light {
    background: $yellow-light;
}

.bg-unselected {
    background: $booking-row-unselected;


    .lighten {
        background: $booking-step-unselected;
    }
}

.bg-red-light {
    background: $red-light;
}

.bg-beige-dark {
    background: $beige-dark;
}

.border-beige {
    border-color: $beige-dark;
}

.bg-beige {
  background: $beige;
}
