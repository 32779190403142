input, button, select, textarea {
  font-family: $font-family-sans-serif
}

.form-group {
    label {
        .material-icons {
            position: relative;
            top: -2px;
            margin-left: ($spacer * 0.5);
            cursor: pointer;
        }
    }
}

.rtc-group {
    display: block;
    margin-bottom: ($spacer * 2);

    .rtc-content {
        overflow-y: hidden;

        .inner {
            padding: ($spacer * 1.25);
            @include media-breakpoint-only(sm) {
                height: 135px;
            }
        }

        h5 {
            margin-bottom: 10px;
            font-weight: normal;
        }

        .paragraph {
            p {
                &:first-of-type {
                    max-height: 72px;
                    overflow: hidden;
                }
            }
        }

        p {
            margin-bottom: 10px;

            &:last-child {
                @extend .mb-0;
            }
        }

        @include media-breakpoint-only(xs) {
            height: auto !important;
        }
    }

    label {
        @extend .mb-0;
        @extend .p-0;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        overflow: hidden;
        background: $gray-lighter;
        border: 0;
        cursor: pointer;

        &:hover {
            background: darken($gray-lighter, 5%);
        }

        span {
            @include transition('.15s ease-in-out');
            display: block;
            padding-top: ($spacer * .8);
            padding-bottom: ($spacer * .8);

            &:after {
                content: "Select";
            }
        }

        [type="checkbox"] {
            position: absolute;
            top: -50px;

            &:checked {
                + span {
                    background: $brand-primary;
                    color: #fff;

                    &:before {
                        position: absolute;
                        top: .75rem;
                        margin-left: -1.5rem;
                        content: '\E5CA';
                        font-family: 'Material Icons';
                    }

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        &:before {
                            position: relative;
                            top: 0;
                            margin-left: 0;
                            margin-right: 6px;
                            font-size: 12px;
                        }
                    }

                    &:after {
                        content: "Selected";
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            position: relative;
            left: auto;
            bottom: auto;
        }
    }
}

.rtc-group-horizontal {
    .rtc-media {
        @include size(40%, 200px);
        float: left;
        position: relative;
        overflow: hidden;

        img {
            height: 100%;
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: none;
        }
    }

    .rtc-content {
        @include size(60%, 200px);
        float: left;
        position: relative;
    }

    @include media-breakpoint-only(lg) {
        .rtc-media { width: 30%; }
        .rtc-content { width: 70%; }
    }

    @include media-breakpoint-only(md) {
        .rtc-media { width: 35%; }
        .rtc-content { width: 65%; }
    }

    @include media-breakpoint-down(sm) {
        .rtc-media {
            width: 100%;
            height: auto;

            img {
                max-width: 100%;
                height: auto;
                position: static;
                transform: translate(0);
            }
        }

        .rtc-content {
            width: 100%;
            height: auto;
        }
    }
}

.rtc-group-vertical {
    .rtc-media {
        @include media-breakpoint-down(md) {
            float: left;
        }

        img {
            width: 100%;
            height: auto;
        }

        @include media-breakpoint-only(sm) {
            overflow: hidden;
        }

        @include media-breakpoint-down(sm) {
            position: relative;
            float: none;
            overflow: hidden;
        }
    }

    .rtc-content {
        @include media-breakpoint-only(md) {
            float: left;
        }

        height: 250px;
        display: block;

        @include media-breakpoint-only(sm) {
            height: 180px;
        }
    }

    @include media-breakpoint-only(md) {
        .rtc-media {
            width: 35%;
            height: 200px;
            position: relative;
            overflow: hidden;

            img {
                width: auto;
                height: 100% !important;
                max-width: none;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }

        .rtc-content {
            width: 65%;
            height: 200px;
        }
    }
}

.paw-radio-group {
  .btn {
    margin-right: $spacer * 0.5;
    border: 1px solid $gray-light;
    background: #fff;
    color: $gray;

    &:hover {
      background: lighten($brand-secondary, 38%);
      border-color: mix($input-border-color, darken($brand-secondary, 10%), 40%);
      color: $gray;
    }

    &.active {
      background: $brand-secondary;
      border-color: darken($brand-secondary, 10%);
      color: $btn-primary-color;
    }

    &.focus {
      outline: none;
    }
  }
}

.btn-group.paw-radio-group .btn {
  margin-bottom: $spacer * 0.5;
  padding: $input-padding-y $input-padding-x*2;

  > [type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
}
