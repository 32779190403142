.card {
    // Default styles
    // Display flex is important in card, because cards are given the carousel-item class which has display: none.
    display: flex !important;
    box-shadow: none;
    .card-block {
        color: $black;
    }

    .card-text {
        color: rgba($black, .9);
    }

    &:nth-of-type(odd) {
        .card-block {
            background-color: $card-background-odd;
        }
    }

    &:nth-of-type(even) {
        .card-block {
            background-color: $card-background-even;
        }
    }

    // .btn {
    //     @extend .btn-outline-black;
    // }

    &.card-green-dark {
        .card-block {
            background-color: $card-background-green-dark;
            color: $white;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $white;
        }
    }

    &.card-green-light {
        .card-block {
            background-color: $card-background-green-light;
            color: $white;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $white;
        }
    }

    &.card-beige-sand {
        .card-block {
            background-color: $card-background-beige-sand;
            color: $black;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $black;
        }
    }

    &.card-red-light {
        .card-block {
            background-color: $card-background-red-light;
            color: $white;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $white;
        }
    }

    &.card-orange-light {
        .card-block {
            background-color: $card-background-orange-light;
            color: $white;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $white;
        }

        .btn {
            @extend .btn-white;
        }
    }

    &.card-brown-light {
        .card-block {
            background-color: $card-background-brown-light;
            color: $white;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $white;
        }

        .btn {
            @extend .btn-white;
        }
    }

    &.card-brown-earth {
        .card-block {
            background-color: $card-background-brown-earth;
            color: $white;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $white;
        }
    }

    &.card-taupe {
        .card-block {
            background-color: $card-background-taupe;
            color: $white;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $white;
        }
    }

    &.card-taupe-light {
        .card-block {
            background-color: $card-background-taupe-light;
            color: $black;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $black;
        }
    }

    &.card-yellow-sun {
        .card-block {
            background-color: $card-background-yellow-sun;
            color: $black;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $black;
        }
    }

    &.card-white {
        .card-block {
            background-color: $card-background-white;
            color: $black;
        }

        .card-title, 
        .card-title a, 
        .card-text,
        .card-text h1, 
        .card-text h2, 
        .card-text h3, 
        .card-text h4, 
        .card-text h5, 
        .card-text p, 
        a:not(.btn) {
            color: $black;
        }

        //&anchor below
        &.carousel-item {
            &.active {
                display:flex!important;
            }
        }
    }
}

[class*="card-group-overlay"] {
    .card {
        .card-block:not(:hover) {
            background: transparent;
        }

        &:hover {
            .card-block {
                color: $black;

                .card-text {
                    color:$white;
                }
            }

            a:not(.btn) {
                color: $black;
            }
        }
    }
}