@use "sass:math";

section {
    &.no-content {
        .section-content {
            @extend .hidden-xs-up;
        }
    }
}

// &anchor
.section-content {
    h4 {
        font-size: 1.5rem;
    }
}


.section-header {
    position: relative;

    .arrow {
        position: absolute;
        top: -3px;
        right: 0;
    }
}

.section-sm {
    @extend .p-y-sm;
}

.section-md {
    @extend .p-y-md;
}

.section-lg {
    padding-top: ($padding-large-vertical * 5);
    padding-bottom: $padding-large-vertical * 5;

    @include media-breakpoint-down(sm) {
        padding-top: ($padding-large-vertical * 3);
        padding-bottom: ($padding-large-vertical * 3);
    }

    .section-heading, .heading-group {
        @extend .mt-0;
    }
}

.section-heading {
    @extend .text-center;
    margin-bottom: .625rem;
    overflow: hidden;

    // @include media-breakpoint-down(sm) {
    //     margin-bottom: 20px;
    // }
}

.section-footer {
    .btn-link {
        text-transform: none !important;
        font-size: 18px;
    }
}

// .section-content {
//     @include media-breakpoint-up(md) {
//         margin-bottom: -.9375rem;
//     }
// }

.section-footer {
    @extend .m-t-lg;
}

.section-hh {
    @extend .px-0;
    @include transition(all .25s linear);
    padding-top: $grid-gutter-width;
    padding-bottom: 0;
    background-color: #515d31;


    &.section-active {
        @include transition(all .25s linear);
        background: #3b4528;
    }

    .section-content {
        @extend .p-0;
        position: relative;
        line-height: 0; // Difference fix

        p {
            @extend .mb-0;
        }
    }

    img {
        width: 100%;
    }

    .btn-pointer {
        position: relative;
        z-index: 200;
        padding: ($padding-large-vertical * 0.5) ($padding-large-horizontal * .5);
        font-family: $headings-font-family;
        font-size: $font-size-base;
        font-weight: 700;
        background-color: #aa3232;
        color: #ffa900;
        cursor: pointer;
        text-transform: uppercase;

        &:after {
            @include pos-t-l(108%, 50%);
            transform: translate(-50%, -50%);
            content: "";
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #aa3232;
        }
    }

    .highlight-layer {
        @include size(100%, 100%);
        @include pos-t-l;
        z-index: 150;
        background-size: 100% !important; // Without important size isn't printed
        background-repeat: no-repeat !important;
    }

    .btn-sp {
        @include pos-t-l(50%, 22%);
    }

    .btn-vp {
        @include pos-t-l(42%, 74%);
    }

    .btn-sl {
        @include pos-t-l(17%, 50%);
    }

    .btn-ec {
        @include pos-t-l(42%, 42%);
    }

    .btn-sr {
        @include pos-t-l(72%, 32%);
    }

    .btn-sh {
        @include pos-t-l(56%, 36%);
    }

    .hl-sp {
        display: none;
        background: url(/img/map/highlight-sp.png);
    }

    .hl-vp {
        display: none;
        background: url(/img/map/highlight-vp.png);
    }

    .hl-sl {
        display: none;
        background: url(/img/map/highlight-sl.png);
    }

    .hl-ec {
        display: none;
        background: url(/img/map/highlight-ec.png);
    }

    .hl-sr {
        display: none;
        background: url(/img/map/highlight-sr.png);
    }

    .hl-sh {
        display: none;
        background: url(/img/map/highlight-sh.png);
    }

    .popover {
        padding: 20px !important;

        &.top {
            // left: 77%;
            top: 0;
            right: 0;
            left: auto;
        }

        @include media-breakpoint-down(md) {
            @include size(100%, auto);

            &.top {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .popover {
        @extend .text-center;
        width: 240px;

        .popover-title {
            // color: #ffa900 !important;
            // font-size: 26px;
            // text-transform: uppercase;
        }

        .popover-content {
            margin-bottom: math.div($grid-gutter-width, 3);
        }
    }
}

@include media-breakpoint-down(sm) {
    .section-hh {
      height: 320px!important;
      .popover.top{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
        .btn-pointer {
            font-size: 0px;
            border-radius: 50%;
            height: 38px;
            width: 37px;

            &:before {
                @include size(30px, 15px);
                position: absolute;
                top: 30px;
                left: 15px;
                transform: rotate(-20deg);
                content: "";
                background: rgba(0,0,0,.5);
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
                border-top-left-radius: 50%;
            }

            &:after {
                border-left: 15px solid transparent;
                border-right: 14px solid transparent;
                border-top: 10px solid #aa3232;
            }
        }

        .btn-sp {
            @include pos-t-l(47%, 22%);
        }
        .btn-vp {
            @include pos-t-l(40%, 74%);
        }
        .btn-sl {
            @include pos-t-l(13%, 53%);
        }
        .btn-ec {
            @include pos-t-l(38%, 46%);
        }
        .btn-sr {
            @include pos-t-l(65%, 29%);
        }
        .btn-sh {
            @include pos-t-l(41%, 36%);
        }
    }
}

@include media-breakpoint-only(md) {
    .section-hh {
        .btn-pointer {
            padding: ($padding-large-vertical * 0.5) ($padding-large-horizontal * 0.5);
            font-size: 15px;

            &:after {
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 15px solid #aa3232;
            }
        }

        .btn-sp {
            @include pos-t-l(50%, 18%);
        }
        .btn-vp {
            @include pos-t-l(40%, 70%);
        }
        .btn-sl {
            @include pos-t-l(15%, 48%);
        }
        .btn-ec {
            @include pos-t-l(38%, 41%);
        }
        .btn-sr {
            @include pos-t-l(67%, 30%);
        }
        .btn-sh {
            @include pos-t-l(52%, 34%);
        }
    }
}

@include media-breakpoint-only(lg) {
    .section-hh {
        .btn-pointer {
            &:after {
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid #aa3232;
            }
        }

        .popover {
            width: 225px;

            &.top {
                left: 74%;
                top: -7px;
            }
        }

        .btn-sp {
            @include pos-t-l(50%, 19% !important);
        }
        .btn-vp {
            @include pos-t-l(41%, 71% !important);
        }
        .btn-sl {
            @include pos-t-l(16%, 49% !important);
        }
        .btn-ec {
            @include pos-t-l(40%, 41% !important);
        }
        .btn-sr {
            @include pos-t-l(69%, 31% !important);
        }
        .btn-sh {
            @include pos-t-l(55%, 35% !important);
        }
    }
}

// Highlight map splash pages

.section-highlight {
    @extend .pb-0;

    .section-heading {
        @extend .m-b-sm;
    }

    .section-content {
        @extend .px-0;
        @extend .p-t-sm;
        position: relative;

        @include media-breakpoint-down(sm) {
            min-height: 200px;
            padding-top: 0 !important;
            overflow: hidden;
        }
    }

    img {
        width: 100%;

        @include media-breakpoint-down(sm) {
            @include pos-t-l(50%, 50%);
            width: 200%;
            transform: translate(-50%, -50%);
        }
    }

    p {
        @extend .mb-0;
    }

    .btn {
        @include pos-t-l(50%, 50%);
        transform: translate(-50%, -50%);
    }

    &.blue-monkey {
        .btn-primary {
            background: $brand-primary !important;
        }
    }
}

section {
    [class*='jumbotron'] {
        background-size: cover;

        & + .section-content {
            margin-top: -330px;
        }
    }
}
