.jumbotron {
    @extend .mb-0;
    position: relative;
    padding: 0;
    background-position: center;
    background-size: cover;
    border-radius: 0;

    @include media-breakpoint-up(lg) {
        margin-top: -66px;
    }

    h1 {
        font-size: ($font-size-base * 2.5);
    }

    h2 {
        font-size: 48px;
    }

    h4 {
        font-family: "sofia-pro", sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.30;
        opacity: 1.00;
        letter-spacing: -0.72px;
        word-spacing: 0.00px;
        text-transform: none!important;
    }

    p {
        font-size: $font-size-lg;
        color: $white;
    }

    a:not(.btn) {
        color: $white;
        text-decoration: underline;
    }

    .container {
        height: 100%;
        position: relative;
    }

    &.darken {
        position: relative;

        &:before {
            @include size(100%, 100%);
            @include gradient-y(rgba($black,.55), transparent, 0%, 70%);
            position: absolute;
            top: 0;
            left: 0;
            // z-index: 800;
            content: "";
        }
    }
}

.jumbotron-video {
    .embed-responsive {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

.jumbotron-xs {
    @include media-breakpoint-up(lg) { height: 350px; }
    @include media-breakpoint-only(md) { height: 350px; }
    @include media-breakpoint-down(sm) { height: 350px; }
}

.jumbotron-sm {
    @include media-breakpoint-up(lg) { height: 450px; }
    @include media-breakpoint-only(md) { height: 400px; }
    @include media-breakpoint-down(sm) { height: 350px; }
}

.jumbotron-md {
    @include media-breakpoint-up(lg) { height: 550px; }
    @include media-breakpoint-only(md) { height: 450px; }
    @include media-breakpoint-down(sm) { height: 350px; }
}

.jumbotron-lg {
    @include media-breakpoint-up(lg) { height: 650px; }
    @include media-breakpoint-only(md) { height: 500px; }
    @include media-breakpoint-down(sm) { height: 350px; }
}

.jumbotron-sb {
    @include media-breakpoint-down(sm) { 
        height: 160px !important;

        .jumbotron-caption {
            h2 {
                font-size: 1.875rem;
            }
        }
    }
}




// Old styles

body {
    .masked-bg {
        @extend .pb-0;
        position: relative;
        margin-top: -66px !important;
        padding-top: 205px !important;

        .image-bg {
            @include size(100%, auto);
            @include pos-t-l;
            min-height: 450px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        @include media-breakpoint-only(md) {
            margin-top: 0 !important;
            padding-top: 77px !important;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 0 !important;
            padding-top: $grid-gutter-width-base !important;
        }
    }
}
