@use "sass:math";
.mpForm {
    background-color: $beige-light;
    padding: 30px;
    font-size: 16px;
}

.mpTwoColumnLayout {
    @extend .pl-0;
    width: 100%;
    list-style: none;

    input {
        &[type="radio"], &[type="checkbox"] {
            margin-top: 4px;
            margin-right: .5rem;
        }
    }
}

.mpQuestionTable {
    width: 100%;
    position: relative;
    margin-bottom: (math.div($grid-gutter-width, 3) * 2);

    &:last-child {
        margin-bottom: 0;
    }
}

.mpLabelRow {
    @include make-row;
}

.mpFormLabel {
    @include media-breakpoint-up(md) {
        @include make-col(6);
        @include make-col-ready;
    }

    @include media-breakpoint-down(sm) {
        @include make-col(12);
        @include make-col-ready;
    }
}

.mpFormField {
    @include media-breakpoint-up(md) {
        @include make-col(6);
        @include make-col-ready;
    }

    @include media-breakpoint-down(sm) {
        @include make-col(12);
        @include make-col-ready;
    }

    select,
    input[type="text"] {
        @extend .form-control;
        border-width: 1px;

        .error & {
            border-color: $brand-danger;
        }
    }

    ul {
        @extend .pl-0;
        @extend .mb-0;
        list-style: none;

        li {
            display: flex;
        }
    }
}

.mpErrorRow {
    @include make-col(6);
    @include make-col-offset(6);
    @include make-col-ready;
    position: relative;
    display: block;
    font-size: .875rem;
    color: $brand-danger;

    input[type="text"] {
        border-color: $brand-danger;
    }

    &:after {
        @include pos-t-r(-34px, 10px);
        content: '\E5CD';
        color: $brand-danger;
        font-family: 'Material Icons';
        font-size: 18px;
    }

    @include media-breakpoint-down(sm) {
        @include make-col(12);
        @include make-col-ready;
        @include make-col-offset(0);
        top: -38px;
        right: 26px;
    }
}

.mpErrorSummary {
    ul {
        @extend .pl-0;
        list-style: none;

        li {
            position: relative;
            padding-left: 22px;
            line-height: 28px;

            &:before {
                @include mi('close');
                @include pos-t-l(7px, -3px);
                font-size: 15px;
                color: $brand-danger;
            }
        }
    }
}

#CNT17558 {
    .descriptionLabel + .mandatorySign {
        position: absolute;
        right: 10px;
    }

    .mpErrorRow:after {
        top: -62px;
    }
}

.mpLabelRow {
    width: 100%;
    margin-right: 15px;
}

.submitCell {
    margin-left: 15px;
}

.mpMultipleLabel {
    font-weight: 400;
    padding-left: 5px;
}

.mpFormTable {
    table {
        &:last-of-type {
            .mpLabelRow {
                margin-right: 0;
                margin-left: 15px;
                .submitCellSpacer {
                    display: block;
                }
            }
        }
    }
}

.submitButton {
  @extend .btn;
  @extend .btn-primary-color;
}

#CNT11778, #CNT13022 {
    .mpFormLabel, .mpFormField {
        @include media-breakpoint-up(md) {
            @include make-col(12);
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.mpTwoColumnLayout input[type="checkbox"] {
    width: 25px;
    height: 25px;
    margin-top: -2px;
}
