// Default collection styling Cards 3 columns
.CollectionItemDefault {
  @extend .row;
  -webkit-box-pack: justify;
  justify-content: space-between;

  .collection-item {
    @extend .card;
    width: calc(33.333333% - 15px);
  }
}

// News items collection
// .CollectionItemNews {
//   .card-landscape {
//       @extend .row;
//       max-height: 285px !important;

//       &.card {
//           margin-bottom: 2.25rem;
//       }

//       .card-media {
//           flex: 0 0 33.4% ;
//           max-width: 33.4%;
//           max-height: 285px;

//           img {
//               max-height: 285px;
//               object-fit: cover;
//           }
//       }

//       .card-block {
//           flex: 0 0 66.6%;
//           max-width: 66.6%;
//           max-height: 285px;
//           @include media-breakpoint-only(lg) {max-height: 285px;}
//       }
//   }

//   .card-categories {
//       span {
//           margin-right: 0.5rem;
//           padding: 0.75rem;
//           background: rgba(255,255,255,.35);
//           color: $black !important;
//       }
//   }

//   .card-title {
//       font-weight: 600;
//   }
  
//   .card-subtitle {
//       font-weight: normal;
//       font-style: italic;
//   }

//   .badge {
//       padding: 0.75rem;
//   }
  
//     .card-block {
//         padding-bottom: 5rem;

//         .btn {
//             position: absolute;
//             bottom: 1.875rem;
//         }
//     }

//     @include media-breakpoint-down(sm) {
//         .card-subtitle {
//             margin-bottom: 1rem !important;
//         }

//         .collection-item {
//             margin-bottom: 0 !important;
//         }

//         .card {
//             margin-bottom: 0 !important;
//         }
//     }
// }

// .collection-filter {
//     flex: 0 0 66.66667%;
//     max-width: 66.66667%;
//     margin-left: 16.66667%;
// }

.collection-filter-text {
  text-align: center;
}

.collection-filter-label {
    @extend .h3;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 600;

    @include media-breakpoint-up(md) {
        margin-bottom: 1.75rem;
    }
}

.collection-navigation-buttons {
    @extend .text-center;

    // .btn-load-more {
    //     //@extend .btn-taupe;
    // }

    .btn-show-all {
        //@extend .btn-brown-light;
        margin-left: 1rem;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 1rem;
    }
}

// Card overlay collection
.CollectionItemOverlay {
    // @include make-row();
    @include media-breakpoint-down(xs) {
        display: flex;

        // Remove default margin from bootstrap
        // Block content is dynamic so we can't just remove the container style for this section.
        margin-left: -15px;
        margin-right: -15px;
    }
    
    .collection-item {
        @extend .card;
        @include make-col(4);
        margin-bottom: 0 !important;
        color: $white;

        .card-img-overlay {
            background-color: $brand-primary;
        }

        &:not(:hover) {
            .card-img-overlay {
              top: auto;
              padding: 0.75rem 1.25rem;
              background: transparent !important;
              
              .card-title {
                @extend .mb-0;
              }

              .card-text, .btn {
                display: none;
              }
           }
        }

        @include media-breakpoint-down(xs) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}


.collection-filter-buttons {
    margin-top: 1.75rem;
    margin-bottom: 4rem;

    @include media-breakpoint-up(lg) {
        text-align: center;
    }

    h5 {
        display: none;
    }

    .form-check {
        display: inline-block;
        margin-right: .5rem;
        margin-bottom: 0;
    }

    .btn {
        border-width: 2px;
        padding: .5rem .75rem;
    }

    input[type=checkbox] {
        position: absolute;
        clip: rect(0,0,0,0);
        pointer-events: none;
    }

    input[type=checkbox]:checked + label {
        background: $brand-primary;
        color: $white;
        padding-right: 2.2rem;

        &:after {
            content: "\E14C";
            position: absolute;
            top: 7px;
            right: 10px;
            font-family: "Material Icons";
            font-size: 1.25rem;
        }
    }
}