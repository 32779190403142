.collection-item {
    .card-landscape {
        @extend .mx-0;

        &.card {
            margin-bottom: 2.25rem;
        }

        .card-media {
            @include make-col(4);

            @include media-breakpoint-only(lg) {
                @include make-col(4.5);
            }

            @include media-breakpoint-only(md) {
                @include make-col(4.5);
            }

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .card-block {
            @include make-col(8);

            @include media-breakpoint-only(lg) {
                @include make-col(7.5);

                .card-title {
                    font-size: 1.5rem;
                }
            }

            @include media-breakpoint-only(md) {
                @include make-col(7.5);

                .card-title {
                    font-size: 1.25rem;
                }

                .card-subtitle {
                    font-size: 1rem;
                }
            }

            .card-title {
                word-break: normal;
            }
        }
        
        &.first-result {
            margin-bottom: 3.75rem;

            .card-media {
                @include make-col(6);
            }

            .card-block {
                @include make-col(6);
            }
        }
    }

    .card-portrait {
        display: flex;
        flex-direction: row;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        &.card {
            margin-bottom: 2.25rem !important;
        }

        .card-media {
            @include make-col(12);
        }

        .card-block {
            @include make-col(12);

            .card-title {
                font-size: 1.25rem;
            }

            .card-subtitle {
                font-size: 1rem;
            }
        }
    }

    .card-categories {
        span {
            margin-right: 0.5rem;
            padding: 0.75rem;
            background: rgba(255,255,255,.35);
            color: $black !important;
        }
    }

    .card-title {
        word-break: normal;
        font-weight: 600;
    }
    
    .card-subtitle {
        font-weight: normal;
        font-style: italic;
    }

    .badge {
        padding: 0.75rem;
    }

    // .img-fluid {
    //     min-height: 100%;
    // }
}

.collection-filter {
    @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-offset(2);
    }

    @include media-breakpoint-down(sm) {
        .holder-sb & {
            @include make-col(12);
            @include make-col-ready;
            @include make-col-offset(0);
        }
    }
}

.collection-filter-text {
    @extend .text-center;
}

.collection-filter-label {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 600;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
        margin-bottom: 1.75rem;
    }
}

.collection-filter-widget {
    position: relative;
    padding: .7rem;
    background: $beige-light;

    &:after {
        content: "\E8B6";
        position: absolute;
        top: .45rem;
        right: 1rem;
        font-family: "Material Icons";
        font-size: 2rem;
        color: #000;
    }

    input {
        background: none;
        padding-right: 3rem;
        border: none;

        &:focus {
            background: none;
        }
    }
}

.collection-filter-category {
    display: none;
}

.collection-filter-buttons {
    margin-top: 1.75rem;
    margin-bottom: 4rem;

    @include media-breakpoint-up(lg) {
        text-align: center;
    }

    h5 {
        display: none;
    }

    .form-check {
        display: inline-block;
        margin-right: .5rem;
        margin-bottom: 0;
    }

    .btn {
        border-width: 2px;
        padding: .5rem .75rem;
    }

    input[type=checkbox] {
        position: absolute;
        clip: rect(0,0,0,0);
        pointer-events: none;
    }

    input[type=checkbox]:checked + label {
        @extend .btn-green;
        padding-right: 2.2rem;

        &:after {
            content: "\E14C";
            position: absolute;
            top: 7px;
            right: 10px;
            font-family: "Material Icons";
            font-size: 1.25rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .holder-sb & {
            text-align: left !important;

            .form-check {
                display: block;
                float: left;
                margin-right: 5px;

            }
        }
    }
}

.CollectionItemDefault {
    margin-top: 3rem;

    .card {
        @extend .card-landscape;
        @extend .image-left;

        @include media-breakpoint-only(md) {
            margin-left: 0px;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 0px;

            .card-media {
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }

            .card-block {
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
        }
    }

    .collection-item {
        &:hover {
            .card-block {
                background: darken($beige, 5%);
            }
        }
    }
    
    a {
        &:hover {
            text-decoration: none !important;
        }
    }
}

.CollectionItemNews {
    .card-block {
        padding-bottom: 5rem;

        .btn {
            position: absolute;
            bottom: 1.875rem;
        }
    }

    // @include media-breakpoint-only(md) {
    //     .card-media {
    //         flex: 0 0 50% !important;
    //         max-width: 150% !important;
    //     }

    //     .card-block {
    //         flex: 0 0 50% !important;
    //         max-width: 250% !important;
    //     }
    // }

    @include media-breakpoint-down(sm) {
        .card-subtitle {
            margin-bottom: 1rem !important;
        }

        .collection-item {
            margin-bottom: 0 !important;
        }

        .card {
            margin-bottom: 0 !important;
        }
    }
}

.CollectionItemOverlay {
    @extend .card-group;
    @extend .card-group-overlay-md;

    .collection-item {
        @extend .card;
        @extend .card-green-dark;
        margin-bottom: 0 !important;
        //@extend .carousel-item;

        .card-block {
            background-color: $green !important;

            &:not(:hover) {
                background: transparent !important;
            }
        }

        // Life long the hacks 🖖 to fix hacks.
        @include media-breakpoint-down(md) {
            &:hover .card-block {
                top: auto;

                &:hover {
                    background-color: transparent !important
                }

                & .card-text, & .btn {
                    display: none !important
                }
            }
        }

        @include media-breakpoint-down(lg) {
            &:hover .card-block {
                & .card-text:after {
                    content: '';
                    display: block;
                    background: linear-gradient(0deg, #596831 20%, transparent 50%);
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.collection-navigation-buttons {
    @extend .text-center;

    .btn-load-more {
        @extend .btn-taupe;
    }

    .btn-show-all {
        @extend .btn-brown-light;
        margin-left: 1rem;
    }

    @include media-breakpoint-down(xl) {
        margin-top: 1.5rem;
    }

    @include media-breakpoint-down(md) {
        margin-top: 1rem;
    }
}