$jumbotron-height-xs: 350px;
$jumbotron-height-sm: 400px;
$jumbotron-height-md: 500px;
$jumbotron-height-lg: 600px;

.jumbotron {
  height: $jumbotron-height-sm;
}

.jumbotron-video {
    margin-bottom: 0;
    padding: 0;
    overflow: hidden;
    background: transparent;
    max-height: 600px;
    background-color: black;

    video {
        height: auto !important;
    }


    .big-positioned {
        position: absolute;
        top:0;
        z-index: 1001;
        height: 100%;
        width: 100%;
        pointer-events: none;
        .play-button {
            display: flex;
            height: 100%;
            div {
                opacity: 0.4;
                border: 15px solid white;
                border-radius: 100px;
                height: 200px;
                width: 200px;
                margin: auto;
                pointer-events: initial;
                display: flex;
                i {
                    transform: scale(8);
                    cursor: pointer;
                    color: #FFF;
                    margin: auto;
                }

                &:hover {
                    opacity: .8;
                }
            }
        }
    }

    .caption-show {
        opacity: 1;
        transition: opacity 1.2s
    }

    .caption-fade {
        opacity: 0;
        transition: opacity 1.2s
    }

    .video-controls {
        @include size(100%, 100%);
        position: absolute;
        top: 0;
        left: 0;
        padding: 1rem;
        z-index: 1000;
        
        div {
            position: absolute;
            top: 50%;
            left: 1.5rem;
            bottom: auto;
            transform: translate(0%,-50%);
        }

        span {
            @include size(40px, 40px);
            display: block;
            padding-top: 5px;
            margin-bottom: 0.75rem;
            border: 4px solid #fff;
            border-radius: 50px;
            text-align: center;
            opacity: .4;
            cursor: pointer;
            transition: all .2s ease-in-out;

            i {
                position: relative;
                font-size: 1.2rem;
                color: #FFF;
                top: -5px;
            }

            &:hover {
                opacity: .8;
            }
        }
    }
}

.embed-responsive {
    @include media-breakpoint-down(sm) {
        position: initial;
    }
}


.jumbotron-video {
    height: 650px;

    @include media-breakpoint-down(lg) {
        height: 570px;
    }

    @include media-breakpoint-down(md) {
        height: auto;
    }

    .jumbotron-inner {
        height: 100%;
        overflow: hidden;
    }
}

.jumbotron-video .embed-responsive {

    @include media-breakpoint-down(sm) {
        width: 100%;

        &.embed-responsive-16by9 {
            transform: translateY(-50%) scale(1.25);
        }
    }

    @media (max-width: 520px) {
        &.embed-responsive-16by9 {
            transform: translateY(-50%) scale(2);
        }
    }

}

.embed-responsive {
    min-height: 100%;

    @include media-breakpoint-down(sm) {
        height: 100%;
    }
}

.video-thumb {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 190;
    transition: all .3s ease-in-out;
    opacity: 0;

    &.show {
        opacity: 1;
    }

    @media (min-width: 1100px) {
        background-position-y: center;
    }

    &.in {
        opacity: 0;
        transition: opacity .75s ease-in-out;
        -moz-transition: opacity .75s ease-in-out;
        -webkit-transition: opacity .75s ease-in-out;
        &.visible {
            opacity: 1;
            transition: opacity .05s ease-in-out;
            -moz-transition: opacity .05s ease-in-out;
            -webkit-transition: opacity .05s ease-in-out;
        }
    }
}

.modal-gallery {
    .embed-responsive .embed-responsive-item {
        top: 50%;
        transform: translateY(-50%);
    }
}

.jumbotron-caption {
    @extend .text-center;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: auto;
    z-index: 850;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: $white;
    padding: 0 1.87rem;

    @include media-breakpoint-down(lg) {
        width: 75%;
    }

    @include media-breakpoint-down(sm) {
        width: 90%;

        h1, h2 {
            font-size: 2.5rem;
        }
    }
}
