// Wrapper for the slide container and indicators

@use "sass:math";
.carousel {
  position: relative;

    .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    }

    .carousel-item {
    position: relative;
    display: none;
    width: 100%;
    max-height: 500px;

    @include if-supports-3d-transforms() {
        @include transition($carousel-transition);
        backface-visibility: hidden;
        perspective: 1000px;
    }
    }

    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
        display: flex;
    }

    .carousel-item-next,
    .carousel-item-prev {
        position: absolute;
        top: 0;
    }

    // CSS3 transforms when supported by the browser
    @include if-supports-3d-transforms() {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        transform: translate3d(0, 0, 0);
    }

    .carousel-item-next,
    .active.carousel-item-right {
        transform: translate3d(100%, 0, 0);
    }

    .carousel-item-prev,
    .active.carousel-item-left {
        transform: translate3d(-100%, 0, 0);
    }
    }


    //
    // Left/right controls for nav
    //

    .carousel-control-prev,
    .carousel-control-next {
        // position: absolute;
        // top: 0;
        bottom: 0;
        // Use flex for alignment (1-3)
        display: flex; // 1. allow flex styles
        align-items: center; // 2. vertically center contents
        justify-content: center; // 3. horizontally center contents
        // width: $carousel-control-width;
        color: $carousel-control-color;
        text-align: center;
        opacity: .7;
        // We can't have a transition here because WebKit cancels the carousel
        // animation if you trip this while in the middle of another animation.

        // Hover/focus state
        @include hover-focus {
            color: $carousel-control-color;
            text-decoration: none;
            outline: 0;
            opacity: .9;
        }
    }
    .carousel-control-prev {
        left: 0;
    }
    .carousel-control-next {
        right: 0;
    }

    // Icons for within
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        display: inline-block;
        height: $carousel-control-icon-width;
        background: transparent no-repeat center center;
        background-size: 100% 100%;
    }
    .carousel-control-prev-icon {
        background-image: $carousel-control-prev-icon-bg;
    }
    .carousel-control-next-icon {
        background-image: $carousel-control-next-icon-bg;
    }


    // Optional indicator pips
    //
    // Add an ordered list with the following class and add a list item for each
    // slide your carousel holds.

    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 10px;
        left: 0;
        z-index: 15;
        // display: flex;
        justify-content: center;
        padding-left: 0; // override <ol> default
        // Use the .carousel-control's width as margin so we don't overlay those
        margin-right: $carousel-control-width;
        margin-left: $carousel-control-width;
        list-style: none;
        text-align: center;

        li {
            position: relative;
            flex: 1 0 auto;
            max-width: $carousel-indicator-width;
            height: $carousel-indicator-height;
            margin-right: $carousel-indicator-spacer;
            margin-left: $carousel-indicator-spacer;
            text-indent: -999px;
            cursor: pointer;
            background-color: rgba($carousel-indicator-active-bg, .5);

            // Use pseudo classes to increase the hit area by 10px on top and bottom.
            &::before {
                position: absolute;
                top: -10px;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 10px;
                content: "";
            }
            &::after {
                position: absolute;
                bottom: -10px;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 10px;
                content: "";
            }
        }

        .active {
            background-color: $carousel-indicator-active-bg;
        }
    }


    // Optional captions
    //
    //

    .carousel-caption {
        position: absolute;
        right: ((100% - $carousel-caption-width) * 0.5);
        bottom: 20px;
        left: ((100% - $carousel-caption-width) * 0.5);
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: $carousel-caption-color;
        text-align: center;
    }
}

// Custom styyyyles

.carousel-item {
    align-items: flex-start;
}

.carousel-control {
    @include size(50px, 50px);
    @include transition(.15s ease-in-out);
    transform: translate(0, -50%);
    // Issue: Bootstrap.css found in node_modules is overwriting the coming two properties for something reason.
    width: 50px !important;
    top: 50% !important;
    opacity: 1;
    background: rgba(black, .25);
    background-image: none !important;
    filter: none;
    text-shadow: none;
    position: absolute;

    &:hover {
        background: rgba(black, .5);
        opacity: 1;
    }

    .fa, .material-icons {
        @include pos-c-c;
        font-size: $h1-font-size;
        color: $white !important;
    }
}

.card-group-lg {
    .carousel-item{
        display: flex !important
    }
}

.slider {
    position: relative;

    .media-left,
    .media-body,
    .media-right {
        display: table;
        float: none;
    }
}

.slick-prev,
.slick-next,
.slick-arrow {
    @include size(50px, 50px);
    @extend .p-0;
    @extend .hidden-sm-down;
    position: absolute;
    top: 50%;
    display: block;
    transform: translate(0, -50%);
    cursor: pointer;
    line-height: 0;
    font-size: 0;
    color: $white !important;
    border: 1px solid black !important;
    outline: none;
    background: #000;
    opacity: 0.5;
    border: 2px solid $white;

    &:hover,
    &:focus {
        background: $yellow;
        border-color: $yellow !important;
        color: $black;
        opacity: 1;
        outline: none;
    }

    &:before {
        font-size: 3rem !important;
    }

    &.slick-disabled {
        opacity: $slider-opacity-not-active;
    }

    &.slick-arrow-md {
        @include size(40px, 40px);

        &:before {
            font-size: 2rem;
        }
    }
}

.slick-arrow-left {
    left: -65px;
    display: none;

    @include media-breakpoint-only(xl) {
      top: 150px;
    }
    @include media-breakpoint-down(lg) {
      display:none!important;
    }
    &:before {
        @include mi('\E5C4');
    }
}

.slick-arrow-right {
    right: -65px;
    left: auto;
    display: none;

    @include media-breakpoint-only(xl) {
        top: 150px;
    }
    @include media-breakpoint-down(lg) {
      display:none!important;
    }

    &:before {
        @include mi('\E5C8');
    }
}

.slick-prev {
    // left: -25px;

    &:before {
        @include mi('\E5C4');
    }
}

.slick-next {
    right: -25px;

    &:before {
        @include mi('\E5C8');
    }
}

.carousel-control-prev.slick-prev.slick-arrow {
    left: 0px;
}

.carousel-control-next.slick-next.slick-arrow {
    right: 0px;
}

.slick-dots {
    @include transition(.15s ease-in-out);
    @extend .m-0;
    @extend .p-0;
    width: 100%;
    display: block;
    position: absolute;
    bottom: math.div($grid-gutter-width-base * 2, 3);
    list-style: none;
    text-align: center;

    li {
        @include size(15px, 15px);
        margin: 0 math.div($grid-gutter-width, 6);
        display: inline-block;
        position: relative;
        cursor: pointer;

        button {
            @include size(15px, 15px);
            @include transition(.15s ease-in-out);
            @extend .p-0;
            display: block;
            background: rgba($black, .5);
            outline: none;
            color: transparent;
            cursor: pointer;
            border-radius: 50%;
            text-indent: -999em;
            border: 0;

            &:hover,
            &:focus {
                background: $white;
                outline: none;
            }
        }

        &.slick-active button {
            background: $white;
        }
    }
}

.slider-prices {
    z-index: $zindex-content;
    display: block;

    .slick-slider {
        @extend .mb-3;
        background-color: $beige;
        padding:8px;
        margin-bottom:0px!important;;
    }

    .slider-pane {
        z-index: $zindex-content;

        .slider-body {
            z-index: $zindex-content;
            text-align: center;

            .slider-box {
                position: relative;
                margin: 80px ceil(($grid-gutter-width-base * 0.5)) 0;
                z-index: ceil(($zindex-content + 5));
                height: $slider-prices-height;

                .slider-calculated {
                    @include pos-b(0, 0, 0);
                    @include transition(.15s ease-in-out);
                    padding: 5px 0;
                    height: 100%;
                    color: $yellow;
                    cursor: pointer;
                }

                .slider-lowest-price {
                    height: 40px;
                    position: relative;
                    overflow: hidden;
                    z-index: 10;
                    top: 15px;

                    span {
                        position: relative;
                        top: 5px;
                        z-index: 100;
                        color: black !important;
                    }

                    &:before {
                        @include size(102%, 30px);
                        display: block;
                        position: absolute;
                        top: 5px;
                        left: 0;
                        margin-left: -1%;
                        content: '';
                        transform: rotate(-2deg);
                    }
                }

                .slider-box-heading {
                    @include pos-b-l(30px, 0);
                    width: 100%;
                    font-family: $headings-font-family;
                    -webkit-font-smoothing: antialiased;
                    font-weight: 700 !important;
                    font-size: $h4-font-size;
                    color: white !important;

                    @include media-breakpoint-only(md) {
                        font-size: 26px;
                    }

                    s {
                        font-size: $font-size-small;
                        color: white !important;
                    }
                }
            }

            &.disabled {
                .slider-box {
                    .slider-box-heading {
                        color: rgba(white, .5) !important;
                    }
                }
            }
        }
    }

    .slick-arrow {
        background: none;
        margin-left: 0px;
    }

    .slick-prev {
        left: -80px;
    }

    .slick-next {
        background: none;
        right: -80px;
    }

    .slider-date {
        @extend .p-y-sm;
        text-align: center;
    }

    .slider-content {
        @extend .mb-0;
        padding-top: $panel-body-padding;
        background: $taupe-light;

    }

    .form-dropdown-box {
        background: transparent;
    }

    .slider-footer {
        padding: $panel-body-padding;

        .row{
          @include media-breakpoint-only(md) {
            flex-direction: row;

          }
        }

        h3 {
            font-weight: bold !important;
            font-family: $headings-font-family;
            -webkit-font-smoothing: antialiased;
            font-weight: 700 !important;
        }

        .sub-price {
            @extend .p-t-sm;
        }
    }

    .slider-deals {
        margin: $grid-gutter-width-base 0;

        @include media-breakpoint-down(sm) {
            margin-top: 0 !important;
        }

        .inner {
            padding: $panel-body-padding;
            background: $white!important;
        }

        h3 {
            @extend .mt-0;
            @extend .m-b-md;
            font-family: $headings-font-family;
            -webkit-font-smoothing: antialiased;
            font-weight: 700 !important;
        }

        p {
            @extend .m-y-0;
        }

        .btn {
            @extend .m-t-md;
        }
    }

    .sub-price {
        @extend .h4;
        @extend .m-0;
        display: block;
        text-align: right;
        font-family: $headings-font-family;
        -webkit-font-smoothing: antialiased;
        font-weight: 700 !important;
    }

    .best-deal {
        @include size(60px, 60px);
        @include pos-t-l(-35px, 60%);
        @extend .p-t-md;
        display: block;
        text-align: center;
        font-family: $headings-font-family;
        -webkit-font-smoothing: antialiased;
        font-weight: 700 !important;
        border-radius: 50%;
        color: white;
        font-size: $font-size-lead;
        line-height: $font-size-lead;
    }
}

.slider-testimonial {

    h2 {
        @extend .m-t-md;
        text-decoration: none !important;
    }

    .slider-content {
        padding-bottom: $spacer-y;
    }

    .slider-pane {
        text-align: center !important;
    }

    .slider-heading,
    .slider-body {
        @include media-breakpoint-only(lg) {
            @include make-col-ready;
            @include make-col(8);
            @include make-col-offset(2);
        }

        @include make-col-ready;
        @include make-col(12);
        @include make-col-offset(0);
    }

    .media {
        .media-left,
        .media-right,
        .media-body {
            display: inline-block;
            width: auto;
        }
        .media-body {
            margin-top: 7px;

            > :not(.media-heading) {
                font-style: italic;
            }

            h4 {
                @extend .mb-0;
            }

            .media-heading {
                font-size: $font-size-large;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }


    @include media-breakpoint-down(md) {
        position: relative;
        margin-top: math.div($grid-gutter-width-base, 3) * 2 !important;
    }
}


.carousel-block {
    @include transition(.15s ease-in-out);
    // min-height: 500px;
    // height: 500px;
    // opacity: 0;

    .carousel-item {
        // min-height: 500px;
        // height: 500px;
        // position: relative;
        // background-size: cover;
        // background-position: center;
        text-align: center;

        &:before {
            @include size(100%, 100%);
            @include pos-t-l;
            content: "";
            display: block;
            background: rgba($black, .25);
        }
    }

    .carousel-item > img {
      width: 100%;
    }

    // .slick-prev,
    // .slick-next {
    //     @include size(60px, 60px);
    //     // @include mask(none);
    //     z-index: 100;
    //     background-repeat: no-repeat;
    // }

    .carousel-control-prev {
        left: 3rem;
    }

    .carousel-control-next {
        right: 3rem;
    }

    .carousel-indicators {
      li {
        margin: 0 5px;
        display: inline-block;
        position: relative;
        background: rgba(0,0,0,.5);
        outline: 0;
        color: transparent;
        border-radius: 50%;
        text-indent: -999em;
        border: 0;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
      li.active {
        background-color: #fff;
      }
    }

    .caption {
        @include pos-c-c;
        width: 25%;
        color: $white;

        h2 {
            @extend .anton;
            @extend .mt-0;
            @extend .mb-3;
            @extend .text-uppercase;
            font-size: 3rem;
        }

        h3 {
            @extend .mb-3;
            color: $white;
        }

        p {
            @extend .mt-4;
        }

        @include media-breakpoint-only(lg) {
            width: 50%;
        }

        @include media-breakpoint-only(md) {
            width: 55%;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            padding: 0 3rem;

            h2 {
                font-size: 2rem;
            }
        }
    }
}

.gallery-slider {
    opacity: 0;

    .slick-arrow {
        @include size(70px, 70px);
        z-index: 100;
    }

    .slick-prev {
        left: 3rem;
    }

    .slick-next {
        right: 3rem;
    }
}

.carousel {
    .slick-arrow {
        // @include size(70px, 70px);
        z-index: 100;
        text-decoration: none;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}

.yellow-bourbon {
    .slick-arrow {
        background: transparent !important;
        color: rgba($brand-primary, .75);
    }
}

.sf-left {
    @include media-breakpoint-up(lg) {
        @include make-col-ready;
        @include make-col(3);
    }
    @include media-breakpoint-only(md) {
      padding-left:15px;
    }
    @include media-breakpoint-down(md) {
      width:100%;
      text-align: center;
    }
}

.sf-mid {
  margin: auto;
    div > div {
      strong{
        color:$taupe-light;
      }
    }
    .column {
        @include media-breakpoint-up(lg) {
            @include make-col-ready;
            @include make-col(6);
        }
        text-align: right;
        &:last-child {
            text-align: left;
        }
    }

    @include media-breakpoint-up(lg) {
        @include make-col-ready;
        @include make-col(6);
    }

    @include media-breakpoint-only(md) {
        @include make-col-ready;
        @include make-col(4);
    }

    @include media-breakpoint-down(md) {
      margin:auto;
        div > div {
          padding-right:20px;
          strong{
            color:$taupe-light;
          }
    @include media-breakpoint-down(xs) {
      margin-left:25%;
      }
    }

        .column {
            text-align: left;
            flex-direction: column;
        }
    }
}

.sf-right {
    top: .5rem;
    @include media-breakpoint-down(md) {
        width: 100%;
        margin-left:15px;
        margin-right: 15px;
    }

    > div > div:nth-child(1){
        @include media-breakpoint-down(md) {
          width: 100%;
        }
    }
        > div > div:nth-child(2){
        @include media-breakpoint-down(md) {
          width: 100%;
        }
    }
    text-align: right;
    @include media-breakpoint-up(lg) {
        @include make-col-ready;
        @include make-col(3);
    }



    @include media-breakpoint-only(md) {
        @include make-col-ready;
        @include make-col(5);
        width: 100%;
    }

    .column {
        @include media-breakpoint-up(md) {
            @include make-col-ready;
            @include make-col(5);
            text-align: right;

            &:last-child {
                @include make-col-ready;
                @include make-col(7);
            }
        }

    }

    @include media-breakpoint-only (lg) {
      top:15px;
    }

    @include media-breakpoint-up (xl) {
      top:3px;
    }

    @include media-breakpoint-up(lg) {
      @include make-col-ready;
      @include make-col(3);
    }

    @include media-breakpoint-only(md) {
        @include make-col-ready;
        @include make-col(5);
    }

    @include media-breakpoint-down(md) {
        text-align: left;
    }
}

.sub-price{
    width: 92px;
      @include media-breakpoint-down(md) {
        width: 100%;
    }
}
