.footer-social {
  background: $footer-light-tone;
  color: $footer-text-color;
  * {
    color: $footer-text-color;
  }

  &.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  h4 {
    @extend .mb-3;
  }

  h4,
  h5 {
    color: $footer-text-color;
    font-weight: 600;
  }

  p {
    color: $footer-text-color;
  }

  li {
    &.list-checked-item {
      &:before {
        color: $footer-checked-items-color;
      }
    }
  }

  .btn {
    padding: 0.9rem 0;
    text-transform: none !important;
    font-size: $font-size-h5;
  }

  .btn-mail {
    text-transform: uppercase !important;
  }

  .btn-submit {
    @extend .btn-green;
    width: 29%;
    display: inline-block;
    vertical-align: top;
    margin-left: -5px;
    padding: 0.7rem 1rem;
    @include media-breakpoint-down(lg) {
      width: 35%;
    }

    &:hover {
      background: $green-light !important;
    }
  }

  .list-faq {
    color: $footer-text-color;
    margin-left: 1rem;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-bottom: 2rem;
    }

    li {
      margin-bottom: 0.5rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include media-breakpoint-down(md) {
    input,
    .btn-submit {
      display: inline-block;
      width: 100%;
      margin-left: 0px;
    }
  }

  .social-icons {
    float: left;
    margin: 0;

    li {
      margin-right: 0;

      &:last-child {
        .btn-icon {
          border-right: 1px solid $footer-social-media-border-color;
        }
      }
    }
  }

  .btn-icon {
    @include size(60px, 50px);
    position: relative;
    background: $footer-social-media-background-color;
    line-height: 120px;
    border: 1px solid $footer-social-media-border-color;
    border-radius: 0;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.75rem;
      color: $brown-light;
    }

    &:hover {
      background: $footer-social-media-background-hover-color;

      &:before {
        color: #fff;
      }
    }

    @include media-breakpoint-down(md) {
      .social-icons {
        height: 52px;
        float: none;
        border: 1px solid $beige-dark;
        margin-bottom: $spacer;

        li {
          width: 33%;
          display: inline-block;
          border-width: 0 1px 0 0;
          border-style: solid;
          border-color: $beige-dark;
          margin-left: 0;

          + li {
            margin-left: -5px !important;
          }

          .btn-icon {
            border: 0;
          }

          &:last-child {
            border: 0;

            .btn-icon {
              border-width: 0;
            }
          }

          a {
            width: 100%;
            display: block;
          }
        }
      }
    }

    .btn-brochure {
      width: 45%;
      float: left;
      margin-left: 0 !important;
    }
  }

  @include media-breakpoint-down(md) {
    .social-icons {
      height: 52px;
      float: none;
      border: 1px solid $beige-dark;
      margin-bottom: $spacer;

      li {
        width: 25%;
        display: inline-block;
        border-width: 0 1px 0 0;
        border-style: solid;
        border-color: $beige-dark;
        margin-left: 0;

        + li {
          margin-left: -5px !important;
        }

        .btn-icon {
          border: 0;
        }

        &:last-child {
          border: 0;

          .btn-icon {
            border-width: 0;
          }
        }

        a {
          width: 100%;
          display: block;
        }
      }
    }
  }

  .btn-brochure {
    border: 1px solid $footer-dark-tone;
    color: $footer-dark-tone;
    padding: 0.71rem 1rem;
    margin-left: 1rem;

    &:hover {
      background: $footer-dark-tone;
      color: #fff;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block;
      margin: 0;
    }
  }

  .help-block {
    font-size: $font-size-h6;
    margin-bottom: 3.5rem;
    font-style: normal;
  }

  .col-xs-6 {
    @include media-breakpoint-only(xs) {
      @include make-col(6);
    }
  }

  .btn-secondary {
    background: $btn-main;
    color: $btn-main-color;
    border: $btn-main-border !important;
    padding: 0.5rem 1.5rem;
    font-size: 14px;
    &:hover {
      background: $btn-main-hover;
      color: $btn-main-hover-color;
      border: $btn-main-hover-border;
    }
  }

  .btn-subscribe-submit {
    color: $footer-submit-text-color;
  }
}

.footer-usp {
  background: $footer-mid-tone;
  color: $footer-text-color;

  &.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .list-checked {
    margin-top: 1.5rem !important;
  }

  .list-checked-item {
    margin-bottom: 0.5rem;
    font-size: $font-size-h5;

    &:before {
      top: 5px;
    }

    @include media-breakpoint-only(xs) {
      font-size: 1rem;

      &:before {
        top: 0px;
      }
    }
  }

  h5 {
    @extend .mb-4;

    @include media-breakpoint-down(sm) {
      text-align: left !important;
    }

    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }
  }

  // p {
  //     white-space: nowrap;
  // }

  @media (max-width: 600px) {
    p {
      font-size: 14px;
    }
  }
}

.footer-link-holder {
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}

.footer-sm {
  background: $footer-dark-tone;
  padding: 1rem 0 !important;

  p {
    margin-top: 3px;
  }

  p,
  a {
    color: white;
    font-size: 1rem !important;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  p,
  ul {
    margin-bottom: 0;
  }

  .list-inline {
    li {
      margin-right: 40px;
      @include media-breakpoint-down(lg) {
        margin-right: 15px;
      }
    }
  }

  .section-lg {
    padding: 30px 0px 20px !important;
    margin: 0px !important;
  }

  img {
    margin-top: 0px;
    margin-left: 3px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0.25rem 0 !important;

    p {
      text-align: left !important;
    }

    ul {
      margin-bottom: 0.25rem;
    }
  }

  @include media-breakpoint-down(md) {
    p {
      text-align: center !important;
      color: rgba(255, 255, 255, 0.4);
    }

    img {
      opacity: 0.4;
    }

    .list-inline {
      text-align: center;
    }
  }
}

.btn-icon {
  @include size(30px, 26px);
  @extend .p-0;
  @extend .mr-2;
  @extend .text-center;
  font-size: $font-size-lead;
  display: block;
  overflow: hidden;
  color: #fff;

  &:hover,
  &:active {
    color: mix(#fff, $green, 75%);
  }

  &:last-of-type {
    @extend .mr-0;
  }

  &.btn-facebook {
    background: transparent;

    &:before {
      @include fa($fa-var-facebook);
      color: $footer-social-media-icon-color;
    }
  }

  &.btn-instagram {
    &:before {
      @include fa($fa-var-instagram);
      color: $footer-social-media-icon-color;
    }
  }

  &.btn-twitter {
    &:before {
      @include fa($fa-var-twitter);
      color: $footer-social-media-icon-color;
    }
  }

  &.btn-youtube {
    &:before {
      @include fa($fa-var-youtube-play);
      color: $footer-social-media-icon-color;
    }
  }

  &.btn-google {
    &:before {
      @include fa($fa-var-google-plus);
      color: $footer-social-media-icon-color;
    }
  }

  &.btn-whatsapp {
    &:before {
      @include fa($fa-var-whatsapp);
    }
  }

  &.btn-envelope {
    &:before {
      @include fa($fa-var-envelope);
    }
  }
}
