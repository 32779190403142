.social {
    @extend .m-2;
    @extend .pt-1;
    @extend .px-1;
    @extend .pb-0;
    cursor: pointer;
    border: 2px solid $social-border-color;
}

.social-block {
    @extend .mx-0;
    > .col-xs-12 {
        @extend .px-0;
    }
}
