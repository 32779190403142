body {
    &#booking-app {
        @media (max-width: 991px) {
            padding-top: 0;
        }

        &:before {
            content: unset;
        }
    }
}


.receipt-holder {
  .label-vpbb, 
  .label-srbb {
      position: absolute;
      top: 0;
      padding: 15px 20px 15px 62px;
      z-index: 0;
      font-size: 1.25rem; 

      .gallery-preferences & {
          left: auto;
      }

      i {
          position: absolute;
          top: 10px;
          left: 15px;
          font-size: 36px;
      }
      
      @include media-breakpoint-only(md) {
          padding: 10px 15px 10px 55px;
          
          i {
              top: 6px;
              font-size: 30px;
          }

          .gallery-holder & {
              padding: 15px 20px 15px 62px;

              i {
                  position: absolute;
                  top: 10px;
                  left: 15px;
                  font-size: 36px;
              }
          }
      }
  }
}

.booking-step {
  text-decoration: none;
}

.booking-section {
  opacity: 0;
  background: $booking-section-bg;

  &.active {
      opacity: 1;
      transition: all .3s ease-in-out;
  }

  > div {
      @include media-breakpoint-down(sm) {
          padding: 1.25rem !important;
      }
  }
}


#booking-app {
    font-size: 1rem !important;
    line-height: 1.4 !important;
    font-weight: 400;
    letter-spacing: 0;
    background-color: $booking-bg;

    .btn-main {
      background-color: $booking-btn;
      border: $booking-btn-border;
      border-radius: $booking-btn-border-radius !important;
    }

    .pricetag {
      background-color: $price-tag-background;
    }

    .pr-sm-15 {
      @media (min-width: 768px) {
        padding-right: 15px !important;
      }
    }
  
    .modal-cs {
      h3, h5 {
        font-size: 1.25rem;
      }
    }

    h1 {
      .small {
        font-size: 1.25rem;
      }
    }

    .h1, h1 {
      font-size: 2rem;
      line-height: 1.1;
    }

    .h3 {
      font-size: 1.5rem;
      line-height: 1.1;

      @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
      }
    }

    .h4, h4 {
      font-size: 1.25rem;
    }

    .h5, h5 {
      font-size: 1.0875rem;
    }

    h1, h2, h3, h4, h5 {
      margin-bottom: .5rem;
      font-weight: 700;
      line-height: 1.1;
    }

    hr {
      border-width: 2px;
      border-color: $booking-hr;
    }

    // button,
    // input {
    //   font-size: 100%;
    // }

    .section-heading {
        &.section-header {
            margin-bottom: 0;
        }
    }

    .text-info {
      color: #000!important;
      font-size: 1.25rem;
      font-weight: 700;
    }

    nav {
        .container {
            @media (max-width: 991px) {
                width: 720px;
            }

            @media (max-width: 768px) {
                max-width: 100%;
            }

            @media (max-width: 576px) {
                width: 540px
            }
        }

        &.navbar-toggleable-sm {
            justify-content: center;
            .navbar-collapse {
                text-decoration: underline;
                justify-content: center;
                @media (max-width: 991px) {
                    margin-top: 0;
                }
            }
        }

        @include media-breakpoint-down(md) {
          position: relative !important;
        }
    }

    .navbar {
      padding: 0;
      z-index: 10;
  
      .navbar-collapse {
          width: auto;
      }
  
      @include media-breakpoint-only(md) { font-size: 14px }
      background: $white;
    }
  
    .navbar-nav {
        @extend .mt-sm-0;
  
        .material-icons {
            margin-left: 0;
        }
    }
  
    .nav-link {
        color: $black;
  
        &:hover {
            text-decoration: underline;
        }
    }
  
    .navbar-text {
        color: $black;
    }
  
    .navbar-toggler {
        right: 0;
        padding: .5rem 0;
    }
  
    @include media-breakpoint-down(sm) {
        .navbar {
            .container {
                width: 100%;
                padding: 0.5rem 1rem !important;
            }
  
            .navbar-toggler {
              right: 1rem;
              top: 11px;
            }
        }
    }

    .nav-pills .nav-link {
      font-size: 1.25rem;
    }

    .alert-danger {
      ul {
        list-style: none;
        padding-left: 0;
      }
    }

    .alert-warning {
      background-color: #fff;
      border-color: rgba(251,186,0,.7);
      color: #000;
      border: 2px solid rgba(251,186,0,.7);
    }

    .align-center-label {
        padding-top: calc(.5rem - 1px * 2);
    }

    .bg-receipt {
      background-color: #fff;
      border-left: 1px solid $beige-light;

      .list-included li:after {
        content: "Inclusief";
      }
      .locale-en .list-included li:after {
        content: "Included";
      }
      .locale-de .list-included li:after {
        content: "Inbegriffen";
      }
      .locale-fr .list-included li:after {
        content: "Inclus";
      }
    }

    .bg-faded-light, .paw-radio-group .custom-control-indicator {
      background: $faded-light;
    }

    .bg-orange-light {
      background-color: $orange-light !important;
    }

    .bg-red-light {
      background: $red-light !important;
    }

    .font-weight-bold {
      font-weight: 700;
    }

    .booking-section-padding {
        padding: 2.5rem;
    }

    .booking-step {
        h2 {
            font-size: 2rem;
        }
    }

    .carousel-item {
      width: 100%;
      a {
        width: inherit;
      }
      &.active {
        display: block !important;
      }
    }

    .card-lg h5 {
      font-size: 1.5rem;
    }

    .card-footer {
      background: $booking-card-footer;
    }

    .card-link {
      cursor: pointer;
    }

    .form-control {
        border-color: rgba(0, 0, 0, .15);
    }

    div {
        &.has-danger {
            &:after{
                content: none!important;
            }
        }
    }

    // h1, h2, h3, h4, h5, h6, .h1 {
    //     margin-bottom: 0;
    //     letter-spacing: 0;
    // }

    .list-included {
      padding: 0;
      list-style: none;
      
      li {
          position: relative;
          padding-right: 60px;

          &:after {
              font-size: 1rem;
              color: $modal-travel-company-button-color;
              -webkit-font-smoothing: auto;
              position: absolute;
              top: 0;
              right: 0;
          }
      }
    }

    .margin-bottom-1 {
      margin-bottom: 1rem;
    }

    .p-1 {
      padding: 1rem!important;
    }

    .p-2 {
      padding: 1.35rem!important;
    }

    .pl-1, .px-1, .social {
      padding-left: 1rem!important;
    }

    .pr-1 {
      padding-right: 1rem!important;
    }

    .pr-3 {
      padding-right: 2.5rem!important;
    }

    .pt-1 {
      padding-top: 1rem!important;
    }

    .pb-0 {
      padding-bottom: 0!important;
    }

    .pb-1 {
      padding-bottom: 1rem!important;
    }

    .section-lg.m-0.pb-5 {
      padding-bottom: 5rem !important;
    }

    .ps-xs-0 {
      @include media-breakpoint-up(xs) {
        padding-right: 0!important;
        padding-left: 0!important;
      }
    }

    .px-xs-2 {
      @include media-breakpoint-up(xs) {
        padding-right: 1.35rem!important;
        padding-left: 1.35rem!important;
      }
    }

    .py-2 {
      padding-top: 1.35rem!important;
      padding-bottom: 1.35rem!important;
    }
 
    .py-3 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }

    .mt-1 {
      margin-top: 1rem !important;
    }

    .mt-3 {
      margin-top: 2.5rem!important;
    }
 
    .mb-1 {
      margin-bottom: 1rem !important;
    }

    .mb-2 {
        // margin-bottom: 2rem !important;
      margin-bottom: 1.35rem!important;
    }

    .mb-3 {
      margin-bottom: 2.5rem!important;
    }

    .text-heading-font {
      font-family: $headings-font-family;
      font-size: 1.5rem;
      font-weight: 700 !important;
  
      @include media-breakpoint-down(md) {
        font-size: $h5-font-size !important;
      }
  
      @include media-breakpoint-down(sm) {
        font-size: $h6-font-size !important;
      }
    }

    .col-form-label {
      @extend .pb-0;
      @extend .d-block;
      text-align: right;
  
      @include media-breakpoint-down(md) {
        text-align: left;
        margin-bottom: 1rem;
      }
    }

    .form-check-label {
      text-align: left;
    }

    .nav-pills {
      border-bottom: 2px solid $nav-pills-border-color;
  
      .nav-item {
          margin-bottom: -2px;
      }
  
      .nav-link {
          border: 2px solid transparent;
          font-weight: bold;
          font-size: $h4-font-size;
          background-color: transparent;
          color: black;
  
          @include media-breakpoint-down(md) {
            font-size: $h6-font-size;
          }
  
          &.active {
              border: 2px solid $nav-pills-border-color;
              border-bottom-color: $white;
              color: $black;
          }
      }
    }
    
    .rw-btn {
      background-color: $modal-travel-company-button!important;
    }

    .nav-tabs {
        border-bottom: none;
    }

    .object-select {
        display: inline-block;
        max-width: 100%;
        height: calc(2.25rem + 2px);
        padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        line-height: 1.25;
        color: #464a4c;
        vertical-align: middle;
        background: #fff no-repeat right .75rem center url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
        background-size: 8px 10px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        font-size: 1rem;
    }

    p {
        letter-spacing: 0;
        word-spacing: 0;
    }

    .padding-1 {
        padding: 1rem;
    }

    .padding-right-0 {
        padding-right: 0;
    }

    .section-content {
        h4 {
            font-size: 1.25rem;
            font-weight: 700;
            &.text-info {
                color: black!important;
            }
        }
    }

    // Dirty hack to avoid the "default" section-lg class added to sections outside the booking street
    section:first-of-type {
      padding-top: 2.5rem!important;
      padding-bottom: 2.5rem!important;
    }
    section:nth-of-type(2) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .btn {
      text-transform: none !important;
      font-weight: 400;
      font-size: 1rem;
    }

    .btn-lg {
      padding: 0.75rem 1.5rem;
      font-size: 1.375rem;
      border-radius: $booking-btn-border-radius;
      background-color: $modal-button;
      border-color: $modal-button;
      &:hover {
        background-color: $modal-button-hover;
        border-color: $modal-button;
      }
    }

    .btn-outline {
      background: transparent;
      border: 2px solid rgba(255, 255, 255, 0.5) !important;
      color: #fff;
    }

    .btn-reset-lh {
      padding-top: 7px;
    }

    .btn-primary {
      color: #fff;
      background-color: $modal-button;
      border: 1px solid $modal-button !important;

      &:hover {
        background-color: $modal-button-hover !important;
        border: 1px solid $modal-button-hover !important;
      }
    }

    .modal-cs {
      max-width: 650px !important;
    }

    .social-share {
      margin: 1rem;
      padding: 1rem 1rem 0 1rem;
      border: 2px solid $booking-hr;
      cursor: pointer;
    }

    .popover-inner {
      .popover-body {
        background-color: #f9f9f9;
      }
    }

    .stay-info-receipt {
        h5 {
            padding: 1rem 1rem 0 1rem;
        }
    
        .receipt-company {
            padding: 1rem 1rem 0 1rem;
        }
    
        ul {
            padding: 1rem;
        }
    }

    ul {
        &.list-checked {
            &.mx-2 {
                &.mt-1 {
                    margin: 1rem 1.35rem 1rem 1.35rem!important;
                }
            }
        }
    }

    h4 {
        &.h5 {
            &.text-lato {
                margin-bottom: 1rem!important;
                font-weight: 700;
                line-height: 1.1;
            }
        }
    }

    footer {
      display: none;
    }

    .receipt-holder {
        margin-right: 0 !important;
        margin-left: 0 !important;
        @media (max-width: 768px) {
            outline: 2px solid #596831;
            background-color: #596831;
            height: 90px;
        }
        &.toggled {
            padding-top: 0;
            z-index: $zindex-sticky;
            height: 100%;
            padding-top: 90px;
            i {
                &#down-arrow {
                    z-index: 1300;
                    top: 50px;
                }
            }
        }

        .stay-info-receipt {
            label {
                margin-top: -1px
            }
        }
    }

    @media (min-width: 768px) {
      .pl-md-3, .px-md-3 {
        padding-left: 2.5rem!important;
      }

      .pl-md-2, .px-md-2 {
        padding-left: 1.35rem!important;
      }
    }

  .booking-form {
    .section-header {
      height: 80px;
  
      h2 {
        width: 100%;
        text-align: center;

        @include media-breakpoint-only(md) {
          font-size: $h3-font-size !important;
        }
      }
  
      span {
        position: absolute;
        @include size($section-header-span-width, $section-header-span-height);
        text-align: center;
      }
  
      @include media-breakpoint-down(sm) {
        height: 70px;

        h2 {
          font-size: 1.35rem !important;
        }

        span { 
          height: 70px;
          font-size: 1.5rem
        }
      }
  
      @media (max-width: 320px) {
        h2 {
          padding: 1.5rem 1rem 1.25rem 1rem !important;
          font-size: 1.25rem;
        }
      }
    }
  }
}

.modal.fade.show {
  display: block !important;

  h5 {
      font-weight: 700;
  }

  .addition-select {
      height: calc(2.25rem + 2px);
      display: inline-block;
      max-width: 100%;
      width: auto;
      height: calc(2.25rem + 2px);
      padding: .375rem 1.75rem .375rem .75rem;
      line-height: 1.25;
      color: #464a4c;
      vertical-align: middle;
      background: #fff no-repeat right .75rem center url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
      background-size: 8px 10px;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 0;
      -moz-appearance: none;
      -webkit-appearance: none;
      font-size: 1rem;
  }

  .btn-addition {
      cursor: pointer;
      background-image: none;
      color: white;
      background-color: #596831;
      border: 2px solid #596831;

      &:hover {
          background-color: #3b4521;
      }
  }

  .btn-booking {
      cursor: pointer;
      background-image: none;
      color: white;
      background-color: #596831;
      border: 2px solid #596831;
      display: inline-block;
      font-weight: 400;
      line-height: 1.25;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      border: 1px solid transparent;
      padding: .5rem 1rem;
      font-size: 1rem;
      transition: all .2s ease-in-out;

      &:hover {
          background-color: #3b4521;
      }
  }
}

a {
  &.btn-booking {
    &.disabled {
      background-color: $btn-booking;
      pointer-events: none;
    }
  }
}

label {
  &.form-check-label {
    #email_allowed {
      float: left;
      display: flex;
    }
    
    span {
      p {
        text-align: left;
      }
    }
  }
}

.paymentflow {
  background-color: #fff;
}
