.btn-submit-amount {
    display: block;
    margin-top: -5px;
    -webkit-font-smoothing: antialiased;
}

.btn-floating-action {
    @include size($spacer * 4, $spacer * 4);
    @include pos-b-l(0, 50%);
    display: block;
    background: $btn-floating-bg;
    z-index: $zindex-floating-btn;
    border-radius: 50%;
    transform: translate(-50%, 40%);

    .ic {
        background-color: $btn-floating-icon !important;
    }

    &:focus,
    &:hover {
        background: $btn-floating-hover-bg;
        .ic {
            background-color: $btn-floating-hover-icon !important;
        }
    }
}

.btn-outline {
    border: 1px solid transparent;
}

.btn-link {
    padding: 0;
    position: relative;
    padding-right: $panel-body-padding;
    font-family: $font-family-sans-serif;

    .gallery-body &     {   @extend .text-left;   }
    .section-action &   {   @extend .pr-0;       }

    &.btn-block {
        position: relative;
        padding-left: 40px;
    }

    &:hover, &:focus {
        text-decoration: none !important;
    }
}

.btn-arrow {
    &:before {
        @include mi("\E315"); //.mi("keyboard_arrow_right");
        width: 15px;
        transform: translate(-8px, 7px);
    }

    &:hover {
        color: $brand-primary;
    }
}

.btn-more {
    display: block;
    position: relative;

    .description & {
        margin-left: -20px;
        margin-right: -20px;
    }

    .material-icons {
        @extend .md-18;
        transform: translate(0, -1px);
    }

    &:after {
        @include size(100%, 100px);
        @include pos-t(-100px, 0, 0);
        @include gradient-y(rgba($white, 0%), rgba($white, 100%), 0%, 80%);
        @include transition(.15s ease-in-out);
        content: "";
        display: block;
    }

    span {
        text-decoration: none;
        color: $green;
    }

    .result & {
        @extend .pl-0;
        text-align: left;
        text-transform: none !important;
        font-size: 18px;

        span {
            color: $black;
            text-decoration: underline;
        }
    }
}

.btn-result {
    @extend .font-weight-bold;
    font-size: $font-size-small;
}

.btn-info {
    &:before {
        @include mi("\E000"); //.mi("error");
        transform: translate(-5px, 5px);
    }
}

.btn-compare {
    @extend .hidden-xs-down;
}

.btn-close {
    @include size(37px, 37px);
    position: absolute;
    top: -18px;
    right: -18px;
    z-index: 100;
    opacity: 1;
    border-radius: 50%;
    border: 2px solid rgba($brand-primary, .75);
    background: rgba(#000, .75);

    &:hover {
        background: #000;
    }

    span {
        @include pos-t-l(50%, 50%);
        transform: translate(-50%, -50%);
        color: #fff !important;
    }

    // &anchor added below
    span {
        &.material-icons {
            position: absolute !important;
            top: 50% !important;
        }
    }
}

.btn-map {
    span {
        @extend .hidden-sm-down;
        font-size: $font-size-base;
    }
}

.btn-to-map {
    position: relative;
    display: inline-block;
    padding-left: 28px !important;

    &:after {
        @include mi("\E55B");
        @include pos-t-l;
        color: #ffa900 !important;
    }

    .label {
        position: relative;
        display: inline-block;
        background: #ffa900;
        font-size: 11px;
        margin-left: 3px;
        padding: 0 3px;
        top: -1px;
    }
}

.btn-collapse-filter {
    @include size(22px, 22px);
    @include pos-t-l(5px, 0);
    padding: 0;
    border-radius: 3px;
    border: 0;
    background: $filter-btn-bg;
    color: #fff !important;

    &:before {
        @include mi("\E15B");
        position: relative;
        top: 2px;
        font-size: 18px;
    }

    &.collapsed {
        &:before {
            @include mi("\E145");
            position: relative;
            top: 2px;
            font-size: 18px;
        }
    }
}