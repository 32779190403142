.gallery-holder {
    &:not(.affix) {
        margin-left: $grid-gutter-width-base * 0.5;
        @include media-breakpoint-down(md) {
            margin-left: 0;
        }
    }
}

.gallery-preferences {
    [class*='label-'] {
        @include pos-t-r;

        @include media-breakpoint-down(lg) {
            width: 100%;
            position: relative;
            z-index: 50;
            margin: 0;
            padding: 0 0 15px;
            text-align: center;

            i {
                display: inline-block;
                position: relative;
                top: 9px;
                left: 0;
                margin-right: 5px;
            }
        }
    }

    .label-vpbb {
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: 100%;
            right: auto;
            width: 188px;
        }
    }

    .label-srbb {
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: 100%;
            right: auto;
            width: 195px;
        }
    }
}

.accommodation-label-restyled {
  background-image: url('https://d33b12c77av9bg.cloudfront.net/img/label_restyled.png');
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 999;
  background-size: cover;
  height: 60px;
  width: 60px;

  @include media-breakpoint-up(lg) {
    right: -100%;
  }
}

.accommodation-label-new {
  background-image: url('https://d33b12c77av9bg.cloudfront.net/img/label_new.png');
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 999;
  background-size: cover;
  height: 60px;
  width: 60px;

  @include media-breakpoint-up(lg) {
    right: -100%;
  }
}

.gallery-left {
    @include media-breakpoint-up(md) {
        @include make-col(6.5);
    }
}

.gallery-right {
    @include media-breakpoint-up(md) {
        @include make-col(5.5);
    }
}

.gallery-header {
    @extend .p-x-md;
    @extend .text-center;
    background: $accommodation-info-header;
    color: $accommodation-info-header-color;
    padding-top: 32px;
    padding-bottom: 32px;

    h1 {
        @extend .mt-0;
        text-transform: none;
        font-size: 2.15rem;

        @include media-breakpoint-down(sm) {
            font-size: $font-size-h2;
        }
    }
}

.gallery-body {
    min-height: 264px;
    padding: 0 15px 0 15px;
    background: $accommodation-info-background;

    .preference-selector {
        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    .filters .form-dropdown .dropdown-menu {
        left: 0 !important;
    }

    @include media-breakpoint-only(lg) {
        min-height: 308px;

        .btn-booking {
            display: flex;
            justify-content: space-between;
            i {
                display: none;
            }
        }

        .details-included {
            font-size: 14px !important;
            margin-left: 10px;
        }
    }

    .preference-open {
        padding-bottom: 15px;
    }

    .loading {
        color: $gallery-loading-color !important;

        &:before {
            color: $brand-primary !important;
        }
    }

    .form-dropdown {
        @extend .px-0;

        &:hover {
            background: transparent;
        }
    }

    .holder {
        @extend .mb-0;

        li:last-child {
            .form-dropdown {
                border: 0;
            }
        }
    }

    .btn-link {
        color: $brand-primary;
    }
}

.gallery-item {
    @include make-col-ready;
    @include make-col(4);
    @extend .px-0;

    cursor: pointer;

    .inner {
        height: 100%;
        display: block;
    }

    .count {
        @include size(100%, 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        display: block;
        background: rgba($black, .25);
    }

    .count-text {
        @include size(100%, auto);
        @include pos-t-l(50%, 50%);
        transform: translate(-50%, -50%);
        text-align: center;
        color: $white;
        font-size: 24px;
        font-weight: 700;
    }
}

.gallery-footer {
    .btn {
        text-decoration: none !important;
        text-transform: none !important;
        font-size: 1rem;
        font-weight: 700;

        &:before {
            top: 10px !important;
        }
    }

    .inner {
        margin: $grid-gutter-width-base * 0.5;
    }

    .btn-link {
        color: $black !important;
        text-align: left !important;

        &:before {
            top: 9px !important;
            color: $gallery-icon-color !important;
        }

        .fa, .material-icons {
            @include size(20px, 20px);
            @extend .mr-2;
            transform: translate(9px, 5px) !important;
            color: $gallery-footer-icon-color !important;
            font-size: $font-size-h3;
        }
    }
}

.gallery-body {
    color: $gallery-body-color;
}

.gallery-image {
    position: relative;
    cursor: pointer;

    a {
        cursor: pointer;
    }
}

.gallery-thumbs {
    @extend .mx-0;
    background: transparent;
    flex-direction: row;
    display: flex;

    .inner {
        position: relative;
    }

    picture {
        @include pos-reset;
        display: block;
        overflow: hidden;
        height: auto;
    }

    a {
        cursor: pointer;
    }
}

.gallery-nav {
    display: none;
    height: 62px;
    line-height: 62px;

    li {
        a {
            padding: 0 15px;
            font-size: 24px;
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            text-transform: capitalize;
            text-decoration: none;
        }
    }
}

@include media-breakpoint-only(md) {
    .gallery {
        @include make-row;
    }

    .gallery-image {
        @include make-col(9);
    }

    .gallery-thumbs {
        @include make-col(4);
        display: flex;
        flex-wrap: nowrap;
        margin-top: 0 !important;
    }

    .gallery-item {
        @include make-col(12);
        margin-bottom: $grid-gutter-width-base;
        padding: 0;

        @include media-breakpoint-down(md) {
            margin-bottom: 0 !important;
        }
    }
}

.preference-selector {
    @include media-breakpoint-up(md) {
        @include make-col-ready;
        @include make-col(6);
    }

    // .form-dropdown {
    //     margin-bottom: 0 !important;
    // }
}

.modal.centeredModal.fade.show {
    display: flex !important;
}

.preference-data {
    @include media-breakpoint-up(md) {
        @include make-col-ready;
        @include make-col(4.75);
        @include make-col-offset(1);
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &.disabled {
        .price-tag {
            font-size: 20px;
            font-weight: bold;
            background: $beige-light;

            @include media-breakpoint-down(lg) {
                font-size: 16px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        @include make-col(12);
        @include make-col-ready;
        padding-bottom: 20px;
    }

    .price-tag {
        .price {
            font-size: 28px;

            @include media-breakpoint-only(lg) {
                font-size: 24px;
            }
        }

        s {
            font-size: 16px;
        }

        .text-discount {
            font-size: 14px;

            @include media-breakpoint-only(lg) {
                font-size: 12px !important;
            }
        }
    }

    .alert {
        margin: $grid-gutter-width-base * 0.5;
    }

    .details-included {
        display: block;
        font-size: 16px;
    }

    .btn-booking-wrapper {
      position: absolute;
      left: 0;
      bottom: 15px;
    }

    .btn-booking {
      margin-left: 0 !important;
    }

    .btn-link {
        @extend .pt-0;
        margin-top: $grid-gutter-width-base * 0.5;
        color: $brand-primary;
    }

    @include media-breakpoint-down(sm) {
      .btn-booking,
      .btn-booking-wrapper {
        width: 100%;
        position: relative;
        right: auto;
        bottom: auto;
      }
  }
}

.gallery-footer {
    .btn {
        position: relative;
        padding-left: $panel-body-padding * 2 !important;
        white-space: normal;

        &:before {
            @include pos-t-l;
        }
    }

    .btn-compare {
        &:before {
            @include mi('compare');
        }
    }

    // Deprecated
    .btn-share {
        &:before {
            @include mi('share');
        }
    }

    .btn-eurosign {
      &:before {
          @include mi('euro_symbol');
      }
    }

    .btn-book-map {
        &:before {
            @include mi('\E55E');
        }
    }

    .btn-map {
        &:before {
            @include mi('pin_drop');
        }
    }
}

// #fotos {
//     flex-direction: column;
// }

.slider-decorator-0 {
    display: none;
}

.slider-decorator-1 {
    @extend .slick-next;
    @extend .slick-arrow;
    @extend .carousel-control-next;

    button {
        visibility: hidden;
        opacity: 0;
    }
}

.slider-decorator-2 {
    @extend .slick-prev;
    @extend .slick-arrow;
    @extend .carousel-control-prev;

    left: 0 !important;
    transform: translate(0, -50%) !important;

    button {
        visibility: hidden;
        opacity: 0;
    }
}
