.list-features {
    @extend .list-unstyled;
    @extend .list-inline;
    margin-bottom: $grid-gutter-width;
    padding: ($padding-large-vertical * 0.5) 0 $padding-large-vertical;
    border-top: 2px solid $list-features-border-color;
    border-bottom: 2px solid $list-features-border-color;

    @include media-breakpoint-down(sm) {
        padding: 10px 0 15px;
    }

    li {
        @extend .list-inline-item;
        position: relative;
        padding-left: 32px;

        &:before {
            position: absolute;
            top: 3px;
            left: 0;
            color: $swp !important;
        }

        &:not(:last-of-type) {
            margin-right: ceil(($grid-gutter-width * 0.666));
            font-style: oblique;

            @include media-breakpoint-down(sm) {
                margin: 5px;
            }
        }
    }

    .material-icons {
        @include pos-t-l(3px, 0);
    }

    .icon-rolstoel:before { @include mi("\E914"); } //.mi("accessible") }
    .icon-persons:before { @include mi("\E63D"); } //.mi("wc") }
    .icon-room:before { @include mi("\E53A"); } //.mi("hotel");.mi("hotel") }
    .icon-animal:before { @include mi("\E91D"); } //.mi("pets") }
    .icon-wifi:before { @include mi("\E63E"); } //.mi("wifi") }
    .icon-tv:before { @include mi("\E333"); } //.mi("tv") }
    .icon-electra:before { @include mi("\E63C"); } //.mi("power") }
    .icon-restaurant:before { @include mi("\E561"); } //.mi("restaurant_menu") }
    .icon-nearby-centre:before { @include mi("\E536"); } //.mi("directions_walk") }
    .icon-parking:before { @include mi("\E54F"); } //.mi("local_parking") }
    .icon-sanitair:before { @include newyse($newyse-icon-sanitair); font-size: 24px; }
    .icon-watertap:before { @include newyse($newyse-icon-watertappunt); font-size: 24px; }
    .icon-green-hedges:before { @include newyse($newyse-icon-groenhagen); font-size: 24px; }
    .icon-surface:before { @include newyse($newyse-icon-oppervlakte); font-size: 24px; }
    .icon-detached:before { @include newyse($newyse-icon-vrijstaand); font-size: 24px; }
    .icon-sauna:before { @extend .libema-icons; content: '\e912'; font-size: 24px; }
}
