.btn-outline {
    background: transparent;
    border: 2px solid rgba($white, .5);
    color: $white;

    &:hover, &:focus {
        background: $white;
        color: $black;
    }

    &.btn-info {
        &:hover, &:focus {
            color: $brand-info;
        }
    }
}

.btn-outline-black {
    background: transparent;
    border-color: $black;
    border-width: 2px;
    color: $black;

    &:hover {
        background-color: $black !important;
        border-color: $black !important;
        color: $white !important;
    }
}

.btn-border {
    border-width: 2px;
    background-color: transparent;

    &.btn-default {
        border-color: $gray-lighter;
    }
}

.btn-taupe {
  background: $taupe;
}

.btn-link {
    border: 0;
}

.btn-primary-color {
  background: $btn-primary-color;
  color: $white;
  text-decoration: none;
  font-weight: 600;
}

.btn-reset-lh {
  padding-top: 7px;
}
