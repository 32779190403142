// Margin and Padding

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x: map-get($lengths, x);
    $length-y: map-get($lengths, y);

    .#{$abbrev}-#{$size} { #{$prop}: $length-y $length-x !important; } // A
    .#{$abbrev}t-#{$size} { #{$prop}-top: $length-y !important; }
    .#{$abbrev}r-#{$size} { #{$prop}-right: $length-x !important; }
    .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y !important; }
    .#{$abbrev}l-#{$size} { #{$prop}-left: $length-x !important; }

    // Axes
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length-x !important;
      #{$prop}-left: $length-x !important;
    }
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length-y !important;
      #{$prop}-bottom: $length-y !important;
    }

    // X axes
    .#{$abbrev}x-xs-#{$size} {
      @include media-breakpoint-only(xs) {
        #{$prop}-right: $length-x !important;
        #{$prop}-left: $length-x !important;
      }
    }
    .#{$abbrev}x-sm-#{$size} {
      @include media-breakpoint-only(sm) {
        #{$prop}-right: $length-x !important;
        #{$prop}-left: $length-x !important;
      }
    }
    .#{$abbrev}x-md-#{$size} {
      @include media-breakpoint-only(md) {
        #{$prop}-right: $length-x !important;
        #{$prop}-left: $length-x !important;
      }
    }
    .#{$abbrev}x-lg-#{$size} {
      @include media-breakpoint-only(lg) {
        #{$prop}-right: $length-x !important;
        #{$prop}-left: $length-x !important;
      }
    }
    .#{$abbrev}x-xl-#{$size} {
      @include media-breakpoint-only(xl) {
        #{$prop}-right: $length-x !important;
        #{$prop}-left: $length-x !important;
      }
    }

    // Y axes
    .#{$abbrev}y-xs-#{$size} {
      @include media-breakpoint-only(xs) {
        #{$prop}-top: $length-y !important;
        #{$prop}-bottom: $length-y !important;
      }
    }
    .#{$abbrev}y-sm-#{$size} {
      @include media-breakpoint-only(sm) {
        #{$prop}-top: $length-y !important;
        #{$prop}-bottom: $length-y !important;
      }
    }
    .#{$abbrev}y-md-#{$size} {
      @include media-breakpoint-only(md) {
        #{$prop}-top: $length-y !important;
        #{$prop}-bottom: $length-y !important;
      }
    }
    .#{$abbrev}y-lg-#{$size} {
      @include media-breakpoint-only(lg) {
        #{$prop}-top: $length-y !important;
        #{$prop}-bottom: $length-y !important;
      }
    }
    .#{$abbrev}y-xl-#{$size} {
      @include media-breakpoint-only(xl) {
        #{$prop}-top: $length-y !important;
        #{$prop}-bottom: $length-y !important;
      }
    }

    // Top
    .#{$abbrev}t-xs-#{$size} {
      @include media-breakpoint-only(xs) {
        #{$prop}-top: $length-y !important;
      }
    }
    .#{$abbrev}t-sm-#{$size} {
      @include media-breakpoint-only(sm) {
        #{$prop}-top: $length-y !important;
      }
    }
    .#{$abbrev}t-md-#{$size} {
      @include media-breakpoint-only(md) {
        #{$prop}-top: $length-y !important;
      }
    }
    .#{$abbrev}t-lg-#{$size} {
      @include media-breakpoint-only(lg) {
        #{$prop}-top: $length-y !important;
      }
    }
    .#{$abbrev}t-xl-#{$size} {
      @include media-breakpoint-only(xl) {
        #{$prop}-top: $length-y !important;
      }
    }

    // Bottom
    .#{$abbrev}b-xs-#{$size} {
      @include media-breakpoint-only(xs) {
        #{$prop}-bottom: $length-y !important;
      }
    }
    .#{$abbrev}b-sm-#{$size} {
      @include media-breakpoint-only(sm) {
        #{$prop}-bottom: $length-y !important;
      }
    }
    .#{$abbrev}b-md-#{$size} {
      @include media-breakpoint-only(md) {
        #{$prop}-bottom: $length-y !important;
      }
    }
    .#{$abbrev}b-lg-#{$size} {
      @include media-breakpoint-only(lg) {
        #{$prop}-bottom: $length-y !important;
      }
    }
    .#{$abbrev}b-xl-#{$size} {
      @include media-breakpoint-only(xl) {
        #{$prop}-bottom: $length-y !important;
      }
    }
  }
}
