//== Fonts
@import "mixins/fonts";
@import "mixins/arrows";
@import "mixins/background";
@import "mixins/retina";

@import "utilities/position";
@import "utilities/spacing";

@mixin placeholder($val) {
    &::-webkit-input-placeholder {
        color: $val;
    }

    &::-moz-placeholder {
        color: $val;
    }

    &:-moz-placeholder {
        color: $val;
    }

    &:-ms-input-placeholder {
        color: $val;
    }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}

@mixin animate($args) {
    -webkit-animation: $args;
    -moz-animation: $args;
    animation: $args;
}