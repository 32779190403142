// Core variables and mixins
@import "~font-awesome/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap-slider/src/sass/bootstrap-slider";

@import "./web/variables";
@import "./web/custom";
@import "./web/migrate-variables";
@import "./web/migrate-files";
@import "./web/fonts";

// Booking CSS
// @import "./booking/fonts";
@import "./booking/custom";
@import "./booking/utilities/background";
@import "./booking/utilities/text";
@import "./booking/alert";
@import "./booking/booking-bar";
@import "./booking/booking-map";
@import "./booking/button";
@import "./booking/card";
@import "./booking/chatBlock";
@import "./booking/form";
@import "./booking/modal";
@import "./booking/popover";
@import "./booking/progress";
@import "./booking/receipt";
@import "./booking/travelCompany";
@import "./booking/travel_company";
@import "./booking/type";

// Core CSS
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/responsive-embed";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/utilities";

@import "./web/type";
@import "./web/tables";
@import "./web/forms";
@import "./web/button";

@import "./web/dropdown";
@import "./web/footer";
@import "./web/input-group";
@import "./web/nav";
@import "./web/navbar";
@import "./web/card";
@import "./web/badge";
@import "./web/legacy/modules/jumbotron";
// @import "./web/legacy/bootstrap"; // <----
@import "./web/legacy/fonts";
@import "./web/legacy/mixins/card";
@import "./web/legacy/mixins/range";
@import "./web/legacy/utilities/alert";
@import "./web/legacy/utilities/background";
@import "./web/legacy/utilities/badge";
@import "./web/legacy/utilities/buttons";
@import "./web/legacy/utilities/form";
@import "./web/legacy/utilities/grid";
@import "./web/legacy/utilities/image";
@import "./web/legacy/utilities/range";
@import "./web/legacy/utilities/icon";
@import "./web/legacy/utilities/spacing";
@import "./web/legacy/modules/body";
@import "./web/legacy/modules/card";
@import "./web/legacy/modules/carousel";
@import "./web/legacy/modules/collection";
@import "./web/legacy/modules/jumbotron";
@import "./web/legacy/modules/list";
@import "./web/legacy/modules/modal";
@import "./web/legacy/modules/nav";
@import "./web/legacy/modules/tabs";

@import "./web/jumbotron";
@import "./web/alert";
@import "./web/close";

// Components w/ JavaScript

@import "./web/modal";
@import "./web/slick";
@import "./web/carousel";

// Utility classes

@import "./web/affix";
@import "./web/alertBlock";
@import "./web/beeksebergen_booking";
@import "./web/booking_bar";
@import "./web/collection";
@import "./web/compare";
@import "./web/cookiewall";
@import "./web/countdown";
@import "./web/datepicker";
@import "./web/fallback";
@import "./web/feature";
@import "./web/filter";
@import "./web/gallery";
@import "./web/label";
@import "./web/lang";
@import "./web/loaders";
@import "./web/mailplus";
@import "./web/map";
@import "./web/markers";
@import "./web/media";
@import "./web/paragraph";
@import "./web/panel";
@import "./web/pattern";
@import "./web/positioned_image";
@import "./web/popover";
@import "./web/popup";
@import "./web/price";
@import "./web/price_table";
@import "./web/property";
@import "./web/news";
@import "./web/result";
@import "./web/search";
@import "./web/secret-deal";
@import "./web/section";
@import "./web/sharer";
@import "./web/social";
@import "./web/toasters";
@import "./web/toggle";
@import "./web/tooltip";
@import "./web/well";
@import "./web/utilities/background";
@import "./web/utilities/cke";
@import "./web/utilities/text";
@import "./web/yourzine";

body {
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  line-height: $line-height-sm !important;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 300;
  @include media-breakpoint-down(md) {
    padding-top: 56px;
    font-size: 16px;
  }

  // Dirty hack, because of weird navigation implementation
  .main-navigation.affix-top:before {
    content: "";
    display: block;
    width: 100%;
    height: 52px;
    position: absolute;
    background: $navigation-primary-background;
    top: 0;
  }

  h4,
  h5 {
    font-weight: 400;
    word-spacing: 0;
  }
}

body.cke_editable {
  &:before {
    display: none !important;
  }
}

*:focus {
  outline: 0 !important;
  box-shadow: none;
}

* {
  &:focus {
    outline: 0;
  }
}

// This class is only for Android and iOS devices
// Cursor pointer is specified for closing popopers on mobile
.on-device {
  cursor: pointer;
}

/* Specified for tablet Landscape */
/* Do not remove */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  body,
  .lead {
    font-size: 1rem;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.popover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.list-border-top {
  border-top: 1px solid $beige;
}

@include media-breakpoint-only(sm) {
  .px-sm-0 {
    padding: 0 !important;
  }
}

@include media-breakpoint-only(md) {
  .px-md-0 {
    padding: 0 !important;
  }
}

.all-in {
  border-bottom: 2px solid $beige;
}
