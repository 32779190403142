.travel-company {
    .dropdown-title {
      font-weight: 600;
      text-transform: uppercase!important;
      font-size: 16px;
    }
  
    .dropdown-subtitle {
      font-size: 16px;
    }
  
    &:hover {
      background: $beige-light;
    }
  
    .dropdown-subject {
      padding: 30px 25px!important;
    }
  
    .dropdown-label {
      margin-bottom: 20px;
    }
  
    .dropdown-travel-company {
      .dropdown-label {
        margin-bottom: 20px;
      }
  
      .dropdown-input {
        margin-bottom: 7px;
  
        .input-group {
          justify-content: flex-end;
        }
  
        button {
          width: 40px;
          height: 40px;
          line-height: 10px;
          font-size: 1.25rem;
          background: $main;
          border: 0;
          color: $subject-picker-color;
          &:hover {
            background: $btn-booking-hover;
          }
        }
  
        input {
          max-width: 40px;
          border: none;
          text-align: center;
        }
      }
    }
  }