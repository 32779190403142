.filters {
    @include media-breakpoint-only(xl) {
        margin-top: -76px;
        background-color: $beige-light;

        .holder-sb & {
            margin-top: -84px;
        }
    }

    @include media-breakpoint-only(lg) {
        margin-top: -76px;
        background-color: $beige-light;

        .holder-sb & {
            margin-top: -83px;
        }
    }

    @include media-breakpoint-between(sm, lg) {
        margin-top: -75px;
    }

    // Tab styling
    .tab-content {
        @extend .p-0;
        border: 0;

        @include media-breakpoint-only(md) {
            height: 100%;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 $spacer;
        }

        > .active {
            display: flex;
        }

        .tab-pane {
            @include media-breakpoint-up(md) {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .refine-holder {
        width: 1110px;
        margin-left: -832px !important;
        position: static !important;
        box-shadow: none;
        background-color: #f6f1e6;
        cursor: auto;

        .f-l-0 {
            display:flex;
            left:0;
        }

        @include media-breakpoint-only(xl) {
            padding: $spacer * 3 !important
        }

        @include media-breakpoint-only(lg) {
            width: 880px;
            margin-left: -674px !important;
            padding: $spacer * 1.5 !important
        }

        @include media-breakpoint-only(md) {
            width: 720px;
            margin-left: -540px !important;
        }

        @include media-breakpoint-down(md) {
            padding: $spacer !important
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-left: 0 !important;
        }

        .row-stay {
            @extend .mb-4;

            @include media-breakpoint-only(md) {
                .form-check {
                    @include make-col(4);
                }
            }

            @include media-breakpoint-down(sm) {
                h3 {
                    margin-bottom: 5px !important;
                }

                .form-check {
                    @include make-col(12);
                }

                .form-check:last-child {
                    margin-bottom: 30px !important;
                }
            }
        }

        .row-properties {
            @extend .mt-4;

            @include media-breakpoint-down(sm) {
                h3 {
                    margin-bottom: 5px !important;
                }
            }
        }

        hr {
            border-top: 2px solid $beige-dark;
        }

        .col-wrap {
            @include media-breakpoint-up(lg) {
                height: 120px;

                .col-md-4 {
                    flex: none;
                    width: 33.33333%;
                }
            }

            @include media-breakpoint-only(md) {
                flex-flow: row wrap
            }

            @include media-breakpoint-down(sm) {
                max-height: none;
                flex-flow: row wrap;

                .form-check:last-child {
                    margin-bottom: 30px !important;
                }
            }
        }
    }

    // Dropdown styling
    .dropdown {
        cursor: pointer;

        .dropdown-toggle {
            background-color: $filter-unfocused;
            padding: 1rem;
            &:before, &:after {
                display: block;
            }

            &:hover {
                background-color: $filter-focused;

                .dropdown-value .truncate {
                    background-color: $filter-focused !important;
                }
            }

            @include media-breakpoint-only(md) {
                &:before {
                    display: none;
                }

                &:after {
                    @include make-col(1);
                    right: 5px;
                }

                .dropdown-filter {
                    @include make-col(11);
                }
            }
        }

        @include media-breakpoint-only(md) {
            .gallery-holder & {
                .dropdown-toggle {
                    &:before, &:after {
                        display: block;
                    }
                }

                .dropdown-filter {
                    @include make-col(8.3);
                    // display: inline-block;
                }
            }
        }

        &.show {
            .dropdown-toggle, .dropdown-menu, .dropdown-value .truncate {
                background-color: $filter-focused;
            }
        }

        .dropdown-heading {
            font-size: 15px;

            @include media-breakpoint-between(md, lg) {
                font-size: 14px;
            }
        }

        .dropdown-value {
            width: 200px; // Set for default subjects
            max-height: 27px;
            overflow: hidden;
            white-space: nowrap;
            font-size: 15px;

            @include media-breakpoint-only(md) {
                width: 100%;
            }
        }

        &.travel-company {
            .dropdown-toggle {
                .dropdown-value {
                    position: relative;

                    .truncate {
                        width: 40px;
                        position: absolute;
                        right: 0;
                        padding-left: 5px;
                        background-color: $beige-dark;
                    }
                }
            }
        }

        &.date-picker {
            .dropdown-toggle {
                .dropdown-value {
                    position: relative;

                    .truncate {
                        width: 40px;
                        position: absolute;
                        right: 0;
                        padding-left: 5px;
                        background-color: $beige-dark;
                    }
                }
            }
        }
    }

    .dropdown-menu {
        padding: 30px 35px !important;

        @include media-breakpoint-down(sm) {
            width: 100% !important;
            position: static;
            margin-bottom: 0 !important;
            padding: 0 15px 30px !important;
        }
    }

    .travel-company {
        .dropdown-menu {
            width: 350px;
        }
    }

    .date-picker {
        .dropdown-menu {
            width: 350px;
        }
    }


    .arrival-date,
    .departure-date {
        .dropdown-menu {
            width: 420px;
            left: -2px;

            @include media-breakpoint-only(md) {
                width: 350px;
            }
        }

        .DayPicker {
            .CalendarMonth_caption {
                padding: 0px 0 60px;
            }

            .DayPicker_weekHeader ul li {
                padding: 0;
            }
        } 
    }

    .dropdown-input {
        @extend .mb-2;
    }

    .column-company,
    .column-arrival,
    .column-departure {
        .dropdown {
            border-right: 2px solid $filter-focused;

            .filters-accommodation & {
                border-right: 0;
                border-bottom: 2px solid $filter-focused;
            }

            @include media-breakpoint-down(sm) {
                border-right: 0;
                border-bottom: 2px solid $filter-focused;
            }
        }
    }

    // Input group styling
    .input-group-btn {
        .btn {
            @include size(40px, 40px);
            @extend .m-0;
            @extend .p-0;
            line-height: 40px;
            font-size: 1.25rem;
            background: $beige-dark;
            border: 0;

            &:hover {
                background: darken($beige-dark, 10%);
            }

            span {
                position: relative;
                top: -1px;
            }
        }
    }

    .input-group {
        .form-control {
            @include size(40px, 40px);
            @extend .p-0;
            line-height: 40px;
            border: 0;
        }
    }

    .custom-control {
        @extend .mr-0;
        @extend .mb-0;
        @extend .pl-0;
        font-size: 1rem !important;
    }

    .information-indicator {
        &:after {
            position: relative;
            top: 1px;
            color: $orange-light;
            margin-bottom: 5px;
            cursor: pointer;
        }
    }

    .no-property-holder {
        .custom-control-input {
            height: 20px;
            width: 20px;
            margin: 0;
        }

        .custom-control-description {
            margin-left: 0.3rem;
            margin-top: -1px;
        }

        &.camping {
            margin-left: 1.5rem;
            margin-top: 1px;
        }
    }

    .custom-control-input {
        @extend .ml-0;
        position: static;
        margin-right: 0.7rem;
        margin-left: -0.9rem !important;
        z-index: 5;

        &.m-l-0 {
            margin-left: 0 !important;
        }

        cursor: pointer;
        .custom-control-indicator {
            background-color: #fff;
            margin-top: 3px;
        }

        &:focus, &:checked {
            ~ .custom-control-indicator {
                background: #fff;
                box-shadow: none;
            }
        }

        &:disabled, .disabled & {
            ~ .custom-control-description {
                color: #888;
            }
        }

        &:checked {
            ~ .custom-control-indicator {
                &:before {
                    @include mi('\E5CA');
                    @extend .md-18;
                    transform: scale(0.8);
                    top: -4px;
                    left: -4px;
                    position: absolute;
                    color: $green !important;
                    -webkit-font-smoothing: auto;
                }
            }
        }
    }

    #accordion-refine {
        .custom-control-input {
            margin-left: 0rem !important;
        }
    }

    .custom-control-indicator {
        background: #fff;

        .disabled & {
            background: rgba(#fff, .5);
            cursor: not-allowed;

            &:before {
                color: rgba($green, .5);
            }
        }
    }

    .custom-control-description {
        display: flex !important;
        font-size: 1rem;
        .material-icons {
            color: $orange-light;
        }
    }


    // Preferences bar
    .show-view, .amount {
        font-size: 15px;
    }

    .amount {
        font-weight: normal;
        text-transform: none;

        span {
            html[lang="de"] & {
                display: none;
            }
        }
    }

    .column-button {
        background-color: $filter-unfocused;
    }
}

.filters-accommodation {
    @include make-col-ready;
    @include make-col(6.25);
    margin-top: $grid-gutter-width-base * 0.5 !important;
    background-color: transparent;

    @include media-breakpoint-down(sm) {
        @include make-col(12);
    }

    .column-company,
    .column-arrival,
    .column-departure {
        flex: 0 0 100%;
        max-width: 100%;
        .dropdown {
            border-right: 0;
            border-bottom: $picker-border-bottom;
        }

        @include media-breakpoint-down(sm) {
            padding-right: $grid-gutter-width-base * 0.5 !important;
        }
    }

    .column-departure {
        .dropdown {
            border-bottom: 0;
        }
    }
}


// Preferences bar

.row-pb {
    .filters {
        [class*="column-"] {
            @include make-col(3.25);

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }

        .column-button {
            @include make-col(2.25);
            height: 76px;

            @include media-breakpoint-between(md, lg) {
                height: 75px;
            }

            @include media-breakpoint-between(xs, sm) {
                height: auto;
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }
}


// Sticky action bar mobile

.refine-selection {
    .refine-dropdown-toggle {
        display: flex;
        background-color: $filter-unfocused;
        padding: 1rem;

        &:before {
            @include mi('\E152');
            flex: 0 0 15.41667%;
            max-width: 15.41667%;
            position: relative;
            top: 10px;
            font-size: 1.5rem;
            color: $filter-before-color;
        }

        .dropdown-filter {
            flex: 0 0 69.16667%;
            max-width: 69.16667%;
            .dropdown-heading {
                font-weight: 600;
                text-transform: $picker-text-transform !important;
                font-size: 15px;
                white-space: nowrap;
                color: $picker-text-color !important;
            }

            .dropdown-value {
                width: 200px;
                max-height: 27px;
                overflow: hidden;
                white-space: nowrap;
                font-size: 15px;
            }
        }

        &:after {
            @include make-col(1.85);
            @include mi('\E313');
            @extend .m-0;
            @extend .text-right;
            padding-left: 10px;
            position: relative;
            top: 12px;
            border: 0 !important;
            color: $filter-after-color;

            @include media-breakpoint-only(md) {
                display: none;
            }

            @media (max-width: 475px) {
                padding-left: 28px;
            }
        }

        &.after-override {
            &:after {
                @include mi('\E5CD');
            }
        }
    }

    .show {
      .refine-dropdown-toggle {
          background-color: $filter-focused;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
      }
    }

    @include media-breakpoint-down(sm) {
        &.show {
            .row-buttons {
                width: 100%;
                position: fixed;
                bottom: 0;
                left: 0;
                z-index: 100;
                margin: 0;
                padding: $grid-gutter-width-base * 0.5;
                background: $beige-light;
                box-shadow: 0 -2px 3px rgba(0, 0, 0, .15);

                .col {
                    padding: 0;
                }
            }

            .row-properties {
                margin-top: $grid-gutter-width-base !important;

                [class*="col-"] {
                    margin-bottom: 2px !important;

                    h3 {
                        margin-bottom: 0 !important;
                    }
                }

                a {
                    position: relative;
                    display: block;
                    padding: $grid-gutter-width-base * 0.5;
                    background: $beige-light;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    &:after {
                        @include pos-t-r(50%, 20px);
                        transform: translateY(-50%);
                        font-family: 'Material Icons';
                        color: $orange;
                        content: "\E15B";
                    }

                    &.collapsed {
                        background: $beige-dark;

                        &:after {
                            content: "\E145";
                        }
                    }
                }

                .inner {
                    margin: 0 $grid-gutter-width-base * 0.5 $grid-gutter-width-base * 0.5;
                }
            }

            .row-stay {
                margin: 0 !important;
            }
        }
    }

    @include media-breakpoint-up(md) {
        h3 a {
            &:hover,
            &:focus {
                text-decoration: none;
                cursor: text;
            }
        }
    }
}

.datepicker-dropdown {
    background-color: $beige-light !important;
    @extend .dropdown;
}

[class*='.datepicker-orient'] {
    &:before {
        display: none;
        content: '';
    }
}

@media (max-width: 768px) {
    .hide-extra-button {
        display: none;
    }
}
