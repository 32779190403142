.alert {
    padding: $padding-large-vertical $panel-body-padding;
    border: 1px solid transparent;
    border-radius: $alert-border-radius;

    h4 {
        margin-top: 0;
        color: inherit;
    }

    p {
        color: #fff;
    }

    // Provide class for links that match alerts
    .alert-link {
        font-weight: $alert-link-font-weight;
    }

    // Improve alignment and spacing of inner content
    p,
    ul {
        margin-bottom: 0 !important;
    }

    > p + p {
        margin-top: 5px;
    }
}

.alert-warning {
    .results & {
        font-style: italic;
        color: $body-color !important;
    }
}

.alert-cookie {
    z-index: 9999;
    background:rgba(0, 0, 0, 0.7);
    color: #fff;
    @extend .mb-0;

    a {
        color: $brand-secondary;
        //opacity: .5;

        &:hover {
            color: darken($brand-secondary, 10%);
        }
    }

    .close {
        opacity: .5;
        color: #fff;
        text-shadow: none;
        padding-right: 0;
        text-decoration: none;

        span {
            transform: translate(0, 0);
            font-size: 18px;
            height: auto;
            line-height: inherit;
        }

        &:hover {
            color: #fff;
            opacity: 1;

            span {
                transform: translate(0, 0);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: $panel-body-padding 0;

        p {
            width: 95%;
            text-align: center;
        }
    }
}