nav {
  &.collapse {
    z-index: 1011;
  }

  .navbar-brand {
    .logo-desktop {
      display: block;
    }

    .logo-mobile {
      display: none;
    }
  }

  .navbar-toggler {
    right: 1rem;
    padding: 0;
    font-size: 0.75rem;
    text-transform: uppercase;

    .icon-bar {
      @include size(24px, 2px);
      margin: 4px 0;
      display: block;
      background: rgba(255, 255, 255, 1) !important;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:first-child {
        transform: translateX(0) translateY(7px) rotate(45deg);
      }

      &:last-child {
        transform: translateX(0) translateY(-5px) rotate(-45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }
    }

    &.collapsed {
      .icon-bar {
        -moz-transition: 0.35s;
        -webkit-transition: 0.35s;
        transition: 0.35s;

        &:first-child {
          transform: none;
        }

        &:last-child {
          transform: none;
        }

        &:nth-child(2) {
          opacity: 1;
        }
      }

      > span {
        opacity: 0;
        -moz-transition: 0.35s;
        -webkit-transition: 0.35s;
        transition: 0.35s;
      }
    }
  }

  .dropdown-menu {
    box-shadow: none;
    .container {
      .dropdown-item {
        text-decoration: none;
        color: white;
      }
    }
  }

  @include media-breakpoint-down(md) {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    background: $navigation-secondary-background;
    color: $navigation-secondary-text;
    padding: 0 0 !important;
    z-index: 1049;

    &.collapse {
      bottom: 0;
    }

    .container {
      width: 100%;
      margin: 0;
      padding-top: 0;
    }

    .navbar-header {
      position: fixed;
      width: 100%;
      padding: 1rem 1rem 0.5rem;
      -webkit-box-shadow: 0px 3px 13px -1px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 3px 13px -1px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 3px 13px -1px rgba(0, 0, 0, 0.25);
      background: $green;
      z-index: 1049;
    }

    .navbar-brand {
      width: 150px;
      height: 27px;
      background: url("../../assets/img/logos/bb-logo-mobile.svg");
      background-size: cover;

      img {
        display: none;
      }
    }

    .navbar-brand {
      .logo-desktop {
        display: none;
      }

      .logo-mobile {
        display: block;
      }
    }

    .navbar-collapse {
      margin-top: 57px;
    }

    .navbar-container {
      display: flex !important;
      flex-direction: column;

      .navbar-top {
        display: flex;
        flex-direction: column;
        padding: 0.25rem 0;
        order: 1;
        background: $green;

        .container {
          .navbar-nav {
            .nav-item.show {
              background: none !important;
              a {
                color: $navbar-secondary-text;
                text-decoration: underline;
              }
            }
          }
        }

        .nav-link {
          padding-left: 0;
        }

        .navbar-right {
          margin-top: $spacer;

          @include media-breakpoint-down(md) {
            margin-bottom: $spacer;
          }

          .language-dropdown {
            order: 1;

            @include media-breakpoint-down(md) {
              order: 4;
              position: absolute;
            }

            .btn-lang {
              height: 38px;
              padding: 0;
              background: none;
              border: 0px;
              width: 56px;
            }

            .dropdown-menu {
              bottom: 100%;
              top: initial;
            }
          }
        }
      }

      .navbar-main {
        display: flex;
        flex-direction: column;
        padding: 1rem 0 0.25rem;
        background: $green;

        order: 0;

        .navbar-nav {
          order: 1;
        }

        .navbar-right {
          padding: 0 1rem;
          order: 0 !important;

          .btn {
            width: 100%;
            display: block;
          }
        }

        .nav-link {
          font-size: 1.25rem;
          font-weight: 600;
        }
      }

      .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .navbar-search {
        margin-top: 0.7rem;
        margin-bottom: 1rem;
      }

      .navbar-nav {
        &.navbar-cta {
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          padding-bottom: 1rem;
        }

        li {
          a {
            color: #fff;
            text-transform: none !important;
            font-size: 1rem;
            text-decoration: none;
          }

          &.dropdown {
            .navbar-toggler {
              @include size(45px, 45px);
              position: absolute;
              top: 0;
              right: 0.5rem;
              &:before {
                text-align: right;
                @include mi("\E313");
                color: rgba(255, 255, 255, 0.4);
                font-size: 2.5rem;
              }
            }

            &:hover {
              transition: none;
            }
          }

          &.show,
          &.activated {
            background: #fff;

            a {
              color: $gray-dark !important;
              font-weight: 600;
            }

            .navbar-toggler {
              &:before {
                @include mi("\E316");
                color: $gray;
              }
            }

            .dropdown-menu {
              display: block !important;
            }
          }

          .dropdown-menu {
            background: #fff;
            border: none;
            padding: 0rem 2rem 1rem !important;

            .container {
              padding: 0;
            }

            .dropdown-item {
              padding: 3px 0rem;
              font-weight: normal;
              &.activated {
                background: none;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-only(lg) {
    .dropdown-menu {
      .dropdown-item {
        margin: 0 0.5rem !important;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    padding: 0 !important;
    z-index: 1049;

    .navbar-brand {
      position: relative;
      top: 4.75rem;
      padding: 0;
      margin-right: 2rem;

      img {
        height: auto;
      }
    }

    .navbar-collapse {
      display: block !important;
      flex-direction: column;
    }

    .navbar-top,
    .navbar-main {
      display: flex !important;
      width: 100%;

      .container {
        display: flex !important;
        width: 100%;
      }
    }

    .navbar-form {
      .form-control {
        background-color: $navbar-search-background;
        color: $navbar-search-text-color;
      }

      .form-control::-webkit-input-placeholder {
        color: $navbar-search-text-placeholder-color;
      }
      .form-control:-moz-placeholder {
        color: $navbar-search-text-placeholder-color;
      }
      .form-control::-moz-placeholder {
        color: $navbar-search-text-placeholder-color;
      }
      .form-control:-ms-input-placeholder {
        color: $navbar-search-text-placeholder-color;
      }

      .material-icons {
        color: $white;
      }
    }

    .language-dropdown {
      .dropdown-menu {
        margin-top: 2px !important;
        background: $navigation-primary-background;
      }

      .material-icons {
        color: $white;
      }
    }

    .navbar-top {
      .container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        padding-right: 0;
      }
      .nav-link {
        color: $navigation-secondary-text;
        padding: 0.7rem 1rem;
        font-size: 1rem;
        text-decoration: none;
      }

      .nav {
        order: 1;
      }

      .language-dropdown {
        display: flex;
        order: 3;

        button {
          padding: 0px 0.5rem 0;
          background: none;
          border: none;
          cursor: pointer;
        }
      }

      .form-inline {
        order: 4;
        button {
          padding: 0.25rem 0.5rem 0;
          background: none;
          border: none;
          cursor: pointer;
        }
      }
    }

    .navbar-main {
      position: relative;
      top: 1.5rem;
      height: 64px;
      > .navbar-cta {
        li {
          a {
            font-size: 1rem !important;
          }
        }
      }

      > .navbar-nav {
        li {
          a {
            text-transform: none !important;
          }
        }
        .nav-item {
          @include media-breakpoint-up(xl) {
            font-size: 1.75rem;
          }

          font-family: $font-family-main-nav;
          font-size: 1.35rem;
          text-transform: uppercase;
          letter-spacing: 2px;
          border-bottom: 2px solid rgba(255, 255, 255, 0.4);

          &.activated {
            position: relative;
            border: 2px solid rgba(255, 255, 255, 0.4);
            border-bottom: 0;

            .dropdown-menu {
              display: block;
              width: 100%;
              padding: 0 !important;
              background: none;
              border: none;

              .container {
                padding: 0 !important;
                padding-left: 0.5rem !important;
              }

              .dropdown-item {
                width: auto !important;
                color: #fff;
                font-family: $headings-font-family;
                font-weight: 600;
                text-transform: none;
                letter-spacing: 0px;
                border-bottom: none;
                padding: 1rem 0 0 !important;
                margin: 0 1rem;

                &.activated {
                  border-bottom: 2px solid #fff;
                  background: none !important;
                }

                &:focus {
                  background: none !important;
                }

                &:hover {
                  background: none;
                }

                .nav-link {
                  padding: 0rem !important;
                }
              }
            }
          }

          &.dropdown {
            position: static;
            .activated {
              .dropdown-menu {
                display: block !important;
              }
            }
          }
        }

        .nav-link {
          color: $navigation-secondary-text;
          padding: 0.5rem 1.5rem !important;
          text-transform: uppercase !important;
          text-decoration: none;
        }
      }

      .navbar-right {
        text-align: right;
        flex: 1;
        border-bottom: 2px solid rgba(255, 255, 255, 0.4);

        li {
          width: 100%;
          padding-top: 0.85rem;
        }
      }
    }

    .toggle-sub {
      width: 45px;
      height: 45px;
      position: absolute;
      top: 0;
      right: 0;
      border-left: 1px solid #b74040;
      color: #fff;
      padding: 5px 0;
      display: none;
    }

    .navbar-sub {
      position: absolute;
      top: auto;
      left: 0;
      width: 100%;
      min-height: 0;
      display: none;
    }

    .collapse.in {
      display: block;
    }
  }
}

ul {
  &.nav {
    .nav-item {
      a {
        color: $navigation-primary-text;
      }
    }
  }
}

.language-dropdown {
  width: 64px;
  padding: 0.15rem 0.5rem 0.1rem 0.25rem;
  .dropdown-menu {
    width: 50px !important;
    min-width: auto;
    padding: 5px !important;
    background: #fff;
  }

  @include media-breakpoint-up(lg) {
    height: 45px;
    .dropdown-menu {
      left: 5px;
    }
  }

  @include media-breakpoint-down(md) {
    position: absolute;
    right: 12px;
    padding: 0;

    &.show {
      .btn-lang {
        border-top: none !important;
        background: rgba(0, 0, 0, 0.3) !important;
        z-index: 1;
      }

      .dropdown-menu {
        background: rgba(0, 0, 0, 0.3) !important;
      }
    }

    .btn-lang {
      transition: none;
    }

    .dropdown-menu {
      background: none;
      border: 2px solid #fff;
      border-bottom: 0px;
      padding: 1px;
      bottom: 99% !important;
      width: 56px !important;
      z-index: 998;

      .lang {
        width: 42px;
        height: 35px;
        z-index: 999;
      }
    }

    i {
      color: #67793a;
    }
  }
}

// Navigation affix
@include media-breakpoint-up(lg) {
  .navbar {
    transition: all 0.3s ease-in-out;

    &.affix {
      position: fixed;
      flex-direction: column;
      background: $navigation-secondary-background;
      color: $navigation-secondary-text;

      .navbar-top {
        display: none !important;
      }

      .navbar-main {
        top: 0px;
        height: 50px;

        > .navbar-nav {
          .nav-item {
            font-size: 1.35rem;
            .nav-link {
              padding: 1rem 1.5rem;
              text-decoration: none;
            }
          }
        }
      }

      .navbar-right {
        li {
          padding-top: 0;
        }
      }

      .navbar-brand {
        top: 1.55rem;
        width: 140px;

        img {
          width: 85%;
          cursor: pointer;
        }
      }

      &.affix--hidden {
        top: -130px;
        transition: all 0.5s ease-in-out;
        box-shadow: none;
      }

      &.no-sub {
        @include media-breakpoint-up(lg) {
          height: 86px;

          .navbar-brand {
            width: 150px;
            height: 27px;
            background: url("../../assets/img/logos/bb-logo-mobile.svg");
            background-size: cover;
          }

          .navbar-main {
            height: 50px;
            top: 17px;
          }

          .nav-item {
            border-bottom: none;
          }

          .nav-link {
            padding: 0.5rem 1.5rem !important;
          }

          .navbar-right {
            border: none;

            li {
              padding-top: 0.4rem;
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        height: 130px;
      }
    }
  }
}

.navbar {
  &.affix {
    .dropdown-menu {
      display: none !important;
    }
  }
}

.navbar-brand {
  img {
    height: auto;
  }
}

.legacy .main-navigation + * {
  margin-top: 0;
  padding-top: 0;

  @include media-breakpoint-up(lg) {
    margin-top: -65px;
    padding-top: 100px;
  }
}

nav {
  &.affix-top {
    .navbar-main {
      > {
        .navbar-nav {
          .nav-link {
            color: $navigation-affix-top-text;
          }
        }
      }
    }
  }
}
