.properties {
    @include make-row;
    font-size: 1rem;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    @include media-breakpoint-only(xl) {
        li {
            display: inline-block;
            float: none;
        }
    }

    @include media-breakpoint-down(lg) {
        margin: 10px 0 5px !important;
        padding: ($padding-large-vertical * 0.5) 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 8px 0;
    }

    .material-icons {
        @extend .m-r-sm;
        transform: translate(-2px, 6px);
    }
}

.property {
    @include make-col(6);
    position: relative;
    padding-left: 32px;
    min-height: 35px;
    line-height: 35px;

    .card & {
        @include make-col(12);
        font-size: 1rem;
    }

    .thumbnails-info & {
        @include media-breakpoint-only(xl) {
            min-height: 35px !important;
        }
    }

    &:before {
        @extend .m-r-sm;
        @include pos-t-l(6px, 0);
        font-size: 24px;
        color: $card-property-color;
    }

    .material-icons, .fa {
        @include pos-t-l(8px, 0);
        color: $card-property-color;
        font-size: 20px;

        .result & {
            top: 2px;
            left: 5px;
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 0 0 0 $panel-body-padding * 2 !important;

        .material-icons, &:before {
            @include pos-t-l;
        }
    }

    @media (max-width: 374px) {
        @include make-col(12);
    }


    @include media-breakpoint-down(md) {
        line-height: 24px;

        &:before {
            top: 2px;
        }

        .material-icons {
            top: -5px;
        }

        .material-icons, &:before {
            font-size: 24px !important;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }
}

.property-64607,
.property-64608,
.property-64609 {
    &:before {
        @include newyse($newyse-icon-sanitair);
    }
}

.property-1823 {
    &:before {
        @include newyse($newyse-icon-watertappunt);
    }
}

.property-136001 {
    &:before {
        @include newyse($newyse-icon-groenhagen);
    }
}

.property-1664, .property-120002 {
  .material-icons {
    @extend .hidden-xs-up;
  }
  &:before {
      @include newyse($newyse-icon-oppervlakte);
      left: -2px;
      margin-right: 4px !important; // Because of bigger font size, a decrease in margin
      font-size: 28px;
  }
}

.property-1798 {
    &:before {
        @include newyse($newyse-icon-vrijstaand);
    }
}

.thumbnails-info {
        @include media-breakpoint-only(md) {
            flex-flow: row wrap;
            flex-direction: row !important;
        }
    .card {
        @include media-breakpoint-down(md) {
            @include make-col(4);
        }
        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .card-block {
            max-height: none;
            padding-bottom: 6rem;

            .row {
                .date {
                    font-size: 1rem;
                }
            }
        }

        .property {
        @include media-breakpoint-only(md) {
            flex: 0 0 100%;
            max-width: 100%;
        }
            flex: 0 0 50%;
            max-width: 50%;
            line-height: 20px;

            i {
                font-size: 0.95rem;

            }
        }
    }
}
