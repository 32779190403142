// Accommodation page (in: slider)
.dropdown-row {
    @extend .mb-3;

    .dropdown {
        @extend .bg-beige-light;
        @extend .p-3;
    }
}

.dropdown-toggle {
    @include make-row;
    @extend .m-0;
    @extend .p-0;

    .dropdown-filter {
        @include make-col(8.3);
        // display: inline-block;
    }

    .dropdown-heading {
        font-weight: 600;
        text-transform: $picker-text-transform;
        color: $picker-text-color;
        font-size: 1rem;
        white-space: nowrap;
    }

    &:before {
        @include make-col(1.85);
        position: relative;
        top: 10px;
        font-size: $font-size-h4;
        color: $filter-before-color;

        @include media-breakpoint-only(md) {
            display: none;  
        }
    }

    &:after {
        @include make-col(1.85);
        @include mi('\E313');
        @extend .m-0;
        @extend .text-right;
        position: relative;
        top: 12px;
        border: 0 !important;
        color: $filter-after-color;

        @include media-breakpoint-only(md) {
            display: none;
        }
    }
}

// Verblijfsduur

.travel-duration {
    .drowdown-menu {
        width: 290px !important;
        padding-left: 15px !important;
    }
}


// Icons
.arrival-date {
    @extend .mt-0;

    .dropdown-toggle {
        padding: 1rem  !important;
        &:before {
            @include mi('\E8DF');
        }
    }
}

.departure-date {
    @extend .mt-0;

    .dropdown-toggle {
        padding: 1rem  !important;
        &:before {
            @include mi('\E8DF');
        }
    }
}

.resorts {
    @extend .mt-0;

    .dropdown-toggle {
        &:before {
            @include mi('\E53A');
        }
    }
}

.travel-duration {
    .dropdown-toggle {
        &:before {
            @include mi('\E549');
        }
    }
}

.travel-company {
    cursor: pointer;
    .dropdown-toggle {
        padding: 1rem !important;
        &:before {
            @include mi('\E63D');
        }
    }
}

.date-picker {
    @extend .mt-0;

    .dropdown-toggle {
        padding: 1rem !important;
        &:before {
            @include mi('\E8DF');
        }
    }
}

.dropdown-menu {
    min-width: 65px;
    border: 0;
    box-shadow: 2px 2px 0 rgba($brown-light, .5);
    @extend .m-0;
    @extend .p-3;

    @include media-breakpoint-down(sm) {
        width: 100%;
        box-shadow: none;
    }

    .dropdown-label {
        font-size: 1rem;
    }

    .dropdown-caption {
        p {
            @extend .mt-4;
            @extend .mb-0;
            color: $red-light;
            font-size: 1rem;
        }
    }

    .divider {
        margin-bottom: 0.75rem;
    }
    
    .column-stay &, .form-stay & {
        height: auto;
        max-height: 250px;
        overflow-y: scroll;
        padding: 0 !important;

        .dropdown-padded {
            padding: 10px 20px;
        }

        .bordered {
            border-top: 1px solid $beige-lighter;
        }

        .btn {
            font-size: $font-size-base;
            padding: $padding-large-vertical 0;
        }
    }

    .form-stay & {
        padding: .625rem 0;
    }

    &.dropdown-menu {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    > li > a {
        display: block;
        padding: 0;

        &:hover {
            text-decoration: none;
            opacity: 0.8;
        }
    }

    .form-dropdown & {
        left: 0;
    }
}

.dropdown-input {
    display: flex;
    margin-bottom: 1px;

    .dropdown-input-label {
        display: flex;
        width: 55%;
        font-size: 1rem;
    }

    .input-group {
        display: flex;
        width: 45%;
    }
}


.dropdown-subject {
    position: absolute;
    top: 100%;
    z-index: 970;
    color: #292b2c;
    text-align: left;
    padding: 30px 35px!important;
    background-color: $filter-focused;
    box-shadow: 2px 2px 0 rgba(164,138,119,.5);
    width: 420px;

    left: 0;
    list-style: none;
}