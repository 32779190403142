.loading-block {
    &.transparent {
        position: relative;

        &.loading-active {
            .result, 
            .thumbnail, 
            .form-dropdown, 
            .column-button, 
            .column-button 
            .slider-content,
            .alert {
                opacity: .5;
            }

            .sebm-google-map-container {
                @include transition(.3s ease-in-out);
                opacity: .75;
            }

            .form-dropdown.open {
                opacity: 1 !important;
            }

            .results &, .thumbnails-compare &, .affix-form &, .slider & {
                &:after {
                    @include mi("\E5D5"); //.mi("refresh");
                    @include animate(spin 1.5s infinite linear);
                    @include pos-t-l(($grid-gutter-width * 2), 50%);
                    z-index: 200;
                    font-size: $font-size-h1 !important;
                    color: $brand-primary;
                }
            }

            .thumbnails-compare &, .slider-prices & {
                &:after {
                    top: 120px !important;
                    left: 50%;
                    margin-left: -28px;
                    z-index: -1;
                }
            }

            .map-wrapper & {
                &:after {
                    top: 50%;
                    left: 50%;
                    margin-top: -28px;
                    margin-left: -28px;
                }
            }

            .affix-form & {
                &:after {
                    top: -16px !important;
                    z-index: 999;
                    left: 50%;
                    margin-left: -28px;

                    @include media-breakpoint-down(sm) {
                        top: 50% !important;
                        left: 50%;
                        margin-left: -28px;
                        margin-top: -48px;
                    }
                }
            }
        }

        &.loading-acco {
            @include size(100%, 110px);
            @include pos-t-l();
            z-index: 100;
            background: rgba($green, .95);
            cursor: not-allowed;

            .loading {
                @include pos-t-l(50%, 50%);
                @extend .pt-0;
                transform: translateX(-50%);
                margin-top: -14px;
                padding-left: 44px;
                color: $white !important;

                &:before {
                    top: -3px;
                    color: $white;
                }
            }
        }
    }

    &.padded {
        padding: $grid-gutter-width-base;
    }

    .loading-spin {
        position: relative;
        z-index: 200;
        padding-left: 45px;

        &:before {
            @include pos-t-l(-5px, 0);
        }
    }
}

.loading-alert {
    &:after {
        top: 6px !important;
    }
}

.loading-dropdown {
    cursor: not-allowed;

    &:before {
        @include size(100%, 100%);
        @include pos-t-l;
        z-index: 100;
        content: '';
        background: rgba(255, 255, 255, .5);
    }

    &:after {
        @include mi("\E5D5"); //.mi("refresh");
        @include animate(spin 1.5s infinite linear);
        @include pos-t-l(50%, 50%);
        z-index: 110;
        margin-left: -18px;
        margin-top: -18px;
        font-size: $font-size-h1 !important;
        color: $brand-primary;
    }
}

.loading-ts {
    &:after {
        font-size: 6rem;
        margin-top: 50%;
    }
}

.loading-pr {
   &:after {
       margin-top: -40px!important;
       top: -50px !important;
       z-index: 999;

   }
}

.loading {
    &:before {
        font-size: $font-size-h1 !important;
        color: $brand-primary;
    }

    &.loading-spin {
        &:before {
            @include mi("\E5D5"); //.mi("refresh");
            @include animate(spin 1.5s infinite linear);
            font-size: 2.5rem;
        }
    }

    &.loading-opacity {
        &:before {
            @include mi("\E3FA"); //.mi("lens");
            @include animate(fade 1.5s infinite ease-in-out);
        }
    }

    &.loading-expand {
        &:before {
            @include mi("\E40C"); //.mi("panorama_fish_eye");
            @include animate(expand 1.5s infinite ease-in-out);
        }
    }
}

@include keyframes(spin) {
    100% {
        transform: rotate(360deg);
    }

    100% {
        transform: scale(360deg);
    }
}

@include keyframes(fade) {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@include keyframes(expand) {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    75% {
        transform: scale(1.5);
        opacity: 1;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}