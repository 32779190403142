@mixin card-block-inverse {
    .card-block {
        color: #fff;

        p {
            color: rgba(255,255,255,.85);
        }

        a {
            color: #fff;
        }
    }
}