.booking-bar {
    position: absolute !important;
    bottom: 0;
    padding: 0;
    z-index: 999;
    height: auto !important;
    background: $booking-bar-bg;
    color: #fff;

    [class^="col-"] {
        padding: 0;
    }

    .dropdown {
        padding: 1.375rem 0;
        cursor: pointer;

        &:hover {
            transition: all .2s ease-in-out;
            background: rgba(0,0,0,.1);
        }
    }

    .dropdown-toggle {
        width: 100%;
        display: inline-block;
        padding: 6px 30px;
        border-right: 2px solid rgba(255,255,255,.3);

        .dropdown-filter {
            display: inline-block;
        }

        &:before {
            @extend .mr-1;
            display: inline-block;
            font-family: 'Material Icons';
            font-size: $h4-font-size;
            opacity: .3;
        }
    }

    .dropdown-menu {
        min-width: 292.5px;
        margin: 0px;
        border: 0px;
        background: $booking-bar-bg;
        color: #fff;
        
    }

    .arrival-date {
        .dropdown-toggle {
            &:before {
                content: "\E8DF";
            }
        }
    }

    .travel-duration {
        .dropdown-toggle {
            &:before {
                content: "\E549";
            }
        }
    }

    .travel-company {
        .dropdown-toggle {
            &:before {
                content: "\E63D";
            }
        }
    }
}