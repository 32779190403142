.badge {
    @include pos-t-r;
    @extend .float-right;
    border-radius: 0px;
    color: $beige-dark !important;
    padding: 6px 10px 6px;
    background: $brown-light;

    &.badge-none {
        background: $beige-dark;
        color: $brown !important;
    }

    .card-categories & {
        @extend .text-uppercase;
        position: static;
        float: none !important;
        display: inline-block;
        border-radius: 0;
    }
}

.tag-cloud {
    .badge {
        @extend .mt-3;
        @extend .mb-4;
        position: relative;
        float:none !important;
        padding: .65rem .8rem;
        background: $beige-light;
        color: $green !important;
    }
}
