.paragraph {
    border-radius: $paragraph-border-radius;

    hgroup {
        h1, h2 {
            text-transform: none !important;
        }
    }

    .btn-more {
        &:after {
            height: auto;
        }
    }

    &.in {
        height: auto;

        + .btn-more:after {
            opacity: 0;
            height: 0;
        }
    }

    ul {
        @extend .pl-0;

        li {
            &:before {
                position: relative;
                top: 4px;
                left: 0;
                margin-right: 8px;
                display: inline-block;
            }
        }
    }
}

.jumbotron {
    + .section-content {
        &:not(.thumbnails-compare) {
            .paragraph {
                margin-top: -($grid-gutter-width * 0.5);
            }
        }
    }
}

.btn-more {
    .read-less {
        display: inline-block;
    }

    .read-more {
        display: none;
    }

    &.collapsed {
        .read-less {
            display: none;
        }

        .read-more {
            display: inline-block;
        }
    }
}

.paragraph-sm {
    @include transition(.15s ease-in-out);
    height: $paragraph-sm-height;
    overflow: hidden;
}
