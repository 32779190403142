// TODO SET VARS CORRECTLY
.price-hidden {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

#price-table {
  .dropdown-menu {
    padding: 30px 35px!important;
    @media (min-width: 768px) {
      width: 385px;
      background: $filter-focused!important
    }
  }

  .dropdown {
    &.show {
      background-color: $filter-focused;
    }

    &:hover {
      background-color: $filter-focused;
      cursor: pointer;
    }
  }

}

.price-table {
  display: inline-block;
  padding: 20px 0;
  vertical-align: top;
  font-size: 16px;
  .price-grid {
    list-style: none;
    margin: 0;

    // Improve styling for active column.
    .active-column {
      background-color: $price-table-active-column;
    }

    .price-header {
      display: flex;
      background-color: $price-table-header-background;
      border-bottom: $price-table-header-border-bottom;
    }

    .header-cell {
      height: 70px;
      width: 20%;
      max-width: 120px;
      min-width: 120px;
      position: relative;
      padding-top: 10px;
      font-weight: 400;
      text-align: center;
      @include media-breakpoint-down(lg) {
        max-width: 100px;
        min-width: 100px;
      }
    }

    .price-cell {
      height: 65px;
      position: relative;
      border-bottom: $price-table-cell-border-bottom;

      span {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        font-weight: 400;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    .no-price-found {
      background-color: $price-table-no-price-found;
      height: calc(100% - 42px);
      .center-message {
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .price-content {
      display: flex;
    }

    .content-cell {
      max-width: 120px;
      min-width: 120px;
      display: flex;
      justify-content: center;
      @include media-breakpoint-down(lg) {
        max-width: 100px;
        min-width: 100px;
      }

      cursor: pointer;

      span {
        font-weight: 600;
      }

      s {
        font-size: 13px;
        padding-right: 15px;
      }

      &:hover {
        background-color: $price-table-cell-hover-background;
        box-shadow: $price-table-cell-hover-shadow;
      }

      &.active {
        box-shadow: $price-table-cell-active-hover-shadow;
        background-color: $price-table-cell-active;
        color: $price-table-cell-active-color;
      }
    }

    .content-empty-cell {
      @extend .content-cell;
      cursor: default;
      pointer-events: none;
    }

    .price-row {
      @extend .price-cell;
      display: flex;
    }

    .arrow {
      height: 70px;
      position: relative;
      color: $btn-primary-color;
      background-color: $price-table-arrow-background;
      font-size: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;
      width: 100%;

      &:hover {
        color: $price-table-arrow-color;
        background-color: $price-table-arrow-hover-background;
      }
    }

    .left-arrow {
      max-width: 53px;
      @extend .arrow;
      &:hover {
        background-color: $price-table-arrow-hover-background;
      }
    }

    .right-arrow {
      @extend .arrow;
      &:hover {
        background-color: $price-table-arrow-hover-background;
      }
    }
  }

  .price-receipt {
    background-color: $price-table-receipt-background;
    border-left: $price-table-receipt-border;
    border-bottom: $price-table-receipt-border;
    padding: 15px 15px 15px 25px;
    margin-left: -15px;
    font-weight: 400;

    h5 {
      font-weight: 600;
    }

    .date-receipt {
      width: 100px;
      display: inline-block;
    }
    .old-price {
      font-size: 15px;
      text-decoration: line-through;
    }
  }
}
