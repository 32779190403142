.card {
    box-shadow: none !important;

    &.receipt {
        border: 0;

        .card-block {
            @extend .p-0;
            background: $beige;
        }
    }
}

.accordion {
    .card {
        background: #fff;
    }
}

label.card-block {
    max-height: 104px;
    overflow: hidden;
}

.card-mandatory {
    .card-block {
        max-height: 103px;
        overflow: hidden;

        @include media-breakpoint-only(lg) {
            max-height: 120px;
        }
    }
}

.card-footer {
    background: $card-footer-bg;
}

.card-lg {
    max-height: 216px;
    overflow: hidden;

    .card-block {
        padding: ($spacer * 2);
    }

    h5 {
        font-size: $h3-font-size;

        @include media-breakpoint-down(sm) {
            font-size: $h4-font-size;
        }

        @media (max-width: 320px) {
            font-size: $h5-font-size;
        } 
    }

    p {
        font-size: $lg-font-size;
    }

    @include media-breakpoint-down(md) {
        max-height: none;

        .col-6 {
            @include make-col(12);

            &:last-child {
                padding-right: $spacer * 2.5 !important;
                padding-left: $spacer * 2.5 !important;
            }
        }
    }

    .card-details {
      @include media-breakpoint-down(md) {
        min-height: 150px;

        .card-foot {
          .btn {
            margin-right: 3.5rem;
          }
        }
      }
    }

    .card-foot {
      position: absolute;
      bottom: 1.875rem;
      width: 100%;

      span {
        line-height: 2rem;
      }

      .btn {
        margin-right: 2.5rem;
      }
    }
}

.card-row {
    @extend .clearfix;
    margin-right: -$card-spacer-x;
    margin-left: -$card-spacer-x;
    padding: $card-spacer-y $card-spacer-x;
}

@media (max-width: 374px) {
    .card-cs {
        li.row {
            margin-bottom: .6rem;
            [class^="col-"] {
                width: 100%;
                float: none;
                text-align: left !important;
            }
        }
    }
}

// IE 11 Fixes
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card- {
    .card-block {
     max-height: 103px;
    }
  }
}