.popover {
    border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    border-radius: 0;
    position: absolute;
    z-index: 1050;

    .popover {
        min-width: 276px;
    }
    /* Center the popover on mobile devices */ 
    @include media-breakpoint-down(sm) {
      @include size(100%, auto);

      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }

    .popover-content {
        padding: 1.5rem;
        h3 {
            min-width: 190px;
            width: 85%;
            margin-bottom: .75rem;
        }

        .list-checked {
            @extend .mb-0;

            li {
                margin-bottom: 5px;
            }
        }
    }
}

ul {
    &.list-social {
        &.list-inline {
            margin-bottom: 0;
        }
    }
}

.list {
    .popover {
        top: -200px;
    }
}

button.btn-secondary.show {
    color: #fff;
    background-color: black;
}

.gallery-holder, .result {
    .popover {
        width: auto;
        padding: $panel-body-padding $panel-body-padding 10px;

        ul {
            @extend .mb-0;
        }
    }
}

.result {
    .popover {
        padding: $panel-body-padding $panel-body-padding 10px;
    }
}

.thumbnails-md, .thumbnails-lg {
    .popover {
        ul {
            @extend .p-0;
            margin: 10px 0 !important;
        }
    }
}   

//Pop up map
.section-hh {
    .popover {
        display: none;

        h2 {
            color: $white;
        }
    }

    .section-content {        
        @include media-breakpoint-only(sm) {
            top: 50%;
            left: 45%;
            transform: translate(-50%, -50%);
        }

        @include media-breakpoint-down(xs) {
            left:50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .btn-pointer {
        color:#fff !important;
        text-transform: none !important;
        padding: 6px 10px !important;
        background-color: #E15A5D !important;

        &:after{
            border-top: 20px solid #E15A5D !important;
        }
    }

    .popover {
        width: 290px !important;
        padding: 1.5rem;
        background-color: $green;
        border-radius: 0;
        border: none;
        color: #fff;
        text-align: left!important;
        z-index: 1050 !important;

        .popover-content {
            @extend .px-0;
            margin-bottom: 15px;
            background: none !important;
            font-size: 1rem;
        }

        .popover-title {
            @extend .px-0;
            @extend .pt-0;
            @extend .mt-0;
            background: none !important;
            border: none;
            font-size: $font-size-h3;
        }

        .text-center {
            text-align: left !important;
        }

        div > a {
            color:#fff;
            border-color: #fff;
            font-size: 0.75rem;

        }
    }

    @include media-breakpoint-down(sm) {
        padding-top:10px!important
    }
}

.gallery-holder, .result {
    .popover {
        width: auto;
        padding: $panel-body-padding $panel-body-padding 10px;

        ul {
            @extend .mb-0;
        }
    }
}

.result {
    .popover {
        padding: $panel-body-padding $panel-body-padding 10px;
    }
}

.thumbnails-md, .thumbnails-lg {
    .popover {
        ul {
            @extend .p-0;
            margin: 10px 0 !important;
        }
    }
}

.popover-share {
    border: 0;
    border-radius: 0;
    background: $green;

    // This breaks popover colors on news detail pages
    // .popover-inner {
    //     background: none;
    // }

    .popover-body {
      .list-social {
          @extend .mb-0;
      }
  }

    &:before {
        border-top-color: transparent;
    }

    &.popover-top, &.bs-tether-element-attached-bottom, &.popover-content {
        &:before {
            border-top-color: transparent;
        }
        
        &:after {
            border-top-color: $green;
        }
    }
}

.popover-share {
  .popover-inner {
    margin: -2px;

    &:before {
        border-top-color: transparent;
    }

    &:after {
        @include size(0, 0);
        @include pos-b-l(-22px, 50%);
        z-index: 100;
        display: block;
        margin-left: -11px;
        content: "";
        border-width: 11px;
        border-color: transparent;
        border-style: solid;
        border-top-color: $green;
    }

    .popover-body {
      padding: 5px 10px 0 !important;
    }

    svg rect {
        fill: transparent;
    }

    .SocialMediaShareButton {
        @include size(36px, 36px);
        overflow: hidden;
        cursor: pointer;

        a:hover {
          text-decoration: none !important;
        }

        &:hover {
          g:last-child path {
            fill: darken($gray-lighter, 10%) !important;
          }

          a {
            color: darken($gray-lighter, 10%) !important;
          }
        }
    }

    .SocialMediaShareButton--mail {
        a {
            @include size(36px, 36px);
            display: block;
            text-align: center;
            line-height: 36px;
            color: $white;

            i {
                position: relative;
                top: -3px;
            }
        }
    }
  }
}

.popover-inner {
    @extend .p-0;
    border: 0;
    border-radius: 0;
    z-index: 1050;

    .popover-body {
        @extend .p-4;
    }

    .popover-close {
        @include size(42px, 42px);
        @include pos-t-r(0, 0);
        @extend .text-center;
        background: $green;
        cursor: pointer;
        display: flex;

        i {
            position: relative;
            margin: auto;
            color: #fff;
        }
    }
    
    &:before, &:after {
        display: none;
    }
}

// Accommodation details popover
.preference-data {
    .details-included-mobile {
        @include media-breakpoint-up(md) {
        display: none !important;
        }
        @include media-breakpoint-down(md) {
        display: block !important;
        }
    }
}
.preference-data {
    .details-included-desktop {
        @include media-breakpoint-down(md) {
        display: none !important;
        }
        @include media-breakpoint-up(lg) {
        display: block !important;
        }
    }
}
