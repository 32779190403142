
@use "sass:math";
.modal-fullscreen {
    background: transparent;

    .modal-content {
        background: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .modal-dialog {
        width: 100%;
        margin: 0 auto;
    }
}
.modal {
    &.show {
      // display: block;
      display: flex !important;
      justify-content: center;
      align-items: center;
      .modal-lg {
        top: auto;
        transform: none !important;
      }
    }
}
.modal {
    &.in {
        opacity: 1;
    }
}
.modal-gallery {
    display:block;
    width: 100%;
    height: 100%;
    margin: 0;

    .modal-header {
        position: absolute;
        right: 0;
        border: 0;
        z-index: 900;

        .close {
            font-weight: 100;
            color: #FFF;
            text-shadow: none;
            opacity: 1;
        }
    }

    .modal-content{
        height: 100%;
        background: #000;
        border: 0;
        border-radius: 0;
    }

    .modal-body {
        padding: 0;

        .video-container {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translate(0, 60%);
        }

        .embed-responsive {
            position: absolute;
            top: -50%;
            transform: translate(0, 50%);
        }
    }
}

.modal-backdrop {
    &.modal-backdrop-fullscreen {
        background: white;

        &.in {
            opacity: .85;
            filter: alpha(opacity=85);
        }
    }
}

// Specific facilities image placement
#faciliteiten {
    .modal {
        img {
            margin: auto;
        }
    }
}

.error-modal {
    .modal-content {
        top: 60px;
    }

    .modal-title {
        @extend .mt-0;
        margin-bottom: math.div($grid-gutter-width, 3);
    }

    .modal-body {
        padding: ($modal-inner-padding * 2);

        p {
            @extend .mb-0;
        }
    }
}

.modal-secret {
    .modal-dialog {
        width: 842px; // Modal border
    }

    .modal-header {
        @include size(840px, 178px);
        @include img-retina($secret-modal-banner, $secret-modal-banner-2x, 840px, 178px);
        border: 0;
    }

    .modal-title {
        @extend .mt-0;
        font-size: ($font-size-h2 * .85);
        color: $secret-modal-title-color
    }

    .modal-body {
        padding: ($panel-body-padding * 2);

        .btn {
            height: $input-height-base;
            padding: $padding-base-vertical $padding-base-horizontal !important;
            background: $secret-modal-btn-bg;

            &:before {
                @extend .hidden-xs-up;
            }
        }
    }

    .alert {
        margin-top: $grid-gutter-width;
        color: $secret-modal-text-color !important;
        font-style: italic;
    }
}

.modal-warning {
    .modal-dialog {
        top: 100px;
    }

    .modal-content {
        background-color: rgba($yellow, 1);

        .modal-body {
            line-height: 22px;
            padding: 0 30px 0 30px;

            p {
                &:first-of-type {
                    @extend .m-b-md;
                }
            }
        }
    }

    .modal-header {
        border-bottom: 0;
        padding: 0 0 0 30px;
        margin-left: -18px;
        justify-content: left;

        .fa-exclamation-triangle {
            color: $brand-primary;
            font-size: $font-size-lead;
        }

        .modal-title {
            font-family: $headings-font-family;
            font-weight: 600 !important;
            text-transform: uppercase;
            display: inline-block;
        }
    }
}

.modal-secret {
    background: url($secret-deal-backdrop);
    background-repeat: no-repeat;
    background-size: cover;
}

.modal-lg {
    max-width: 60%;
    transform: translate(0, -50%) !important;
    top: 50%;

    @include media-breakpoint-only(lg) {
        max-width: 92%;
        margin-left: 4%;
    }

    @include media-breakpoint-only(md) {
        max-width: 84%;
        margin-left: 8%;
    }

    @include media-breakpoint-only(sm) {
        max-width: 70%;
        margin: 0px 15%;
    }

    @include media-breakpoint-only(xs) {
        max-width: 92%;
        margin: 0 4%;
    }
}

.modal-content {
    border-radius: 0;
}
