.bg-dark .nav-item {
  .nav-link {
    color: white;
  }
}

.nav-link-caret {
  margin-left: 0.8rem !important;
  border-left: solid 1px #ddd;
}

.nav-tabs {
  &.nav-justified {
    width: 100%;

    > .nav-item {
      @extend .text-center;
      width: 1%;
      display: table-cell;
      float: none;

      > .nav-link {
        cursor: pointer;
      }
    }
  }
}

.tabs.card {
  .nav-tabs {
    .nav-link {
      color: $gray-dark;
    }

    .nav-link {
      padding: 0.75rem 1rem;
      border-color: $gray-lighter;
      border-width: 0 0 1px;
      color: $gray-light;
      text-transform: uppercase;
      font-size: 0.9rem;
    }

    .nav-link.active,
    .nav-item.open .nav-link {
      @include plain-hover-focus {
        color: $brand-primary;
        background-color: transparent;
        border-color: transparent transparent $brand-primary;
        border-width: 0 0 3px;
      }
    }
  }

  .tab-content {
    padding: ($spacer * 1.5);

    p:last-child {
      @extend .mb-0;
    }
  }
}

.accordion {
  .card {
    border-radius: 0;
    box-shadow: none;
    border-top: 1px solid rgba($brand-primary, 0.2);

    &:first-child {
      border-top: 2px solid #f0ead9;
    }
  }

  .card-header {
    position: relative;
    padding: $spacer $card-spacer-x * 2 $spacer 0;
    background: transparent;

    &.active {
      color: $brand-primary !important;
    }

    span,
    i {
      position: absolute;
      top: $spacer;
      right: -0.3125rem;
    }
  }

  .card-block {
    p {
      @extend .mb-0;
      color: $gray;
    }

    &.active {
      background: rgba($brand-primary, 0.1);
    }
  }
}

li {
  .nav-mobile {
    @media (max-width: 991px) {
      display: inline-block;
    }
  }

  div {
    &.nav-mobile {
      color: #fff;
      text-transform: none;
      text-decoration: none;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
