.mamba-forest,
.white-savannah {
    @extend .bg-white;
}

.yellow-bourbon {
    @extend .bg-yellow;
}

.red-yirgacheffe {
    @extend .bg-orange;
}

.blue-monkey,
.navy-blue {
    @extend .bg-blue;
}

.ethiopian-desert {
    @extend .bg-beige-light;
}

.jungle-liana,
.army-green {
    @extend .bg-green;
}

.plain-hyena {
    @extend .bg-beige-lighter;
}

.shades-of-brown {
    @extend .bg-taupe;
}

.bg-white,
.bg-yellow,
.bg-beige-dark,
.bg-beige,
.bg-beige-light,
.bg-beige-lighter {
    color: $black;

    h1, h2, h3, h4, h5, p
    .card-text,
    a:not(.btn) {
        color: $black;
    }

    .gallery-header h1 {
        color: $white;
    }
}

.bg-black,
.bg-orange,
.bg-blue,
.bg-green,
.bg-taupe {
    color: $white;

    h1, h2, h3, h4, h5, p
    .card-text,
    a:not(.btn) {
        color: $white;
    }

    .gallery-header h1 {
        color: $black;
    }
}

