.btn-primary,
.btn-secondary,
.btn-black {
    @extend .btn-outline-black;
}

.btn-white,
.btn-outline-white {
    @include button-outline-variant($white);
    border-color: rgba($white, .5);
    border-width: 2px;

    &:hover {
        background-color: $white !important;
        border-color: $white !important;
        color: $black !important;
    }
}

.btn-full-white {
    background-color: $white !important;
    border-color: $white !important;
    color: $black !important;
    font-family: $font-family-base;

    &:hover {
        background-color: #dedede !important;
        border-color: #dedede !important;
        color: $black !important;
    } 
}

.btn-outline-black {
    @include button-outline-variant($black);
    border: 2px solid rgba($black, .5) !important;
    font-weight: 600 !important;
    text-decoration: none;

    &:hover {
        background-color: $black !important;
        border-color: $black !important;
        color: $white !important;
    }
}

.btn-beige-outline {
    background-color: transparent !important;
    border-color: $btn-beige !important;
    color: $black !important;

    &:hover {
        background-color: $btn-beige !important;
        border-color: $btn-beige !important;
        color: $black !important;
    }
}

.btn-beige {
    background-color: $btn-beige !important;
    border-color: $btn-beige !important;
    color: $black !important;

    &:hover {
        background-color: $btn-beige !important;
        border-color: $btn-beige !important;
        color: $black !important;
    }
}

.btn-red {
    background-color: $red-light !important;
    border-color: $red-light !important;
    color: $white !important;

    &:hover {
        background-color: darken($red-light, 10%) !important;
        border-color: darken($red-light, 10%) !important;
        color: $white !important;
    }
}

.btn-primary-color {
    background-color: $btn-primary-color !important;
    border-color: $btn-primary-color !important;
    color: $white !important;

    &:hover {
        background-color: $orange-light !important;
        border-color: $orange-light !important;
        color: $white !important;
    }
}

.btn-primary-color-light {
    background-color: $orange-yellow-light !important;
    border-color: $orange-yellow-light !important;
    color: $white !important;

    &:hover {
        background-color: $orange-yellow, 10% !important;
        border-color: $orange-yellow, 10% !important;
        color: $white !important;
    }
}

.btn-green {
    background-color: $green !important;
    border-color: $green !important;
    color: $white !important;
    font-family: $font-family-base;

    &.less {
      margin-bottom: 10px;
    }

    &.more {
      margin-bottom: 15px;
    }

    &:hover {
        background-color: $green-light !important;
        border-color: $green-light !important;
        color: $white !important;
    }
}

.btn-orange {
    background-color: $orange !important;
    border-color: $orange !important;
    color: $white !important;
    font-family: $font-family-base;

    &.less {
        margin-bottom: 10px;
    }

    &.more {
        margin-bottom: 15px;
    }

    &:hover {
        background-color: $orange-light !important;
        border-color: $orange-light !important;
        color: $white !important;
    }
}

.btn-yellow {
    background-color: $yellow !important;
    border-color: $yellow !important;
    color: $black !important;

    &:hover {
        background-color: $yellow-light !important;
        border-color: $yellow-light !important;
        color: $black !important;
    }
}

.btn-taupe {
    background-color: $taupe !important;
    border-color: $taupe !important;
    color: $white !important;

    &:hover {
        background-color: $taupe-light !important;
        border-color: $taupe-light !important;
        color: $white !important;
    }
}

// .btn-taupe-dark {
//     background-color: $taupe-dark !important;
//     border-color: $taupe-dark !important;
//     color: $white !important;

//     &:hover {
//         background-color: $taupe-dark !important;
//         border-color: $taupe-dark !important;
//         color: $white !important;
//     }
// }

// .btn-blue {
//     background-color: $blue !important;
//     border-color: $blue !important;
//     color: $white !important;

//     &:hover {
//         background-color: $blue-dark !important;
//         border-color: $blue-dark !important;
//         color: $white !important;
//     }
// }

.btn-brown-light {
    background-color: $brown !important;
    border-color: $brown !important;
    color: $white !important;

    &:hover {
        background-color: $brown-light !important;
        border-color: $brown-light !important;
        color: $white !important;
    }
}

.btn-mail {
    background-color: $green !important;
    border-color: $green !important;
    color: $white !important;
    font-family: $font-family-base;

    &:hover {
        background-color: $green-light !important;
        border-color: $green-light !important;
        color: $white !important;
    }
}

.btn-main {
    background-color: $btn-main;
    border-color: $btn-main-border;
    color: $btn-main-color;
    &:hover {
        background-color: $btn-main-hover;
        border-color: $btn-main-hover-border;
        color: $btn-main-hover-color;   
    }
}

.btn-transparent-ghost {
    background-color: $btn-ghost;
    border-color: $btn-ghost-border;
    color: $btn-ghost-color;
    &:hover {
        background-color: $btn-ghost-hover;
        border-color: $btn-ghost-hover-border;
        color: $btn-ghost-hover-color;   
    }
}

.jumbotron-caption {
    .btn-primary {
        color: $jumbotron-button-color;
        border: $jumbotron-button-border !important;
        background-color: $jumbotron-button-background;
        &:hover {
            background-color: $jumbotron-button-hover-background !important;
            border: $jumbotron-button-hover-border !important;
            color: $jumbotron-button-hover-color !important;
        }
    }
}