$white: #fff !default;
$black: #000 !default;

$red: #d41217;
$red-light: #e15a5d;

$orange: #e84d0e;
$orange-light: #ef8357;

$orange-yellow: #f39200;
$orange-yellow-light: #f08d5f;

$yellow: #fbba00;
$yellow-light: #fccf4d;

$brown: #7d583c;
$brown-light: #a48a77;

$taupe: #ab9681;
$taupe-light: #c4b6a7;

$green: #596831;
$green-light: #8b966f;

$blue: #345d6d;
$teal: #5bc0de;

// Create beigescale
$beige-dark: #eae1c8;
$beige: #f0ead9;
$beige-light: #f6f1e6;
$beige-lighter: #fbf9f4;

// Create grayscale
$gray-dark: #292b2c !default;
$gray: #464a4c !default;
$gray-light: #636c72 !default;
$gray-lighter: #eceeef !default;
$gray-lightest: #f7f7f9 !default;

// Base colors
$brand-primary: $orange;
$brand-secondary: $green;
$brand-tertiairy: $beige-dark;

$main: #596831;
$navigation-primary-background: #ab9681;
$navigation-primary-text: #fff;

$navigation-affix-top-text: #fff;

$navigation-secondary-background: $green;
$navigation-secondary-text: #fff;
$navbar-secondary-text: #8b966f;

$background-white: white;
$background-orange: $orange;
$background-orange-light: $orange-light;
$background-brown-earth: $brown;
$background-green: $green;
$background-green-light: $green-light;
$background-green-dark: $green;
$background-yellow: $yellow;
$background-yellow-sun: $yellow-light;
$background-yellow-light: $yellow-light;
$background-taupe: $taupe;
$background-taupe-light: $taupe-light;
$background-blue: $blue;
$background-beige: $beige;
$background-beige-light: $beige-light;
$background-beige-dark: $beige;
$background-beige-lighter: $beige-lighter;
$background-red-light: $red-light;
$btn-primary-color: $orange;

$btn-main: #596831;
$btn-main-border: #596831;
$btn-main-color: white;

$btn-main-hover: #8b966f;
$btn-main-hover-border: #8b966f;
$btn-main-hover-color: black;

$btn-beige: $beige;

$btn-booking: #596831;
$btn-booking-color: white;
$btn-booking-border: #596831;

$btn-booking-hover: #8b966f;
$btn-booking-hover-color: white;
$btn-booking-hover-border: #8b966f;

$btn-ghost: transparent;
$btn-ghost-border: #000;
$btn-ghost-color: black;

$btn-ghost-hover: black;
$btn-ghost-hover-border: black;
$btn-ghost-hover-color: white;

$btn-navigation-background: $main;
$btn-navigation-background-hover: #8b966f;

$card-background-even: $beige;
$card-background-odd: #f0ead9;
$card-background-green-dark: $green;
$card-background-green-light: $green-light;
$card-background-beige-sand: $beige-dark;
$card-background-red-light: $red-light;
$card-background-orange-light: $orange-light;
$card-background-brown-light: $brown-light;
$card-background-brown-earth: $brown;
$card-background-taupe: $taupe;
$card-background-taupe-light: $taupe-light;
$card-background-yellow-sun: $yellow-light;
$card-background-white: $white;

$card-property-color: $taupe-light;

$footer-text-color: $black;
$footer-submit-text-color: $white;

$footer-light-tone: #f0ead9;
$footer-mid-tone: #eae1c8;
$footer-dark-tone: #ac9681;

$footer-social-media-background-color: none;
$footer-social-media-background-hover-color: #eae1c8;
$footer-social-media-icon-color: #a48a77;
$footer-social-media-icon-hover-color: white;
$footer-social-media-border-color: #eae1c8;

$footer-checked-items-color: #596831;
$checked-items-color: #596831;

$result-body: $beige-light;

$price-tag-background: $yellow-light;
$price-tag-background-unavailable: #eae1c8;

$material-ui-primary: $orange-yellow;

$filter-unfocused: #eae1c8;
$filter-focused: #f6f1e6;

$filter-after-color: #e84d0e;
$filter-before-color: #a48a77;

$calendar-outside: #f6f1e6;
$calendar-blocked: #f0ead9;
$calendar-selected: #e84d0e;

$accommodation-info-header: $green;
$accommodation-info-header-color: black;
$accommodation-info-background: #eae1c8;

$subject-picker-color: white;

$picker-text-color: black;
$picker-text-transform: uppercase;
$picker-border-bottom: 2px solid $beige-light;

$swp: $taupe-light;

$price-table-active-column: #f0ead9;
$price-table-header-background: #f6f1e6;
$price-table-header-border-bottom: 2px solid #d0c6bb;
$price-table-cell-hover-background: #f0ead9;
$price-table-cell-hover-shadow: inset 0px 0px 0px 2px #d0c6bb;
$price-table-cell-border-bottom: 1px solid #eae1c8;
$price-table-cell-active: #fccf4d;
$price-table-cell-active-color: #292b2c;
$price-table-cell-active-hover-shadow: none;
$price-table-no-price-found: #f0ead96e;
$price-table-arrow-color: #e84301;
$price-table-arrow-background: #f0ead9;
$price-table-arrow-hover-background: #eae1c8;
$price-table-receipt-background: #f6f1e6;
$price-table-receipt-border: 1px solid #eae1c8;

$booking-btn: $orange;
$booking-btn-border: $orange;
$booking-btn-border-radius: none;
$booking-bg: $beige-light;
$booking-hr: $beige-light;

$booking-row: #596831;
$booking-row-unselected: #ae9786;
$booking-step: #8b966f;
$booking-step-unselected: $brown-light;
$booking-close-button: #ab9681;

$faded-light: #faf8f2;

$booking-card-footer: #eae1c8;
$modal-odd: $beige-light;
$modal-even: #f6f1e6;
$modal-button: $main;
$modal-button-hover: #8b966f;

$modal-travel-company-button: #eae1c8;
$modal-travel-company-button-color: #8b966f;

$navbar-search-background: $taupe;
$navbar-search-text-color: white;
$navbar-search-text-placeholder-color: rgba(255, 255, 255, 0.5);

$jumbotron-button-background: transparent;
$jumbotron-button-color: black;
$jumbotron-button-border: 2px solid black;

$jumbotron-button-hover-background: #a0bd76;
$jumbotron-button-hover-color: white;
$jumbotron-button-hover-border: 2px solid #a0bd76;

$gallery-icon-color: $taupe-light;
$list-features-border-color: #eae1c8;

$text-add: $brown-light;
$loader: url("https://s3-eu-west-1.amazonaws.com/beeksebergen/originals/bb-logo.svg");
$loader-bg: $green;

$accordion-border-color: #f0ead9;
$accordion-arrow: #7d583c;
$accordion-arrow-collapsed: #a48a77;
$accordion-title: #7d583c;
$accordion-title-collapsed: #a48a77;

$tabs-bg: #c4b6a7;
$tabs-bg-hover: #ab9681;
$tabs-bg-active: #ab9681;

$table-striped-odd-bg: $beige-lighter;
$table-striped-border: $beige-dark;
$table-striped-header-border: #d0c6bb;

$font-family-sans-serif: "sofia-pro", "Century Gothic", sans-serif;
$font-family-secondary: "Anton";
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-main-nav: $font-family-secondary;
$font-size-base: 1.125rem; // Assumes the browser default, typically `16px`
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 700;

$headings-text-transform: uppercase;

.jumbotron {
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-transform: uppercase;
    font-weight: 400 !important;
  }
}

@import "base";
