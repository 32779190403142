.booking-bar {
    @include media-breakpoint-up(md) {
        @include pos-b-l(0, 50%);
        transform: translate(-50%,0%);
    }
    
    padding: 0;
    z-index: 998;
    height: auto !important;

    .col {
        @include media-breakpoint-down(sm) {
            flex-basis: initial;
            padding: 0 1rem;
        }

        @extend .bg-beige;
        padding: 0;
        flex-grow: 2;

        &:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
        }
    }

    // .column-button {
    //     @extend .px-3;

    //     @include media-breakpoint-up(sm) {
    //         @include make-col(2.5);
    //     }

    //     @include media-breakpoint-only(md) {
    //         .amount {
    //             display: block;
    //         }
    //     }
    // }

    &.affix {
        width: 100%;
        position: absolute;
        top: 150px;
        bottom: auto;
        transform: none;
        left: 0;
        z-index: 1030;
        position: fixed;
        background: $beige;

        @include media-breakpoint-down(md) {
            top: 57px;
        }
    }
}

// &anchor (div specific is extra)
div {
    .ticket-bar {
        @include pos-b-l(0, 50%);
        transform: translate(-50%,0%);
        z-index: 1010;
    
        @include media-breakpoint-down(sm) {
        position: relative;
        top: 0px;
        margin-top: 30px;
    
            h3 {
                color: $black !important;
                background-color: $beige;
                margin-bottom: 0px !important;
                padding-top: 5px;
            }
        }
    
        .ticket-title {
            font-weight: 600;
            @include media-breakpoint-only(lg) {
                font-size: 1.3rem;
            }
            @include media-breakpoint-only(md) {
                font-size: 1.4rem;
            }
        }
    
        p {
            @include media-breakpoint-only(lg) {
                font-size: 17px;
            }
        }
        
        .btn {
            position: relative;
            padding-bottom: .875rem;
            text-transform: none !important;
            font-size: $h5-font-size;
            @include media-breakpoint-only(lg) {
                font-size: 1.05rem;
            }
            @include media-breakpoint-only(md) {
                font-size: 1rem;
            }
    
            span {
                @extend .text-uppercase;
                display: block;
                margin-top: .375rem;
                font-size: $font-size-small;
                @include media-breakpoint-only(lg) {
                    font-size: 0.72rem;
                }
                @include media-breakpoint-only(md) {
                    font-size: 0.7rem;
                }
            }
    
            &:after {
                @include pos-t-r(50%, $btn-padding-x);
                @include mi('\E5C8');
                transform: translateY(-50%);
            }
        }
    
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        @include media-breakpoint-down(sm) {
            .btn-ticket {
                margin-top: 1rem;
            }
        }
    }


}

