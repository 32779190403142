.col-xs-offset-3 { @include media-breakpoint-only(xs) { @include make-col-offset(1); } }
.col-sm-offset-1 { @include media-breakpoint-up(sm) { @include make-col-offset(1); } }
.col-md-offset-1 { @include media-breakpoint-up(md) { @include make-col-offset(1); } }
.col-lg-offset-1 { @include media-breakpoint-up(lg) { @include make-col-offset(1); } }
.col-xl-offset-1 { @include media-breakpoint-up(xl) { @include make-col-offset(1); } }

.col-xs-offset-2 { @include media-breakpoint-only(xs) { @include make-col-offset(2); } }
.col-sm-offset-2 { @include media-breakpoint-up(sm) { @include make-col-offset(2); } }
.col-md-offset-2 { @include media-breakpoint-up(md) { @include make-col-offset(2); } }
.col-lg-offset-2 { @include media-breakpoint-up(lg) { @include make-col-offset(2); } }
.col-xl-offset-2 { @include media-breakpoint-up(xl) { @include make-col-offset(2); } }

.col-xs-offset-3 { @include media-breakpoint-up(xs) { @include make-col-offset(3); } }
.col-sm-offset-3 { @include media-breakpoint-up(sm) { @include make-col-offset(3); } }
.col-md-offset-3 { @include media-breakpoint-up(md) { @include make-col-offset(3); } }
.col-lg-offset-3 { @include media-breakpoint-up(lg) { @include make-col-offset(3); } }
.col-xl-offset-3 { @include media-breakpoint-up(xl) { @include make-col-offset(3); } }

.col-xs-offset-4 { @include media-breakpoint-up(xs) { @include make-col-offset(4); } }
.col-sm-offset-4 { @include media-breakpoint-up(sm) { @include make-col-offset(4); } }
.col-md-offset-4 { @include media-breakpoint-up(md) { @include make-col-offset(4); } }
.col-lg-offset-4 { @include media-breakpoint-up(lg) { @include make-col-offset(4); } }
.col-xl-offset-4 { @include media-breakpoint-up(xl) { @include make-col-offset(4); } }

.col-xs-offset-5 { @include media-breakpoint-up(xs) { @include make-col-offset(5); } }
.col-sm-offset-5 { @include media-breakpoint-up(sm) { @include make-col-offset(5); } }
.col-md-offset-5 { @include media-breakpoint-up(md) { @include make-col-offset(5); } }
.col-lg-offset-5 { @include media-breakpoint-up(lg) { @include make-col-offset(5); } }
.col-xl-offset-5 { @include media-breakpoint-up(xl) { @include make-col-offset(5); } }

.col-xs-offset-6 { @include media-breakpoint-up(xs) { @include make-col-offset(6); } }
.col-sm-offset-6 { @include media-breakpoint-up(sm) { @include make-col-offset(6); } }
.col-md-offset-6 { @include media-breakpoint-up(md) { @include make-col-offset(6); } }
.col-lg-offset-6 { @include media-breakpoint-up(lg) { @include make-col-offset(6); } }
.col-xl-offset-6 { @include media-breakpoint-up(xl) { @include make-col-offset(6); } }

.col-xs-offset-7 { @include media-breakpoint-up(xs) { @include make-col-offset(7); } }
.col-sm-offset-7 { @include media-breakpoint-up(sm) { @include make-col-offset(7); } }
.col-md-offset-7 { @include media-breakpoint-up(md) { @include make-col-offset(7); } }
.col-lg-offset-7 { @include media-breakpoint-up(lg) { @include make-col-offset(7); } }
.col-xl-offset-7 { @include media-breakpoint-up(xl) { @include make-col-offset(7); } }

.col-xs-offset-8 { @include media-breakpoint-up(xs) { @include make-col-offset(8); } }
.col-sm-offset-8 { @include media-breakpoint-up(sm) { @include make-col-offset(8); } }
.col-md-offset-8 { @include media-breakpoint-up(md) { @include make-col-offset(8); } }
.col-lg-offset-8 { @include media-breakpoint-up(lg) { @include make-col-offset(8); } }
.col-xl-offset-8 { @include media-breakpoint-up(xl) { @include make-col-offset(8); } }

.col-xs-offset-9 { @include media-breakpoint-up(xs) { @include make-col-offset(9); } }
.col-sm-offset-9 { @include media-breakpoint-up(sm) { @include make-col-offset(9); } }
.col-md-offset-9 { @include media-breakpoint-up(md) { @include make-col-offset(9); } }
.col-lg-offset-9 { @include media-breakpoint-up(lg) { @include make-col-offset(9); } }
.col-xl-offset-9 { @include media-breakpoint-up(xl) { @include make-col-offset(9); } }

.col-xs-offset-10 { @include media-breakpoint-up(xs) { @include make-col-offset(10); } }
.col-sm-offset-10 { @include media-breakpoint-up(sm) { @include make-col-offset(10); } }
.col-md-offset-10 { @include media-breakpoint-up(md) { @include make-col-offset(10); } }
.col-lg-offset-10 { @include media-breakpoint-up(lg) { @include make-col-offset(10); } }
.col-xl-offset-10 { @include media-breakpoint-up(xl) { @include make-col-offset(10); } }

.col-xs-offset-11 { @include media-breakpoint-up(xs) { @include make-col-offset(11); } }
.col-sm-offset-11 { @include media-breakpoint-up(sm) { @include make-col-offset(11); } }
.col-md-offset-11 { @include media-breakpoint-up(md) { @include make-col-offset(11); } }
.col-lg-offset-11 { @include media-breakpoint-up(lg) { @include make-col-offset(11); } }
.col-xl-offset-11 { @include media-breakpoint-up(xl) { @include make-col-offset(11); } }

.col-xs-offset-12 { @include media-breakpoint-up(xs) { @include make-col-offset(12); } }
.col-sm-offset-12 { @include media-breakpoint-up(sm) { @include make-col-offset(12); } }
.col-md-offset-12 { @include media-breakpoint-up(md) { @include make-col-offset(12); } }
.col-lg-offset-12 { @include media-breakpoint-up(lg) { @include make-col-offset(12); } }
.col-xl-offset-12 { @include media-breakpoint-up(xl) { @include make-col-offset(12); } }

.pt-4 {
    @include media-breakpoint-down(md) { padding-top: $spacer !important }
    @include media-breakpoint-up(lg) { padding-top: $spacer * 1.5 !important }
}

.pt-5 {
    @include media-breakpoint-down(md) { padding-top: $spacer !important }
    @include media-breakpoint-only(lg) { padding-top: $spacer * 1.5 !important }
    @include media-breakpoint-only(xl) { padding-top: $spacer * 3 !important }
}

.pb-4 {
    @include media-breakpoint-down(md) { padding-bottom: $spacer !important }
    @include media-breakpoint-up(lg) { padding-bottom: $spacer * 1.5 !important }
}

.pb-5 {
    @include media-breakpoint-down(md) { padding-bottom: $spacer !important }
    @include media-breakpoint-only(lg) { padding-bottom: $spacer * 1.5 !important }
    @include media-breakpoint-only(xl) { padding-bottom: $spacer * 3 !important }
}

.px-4 {
    @include media-breakpoint-down(md) { padding-left: $spacer !important; padding-right: $spacer !important }
    @include media-breakpoint-up(lg) { padding-left: $spacer * 1.5 !important; padding-right: $spacer * 1.5 !important }
}

.px-5 {
    @include media-breakpoint-down(md) { padding-left: $spacer !important; padding-right: $spacer !important }
    @include media-breakpoint-only(lg) { padding-left: $spacer * 1.5 !important; padding-right: $spacer * 1.5 !important }
    @include media-breakpoint-only(xl) { padding-left: $spacer * 3 !important; padding-right: $spacer * 3 !important }
}

.py-4 {
    @include media-breakpoint-down(md) { padding-top: $spacer !important; padding-bottom: $spacer !important }
    @include media-breakpoint-up(lg) { padding-top: $spacer * 1.5 !important; padding-bottom: $spacer * 1.5 !important }
}

.py-5 {
    @include media-breakpoint-down(md) { padding-top: $spacer !important; padding-bottom: $spacer !important }
    @include media-breakpoint-only(lg) { padding-top: $spacer * 1.5 !important; padding-bottom: $spacer * 1.5 !important }
    @include media-breakpoint-only(xl) { padding-top: $spacer * 3 !important; padding-bottom: $spacer * 3 !important }
}

.p-4 {
    @include media-breakpoint-down(md) { padding: $spacer !important }
    @include media-breakpoint-up(lg) { padding: $spacer * 1.5 !important }
}

.p-5 {
    @include media-breakpoint-down(md) { padding: $spacer !important }
    @include media-breakpoint-only(lg) { padding: $spacer * 1.5 !important }
    @include media-breakpoint-only(xl) { padding: $spacer * 3 !important }
}

.mt-4 {
    @include media-breakpoint-down(md) { margin-top: $spacer !important }
    @include media-breakpoint-up(lg) { margin-top: $spacer * 1.5 !important }
}

.mt-5 {
    @include media-breakpoint-down(md) { margin-top: $spacer !important }
    @include media-breakpoint-only(lg) { margin-top: $spacer * 1.5 !important }
    @include media-breakpoint-only(xl) { margin-top: $spacer * 3 !important }
}

.mb-4 {
    @include media-breakpoint-down(md) { margin-bottom: $spacer !important }
    @include media-breakpoint-up(lg) { margin-bottom: $spacer * 1.5 !important }
}

.mb-5 {
    @include media-breakpoint-down(md) { margin-bottom: $spacer !important }
    @include media-breakpoint-only(lg) { margin-bottom: $spacer * 1.5 !important }
    @include media-breakpoint-only(xl) { margin-bottom: $spacer * 3 !important }
}

.mx-4 {
    @include media-breakpoint-down(md) { margin-left: $spacer !important; margin-right: $spacer !important }
    @include media-breakpoint-up(lg) { margin-left: $spacer * 1.5 !important; margin-right: $spacer * 1.5 !important }
}

.mx-5 {
    @include media-breakpoint-down(md) { margin-left: $spacer !important; margin-right: $spacer !important }
    @include media-breakpoint-only(lg) { margin-left: $spacer * 1.5 !important; margin-right: $spacer * 1.5 !important }
    @include media-breakpoint-only(xl) { margin-left: $spacer * 3 !important; margin-right: $spacer * 3 !important }
}

.my-4 {
    @include media-breakpoint-down(md) { margin-top: $spacer !important; margin-bottom: $spacer !important }
    @include media-breakpoint-up(lg) { margin-top: $spacer * 1.5 !important; margin-bottom: $spacer * 1.5 !important }
}

.my-5 {
    @include media-breakpoint-down(md) { margin-top: $spacer !important; margin-bottom: $spacer !important }
    @include media-breakpoint-only(lg) { margin-top: $spacer * 1.5 !important; margin-bottom: $spacer * 1.5 !important }
    @include media-breakpoint-only(xl) { margin-top: $spacer * 3 !important; margin-bottom: $spacer * 3 !important }
}

.m-4 {
    @include media-breakpoint-down(md) { margin: $spacer !important }
    @include media-breakpoint-up(lg) { margin: $spacer * 1.5 !important }
}

.m-5 {
    @include media-breakpoint-down(md) { margin: $spacer !important }
    @include media-breakpoint-only(lg) { margin: $spacer * 1.5 !important }
    @include media-breakpoint-only(xl) { margin: $spacer * 3 !important }
}