.rw-numberpicker {
    display: block !important;
    position: relative;

    input {
        position:absolute;
        right: 2.34rem;
        width: 3rem;
        height: 2.5rem;
        padding: 0.6875rem 0 !important;
        border: 0 !important;
        background: $beige-light;
        font-weight: 600;
        text-align: center;
        pointer-events: none;
        color: $gray;
    }

    .rw-i-caret-up,
    .rw-i-caret-down {
      @extend %material-icons;
      line-height: 1;
    }

    .rw-i-caret-up:after {
      content: '\E145';
      font-size: 1rem;
      -webkit-font-smoothing: none;
    }

    .rw-i-caret-down:after {
      content: '\E15B';
      font-size: 1rem;
      -webkit-font-smoothing: none;
    }

    .rw-select button {
        position:absolute;
        bottom: 0 !important;
        width: 38px !important;
        padding: 1.25rem 1rem;
        top: 0 !important;
        right: 0;
        border: 0 !important;
        border-radius: 0 !important;
        background: $beige-dark !important;
        color: $gray;
        cursor: pointer !important;
        display: inline-block;

        span {
          position: absolute;
          top:12px;
          left:10px;
          color: $gray;
        }

        .rw-i-caret-down {
          left: 11px;
        }

        i {
            background: $gray !important;
        }

        &:nth-child(2) {
          right: 5.34rem !important;
        }
    }
}

.numeric-input {
    small {
        display: block;
        height: 1.25rem;
    }
    
}