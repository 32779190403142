div {
  &.daypicker-container {
    background: $beige-light;
    margin-left: 0;
    margin-right: 0;
    
    [class*="col"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.DayPicker {
  width: 100% !important;
  padding: 0;
  background: none;
  box-shadow: none;
  border-radius: 0px;
  
  svg {
    display: none;
  }
  
  // @include media-breakpoint-down(md) {
  //   padding-right: 1rem;
  // }
  
  // @include media-breakpoint-down(sm) {
  //   padding-left: .5rem;
  //   padding-right: .5rem;
  // }
  
  .DayPicker_weekHeaders {
    position: absolute;
    top: 85px;
  }
  
  .DayPickerNavigation {
    .DayPickerNavigation_button {
      // top: 30px;
      top: 0;
      z-index: 2;
      position: absolute;
      background: none;
      padding: 0;
      width: 50px !important;
      
      svg {
        display: none;
      }
      
      button {
        @extend .btn-green;
        padding: .5rem;
        border-radius: 0px;
        color: #fff;
        cursor: pointer;
        
        @include media-breakpoint-down(sm) {
          padding: .25rem .35rem;
        }
        
        i {
          font-size: 2rem;
        }
      }
      &:first-child {
        left: 17px;
      }
      &:last-child {
        right: 17px;
      }
    }
  }
  
  
  .DayPicker_weekHeader {
    top: 90px;
    width: 100%;
    
    ul {
      display: flex;
      padding: 0;
      
      li {
        // width: 50px !important;
        text-align: center;
        padding: 10px 0;
        list-style: none;
        color: $black;
        
        
        small {
          font-size: 1.25rem;
          
          @include media-breakpoint-down(sm) {
            font-size: 1rem;
          }
        }
      }
    }
  }
  
  .CalendarMonth {
    padding: 0 !important;
  }
  
  .CalendarMonth_caption {
    padding: 0px 0 75px;
    font-size: $font-size-h5;
    text-transform: lowercase;
    @extend .text-center;
    
    @include media-breakpoint-down(sm) {
      padding: 0 0 10px;
      font-size: 1.25rem;
    }
    
    strong {
      line-height: 50px;
    }
  }
  
  .CalendarMonth_table {
    .CalendarDay {
      background: $white;
      font-weight: 700;
      text-align: center;
      
      &.CalendarDay__blocked_calendar {
        background: $calendar-blocked;
        color: rgba($body-color, .4);
        cursor: default;
      }
      
      &.CalendarDay__outside {
        background: $calendar-outside !important;
        color: rgba($body-color, .4);
      }
      
      &.CalendarDay__highlighted_calendar {
        background: mix(#fff, $calendar-selected, 40%) !important;
        color: $body-color;
        
        &.CalendarDay__blocked_calendar {
          color: rgba($body-color, .4);
          background: mix(#fff, $calendar-selected, 60%) !important;
        }
        
        &.CalendarDay__blocked_out_of_range {
          background: $calendar-selected !important;
          color: $white;
          text-shadow: 0 -1px 0 rgba(0,0,0,.25);
        }
      }
      
      &.CalendarDay__blocked_out_of_range {
        background: $calendar-selected !important;
        color: $white;
        text-shadow: 0 -1px 0 rgba(0,0,0,.25);
      }
    }
  }
  
  .DayPicker_transitionContainer {
    overflow: hidden !important;
    height: auto !important;
    width: 350px !important;
  }
  
  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }
  
  .CalendarMonthGrid_month__hidden {
    display: inline-block !important;
    vertical-align: top !important;
    min-height: 100% !important;
    position: absolute !important;
    z-index: -1 !important;
    opacity: 0 !important;
    pointer-events: none !important;
  }
  
  .CalendarMonthGrid_month__horizontal {
    display: inline-block !important;
    vertical-align: top !important;
    min-height: 100% !important;
    background: none;
    height: 100%;
    
    table {
      @include media-breakpoint-only(xl) {
        width: calc(100% - 2px);
      }
      
      @include media-breakpoint-only(lg) {
        width: calc(100% - 1px);
      }
      
      @include media-breakpoint-only(md) {
        width: calc(100% - 4px);
      }
    }
    
    .CalendarMonth--horizontal {
      width: 100%;
      
      &:nth-child(3) {
        display: none;
      }
    }
  }
  
  .transition-container {
    width: 100% !important;
    
    .CalendarMonthGrid {
      background: none;
    }
    
    td {
      width: 1% !important;
      background: $beige;
      border: 2px solid $beige-light;
    }
    
    .CalendarDay {
      background: #fff;
      color: $black !important;
      
      &:hover {
        border: 2px double $yellow !important;
      }
      
      > div {
        
        > span {
          position: relative;
          display: block;
          padding: 3px;
          //border: 2px solid #fff;
          
          &:before {
            content: '';
            display: block;
            padding-top: 100%;
          }
          
          span {
            width: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 600;
            font-size: $font-size-h5;
            
            @include media-breakpoint-down(sm) {
              font-size: 1rem;
            }
          }
        }
      }
    }
    
    .CalendarDay--blocked {
      background: $beige-dark !important;
      color: $brown-light;
      
      &:hover {
        border: 2px double $yellow !important;
        cursor: pointer !important;
      }
      
      &:before, &:after {
        display: none !important;
      }
      
      &.CalendarDay--legend-2 {
        div {
          &:after {
            border-color: $beige-dark transparent transparent transparent;
          }
        }
      }
    }
    
    .CalendarDay--blocked-out-of-range {
      color: $brown-light !important;
      
      &:hover {
        border: 2px solid $beige-light !important;
        cursor: default !important;
        color: $brown-light !important;
      }
      
      &:before, &:after {
        display: none !important;
      }
    }
    
    .CalendarDay--today {
      background: $white !important;
      border: 2px double $black;
      color: $black !important;
      
      span {
        cursor: pointer;
      }
      
      &:hover {
        border: 2px double $yellow !important;
      }
    }
    
    .CalendarDay--selected-span {
      background: $yellow-light !important;
      border: 2px double $yellow;
      
      &:hover {
        border: 2px double $yellow !important;
      }
      
      /* Legend Africa by Light - Green triangle */
      &.CalendarDay--legend-2 {
        div {
          &:after {
            border-color: $yellow-light transparent transparent transparent !important;
          }
        }
      }
    }
    
    .CalendarDay--outside {
      background: $beige;
    }
    
    /* Legend 3 */
    .CalendarDay--legend-1 {
      background-size: 6px 6px !important;
      background-image: linear-gradient(
      -45deg,
      transparent 46%,
      $taupe-light 49%,
      $taupe-light 51%,
      transparent 55%
      )
    }
    
    /* Legend Africa by Light - Green triangle */
    .CalendarDay--legend-2 {
      position: relative;
      
      div {
        position: relative;
        
        &:before {
          content: "";
          position: absolute;
          top: 3px;
          left: 3px;
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 18px 18px 0 0;
          border-color: $green transparent transparent transparent;
          
          @include media-breakpoint-down(sm) {
            top: 1px;
            left: 1px;
            border-width: 10px 10px 0 0;
          }
        }
        
        &:after {
          content: "";
          position: absolute;
          top: 3px;
          left: 3px;
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 7px 0 0;
          border-color: $white transparent transparent transparent;
          
          @include media-breakpoint-down(sm) {
            top: 1px;
            left: 1px;
            border-width: 4px 4px 0 0;
          }
        }
      }
    }
    
    /* Legend 5 - Brown circle */
    .CalendarDay--legend-3 {
      position: relative;
      
      &:after {
        @include size(18px, 18px);
        content: "";
        position: absolute;
        top: 3px;
        left: 50%;
        bottom: auto;
        transform: translate(-50%, 0%);
        border: 5px solid $brown;
        border-radius: 50%;
        
        @include media-breakpoint-down(sm) {
          height: 8px;
          width: 8px;
          top: 1px;
          border: none;
          background: $brown;
        }
      }
    }
    
    /* Legend 6 - Red square */
    .CalendarDay--legend-4 {
      position: relative;
      
      &:before {
        @include size(18px, 18px);
        content: "";
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: auto;
        border: 5px solid $red-light;
        
        @include media-breakpoint-down(sm) {
          height: 8px;
          width: 8px;
          top: 1px;
          right: 1px;
          border: none;
          background: $red-light;
        }
      }
    }
  }
  
  .CalendarMonthGrid--animating {
    -webkit-transition: -webkit-transform 1ms ease-in-out;
    -moz-transition: -moz-transform 1ms ease-in-out;
    transition: transform 1ms ease-in-out;
    z-index: 1;
  }
}

.legend {
  @extend .ml-0;
  @extend .pb-4;
  padding-left: 2.1875rem;
  background: $beige-light;
  
  @include media-breakpoint-down(sm) {
    padding-left: 1.25rem;
  }
  
  .legend-row {
    @include media-breakpoint-only(xl) {
      margin-right: 1rem !important;
    }
    
    @include media-breakpoint-only(md) {
      margin-right: 2rem !important;
    }
  }

  .legend-block {
    @include size(24px, 24px);
    @extend .mr-2;
    display: inline-block;
    border: 2px solid #fff;
    
    &.CalendarLegend--legend-selected {
      background: $yellow-light;
      border: 2px double $yellow;
    }
    
    &.CalendarLegend--legend-today {
      border: 2px double $black;
    }
    
    &.CalendarLegend--legend-0 {
      background-size: 6px 6px !important;
      background-image: linear-gradient(
      -45deg, 
      transparent 46%, 
      $taupe-light 49%, 
      $taupe-light 51%, 
      transparent 55%
      )
    }
    
    /* Legend Africa by Light - Green triangle */
    &.CalendarLegend--legend-1 {
      position: relative;
      
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 0;
        border-color: $green transparent transparent transparent;
      }
      
      &:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 0;
        border-color: $beige-light transparent transparent transparent;
      }
    }
    
    /* Legend 5 - Brown circle */
    &.CalendarLegend--legend-2 {
      position: relative;
      
      &:before {
        @include size(8px, 8px);
        content: "";
        position: absolute;
        top: 0px;
        left: 50%;
        bottom: auto;
        transform: translate(-50%, 0%);
        border: 3px solid $brown;
        background: #fff;
        border-radius: 50%;
        
        @include media-breakpoint-down(sm) {
          border: none;
          background: $brown;
        }
      }
    }
    
    /* Legend 6 - Red square */
    &.CalendarLegend--legend-3 {
      position: relative;
      
      &:before {
        @include size(8px, 8px);
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: auto;
        border: 3px solid $red-light;
        background: #fff;
        
        @include media-breakpoint-down(sm) {
          border: none;
          background: $red-light;
        }
      }
      
    }
  }
  
  .legend-name {
    vertical-align: super;
    
    @include media-breakpoint-down(sm) {
      font-size: $font-size-xs;
    }
  }
  
  @media (max-width: 375px) {
    padding-top: 1.25rem;
  }
  
  @media (max-width: 320px) {
    padding-top: 3rem;
  }
}

.list-events {
  height: 100%;
  padding: 2rem 3rem;
  background: $beige-light;
  
  @include media-breakpoint-down(sm) {
    padding: 0 2.25rem 2.25rem;
  }
  
  .opening-hours {
    margin-top: 1.5rem;
    padding: 1.25rem;
    background: $yellow-light;
    font-size: $font-size-h5;
    
    @include media-breakpoint-only(lg) {
      font-size: 1rem;
    }
    
    &:first-letter {
      text-transform: uppercase;
    }
  }
  
  h3 {
    font-weight: 600;
  }
  
  h4 {
    margin-top: 1.5rem;
    color: $brown-light;
    font-weight: 600;		
  }
  
  ul {
    @extend .p-0;
    
    li {
      list-style: none;
      line-height: 2.25rem;
      
      > ul {
        margin-top: -.5rem;
        
        li {
          margin-left: 1.45rem;
          
          &:before {
            display: none;
          }
          
          @include media-breakpoint-only(lg) {
            font-size: 1rem;
          }
        }
      }
      
      &:before {
        position: relative;
        top: 6px;
        left: -8px;
        content: '\E5CC';
        color: $black;
        font-family: 'material icons';
        font-size: 1.5rem;
        font-weight: normal;
        margin-right: 0;
      }
    }
  }
  
  .scroll-top {
    position: absolute;
    left: 30px;
    right: auto;
    bottom: 30px;
    padding: .5rem .75rem;
    background: #ccc;
    font-family: 'material icons';
    color: #fff;
    
    i {
      font-size: 2rem;
    }
    
    @include media-breakpoint-up(lg) {
      display: none;
    }
    
    @include media-breakpoint-down(sm) {
      right: 30px;
      bottom: 30px;
      left: auto;
      padding: .25rem .25rem;
    }	
  }
}

.calendar {
  .nav-tabs {
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
  }
  
  .tabs-horizontal {
    .nav-tabs {
      @include media-breakpoint-down(md) {
        flex-direction: row !important;
      }
      
      li {
        border: none !important;
        flex: 1; 
        background-color: auto!important;
        margin-left: 0px;
        margin-bottom: 0px;
        
        @include media-breakpoint-down(lg) {
          margin-right: 0rem;
        }
        
        .nav-link {
          background: $brown-light;
          border-bottom: 0px !important;
          color: #fff;
          font-size: $font-size-h3;
          font-weight: 600;
          text-transform: none !important;
          
          @include media-breakpoint-down(sm) {
            font-size: 1rem;
          }
          
          &.active {
            background: $beige-light !important;
            color: $black!important;
          }
        }
        
        &:nth-child(2) {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
      }
    }
  }
  
  .tab-content {
    padding: 0;
    border: 0 !important;
  }
}

caption {
  caption-side: top;
}

.datepicker {
  table {
    tr {
      .month {
        @include size(25%, auto);
        @extend .m-0;
        position: relative;
        border-radius: 0;
        
        &:before {
          @include size(100%, 100%);
          position: absolute;
          top: 0;
          left: 0;
          border: 2px solid transparent;
          z-index: 99;
          content: '';
        }
        
        &:hover {
          background: $white !important;
          
          &:before {
            border-color: $calendar-selected !important;
            height: 50px !important;
          }
        }
        
        &.focused {
          background-color: $calendar-selected;
        }
      }
      
      td {
        @include size(50px, 50px);
        @extend .text-center;
        position: relative;
        color: $body-color;
        background: $beige !important;
        font-weight: 700;
        
        &:before {
          @include size(100%, 100%);
          position: absolute;
          top: 0;
          left: 0;
          border: 2px solid transparent;
          z-index: 99;
          content: '';
        }
        
        @include media-breakpoint-only(md) {
          @include size(40px, 40px);
          font-size: 16px;
        }
        
        @include media-breakpoint-down(sm) {
          @include size(48px, 48px);
          font-size: 16px;
        }
        
        @media (max-width: 320px) {
          @include size(40px, 40px);
        }
      }
      
      .day, .month {
        background: $white !important;
        
        &:hover {
          background: $white !important;
          
          &:before {
            border-color: $calendar-selected !important;
            height: 50px !important;
          }
        }
        
        &.disabled {
          color: rgba($body-color, .4);
          background: $beige !important;
          
          &:hover {
            color: rgba($body-color, .4);
            background: $beige !important;
            
            &:before {
              border-color: transparent !important;
            }
          }
        }
        
        &.old, &.new {
          color: rgba($body-color, .4);
          background: transparent !important;
          
          &:hover {
            color: rgba($body-color, .4);
            background: transparent !important;
            
            &:before {
              border-color: transparent !important;
            }
          }
        }
        
        &.in-stay {
          background: mix(#fff, $calendar-selected, 40%) !important;
          color: $body-color;
          
          &:hover {
            background: mix(#fff, $calendar-selected, 40%) !important;
            color: $body-color;
            border-color: transparent;
          }
          
          &.disabled {
            background: mix(#fff, $calendar-selected, 70%) !important;
            color: rgba($body-color, .4);
            
            &:hover {
              background: mix(#fff, $calendar-selected, 70%) !important;
              color: rgba($body-color, .4);
              border-color: transparent;
            }
          }
        }
        
        &.active {
          background: $orange !important;
          color: #fff;
          
          &:hover {
            background: $orange !important;
            color: #fff;
          }
        }
      }
    }
  }
  
  .gallery-body & {
    table tr td {
      @include media-breakpoint-only(md) {
        @include size(40px, 40px);
        font-size: 16px;
      }
    }
  }
}

.date-picker-small {
  &:before {
    @media (max-width: 768px) {
      padding-top: 100%;
    }
  }

  .CalendarMonth_table {
    .CalendarDay {
      background: $white;
      font-weight: 700;
      text-align: center;
      border: none;
      
      &:hover {
        box-shadow: inset 0 0 0 2px $calendar-selected;
        border: none;
      }

      &.CalendarDay__blocked_calendar {
        box-shadow: none;
      }
      
      &.CalendarDay__outside {
        box-shadow: none;
      }
    }
  }

  .DayPicker {
    .DayPicker_transitionContainer {
      width: 100% !important;
      height: 100% !important;
    }

    div {
      div {
        width: 100% !important;
      }
    }

    .CalendarMonthGrid_month__horizontal {
      width: 100%;
    }
    
    .CalendarMonth_table {
      position: relative;
    }
  }

  .DayPicker_weekHeader {
    padding: 0px !important;
  }
  
  .DayPicker_weekHeaders {
    position: relative;
  }
  
  ul {
    text-align: center;
    position: absolute;
    width: 100%;
    top: -25px;
    
    li {
      &.DayPicker_weekHeader_li {
        width: calc(100%/7) !important;
      }
    }
  }
  
  .CalendarMonth_caption {
    z-index: 50;
  }

  table {
    width: 100%;
    td {
      width: calc(100% / 7) !important;
      border: 2px solid #f6f1e6;
    }
  }
  
  
  @media (max-width: 768px) {
    .CalendarDay_caption {
      padding: 0 0 125px;
    }
  }
  
  // iPhone 6 - 7 - 8
  @media (max-width: 414px) {
    ul {
      top: 2.75rem;
    }
    
    .DayPicker {
      .DayPicker_weekHeaders {
        top: 0;
      }
    }
    
    .CalendarMonth_caption {
      padding: 0 0 45px;
    }
  }
  
  // iPhone 5
  @media (max-width: 320px) {
    ul {
      top: 1.75rem;
    }
    
    .DayPicker {
      .DayPicker_weekHeaders {
        top: 0;
      }
    }
    .CalendarMonth_caption {
      padding: 0 0 25px;
    }
  }

  div[role='button'] {
    &.DayPickerNavigation_button {
      @media (max-width: 414px) {
        &:first-child {
          left: 15px;
        }
        
        &:last-child {
          right: 15px;
        }
      }
      
      
      @media (max-width: 320px) {
        &:first-child {
          left: 5px;
        }
        
        &:last-child {
          right: 5px;
        }
      }
    }
  }
}

/**
 * Styling for calendar
 */

.date-picker-large {
  &:before {
    padding-top: 90%;
    content: "";
    display: block;
    
    @media (max-width: 768px) {
      padding-top: 100%;
    }
  }
  
  div[role='button']{
    &.DayPickerNavigation_button {
      position: absolute;
      top: 30px;
      width: 25% !important;
      button[type='button'] {
        &.btn-navigation {
          background-color: $btn-navigation-background !important;
          border-color: $btn-navigation-background !important;
          color: white;
          cursor: pointer;
          
          @media (min-width: 900px) {
            padding: 0.6rem 0.8rem;
          }
        }
      }
      
      &:first-child {
        left: 35px;
      }
      
      &:last-child {
        right: 35px;
        button {
          float: right;
        }
      }
      
      @media (max-width: 414px) {
        top: 25px;
        &:first-child {
          left: 15px;
        }
        
        &:last-child {
          right: 15px;
        }
      }
      
      
      @media (max-width: 320px) {
        top: 5px;
        &:first-child {
          left: 5px;
        }
        
        &:last-child {
          right: 5px;
        }
      }
    }
  }
  
  .DayPicker {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px 35px;

    .CalendarMonth_table {
      .CalendarDay {
        &:hover {
          border: 2px double #fbba00;
        }

        &.CalendarDay__blocked_calendar {
          border: 2px solid #f6f1e6;
        }
      }
    }

    .DayPicker_transitionContainer {
      width: 100% !important;
      height: 100% !important;
    }
    
    .DayPickerNavigation {
      display: flex;
      li {
        font-size: 2rem;
      }
    }
    
    div {
      div {
        width: 100% !important;
      }
    }
    
    .CalendarMonthGrid_month__horizontal {
      width: 100%;
    }
    
    .CalendarMonth_table {
      position: relative;
    }
    
    .CalendarMonth_caption {
      strong {
        font-size: 28px;
      }
    }
  }
  
  .DayPicker_weekHeader {
    padding: 0px !important;
  }
  
  .DayPicker_weekHeaders {
    position: relative;
  }
  
  ul {
    text-align: center;
    position: absolute;
    width: 100%;
    top: -25px;
    
    li {
      &.DayPicker_weekHeader_li {
        width: calc(100%/7) !important;
      }
    }
  }
  
  .CalendarMonth_caption {
    z-index: 50;
  }
  
  .td-wrapper {
    padding-top:100%;
    position:relative;
    .td-inner {
      position: absolute;
      height: 100%;
      top: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    &.blocked {
      background: #eae1c8;
      // 2px solid #f6f1e6
    }
  }
  
  td[role='button'] {
    &.CalendarDay {
      background-color: #fff;
      color: black;
      
      &.CalendarDay__today {
        border: black double 2px;
      }
      
      &.CalendarDay__selected {
        background: #fccf4d;
        border: 2px double #fbba00;
      }
      
      &.CalendarDay__blocked_calendar {
        background-color: #eae1c8;
        color: #a48a77;
      }
      
      &.CalendarDay__outside {
        background-color:#f0ead9 !important;
        pointer-events: none;
        cursor: default;
      }
      
      &:hover {
        box-shadow: none;
        // border: #fbba00 double 2px;
      }
    }
  }
  
  table {
    width: 100%;
    td {
      width: calc(100% / 7) !important;
      border: 2px solid #f6f1e6;
    }
  }
  
  
  @media (max-width: 768px) {
    .CalendarDay_caption {
      padding: 0 0 125px;
    }
  }
  
  // iPhone 6 - 7 - 8
  @media (max-width: 414px) {
    padding: 25px 15px 15px;
    
    ul {
      top: 2.75rem;
    }
    
    .DayPicker {
      padding: 15px;
      
      .DayPicker_weekHeaders {
        top: 0;
      }
    }
    
    .CalendarMonth_caption {
      padding: 0 0 45px;
    }
  }
  
  // iPhone 5
  @media (max-width: 320px) {
    padding: 5px;
    ul {
      top: 1.75rem;
    }
    
    .DayPicker {
      padding: 5px;
      
      .DayPicker_weekHeaders {
        top: 0;
      }
    }
    .CalendarMonth_caption {
      padding: 0 0 25px;
    }
  }
  
  &.additional-row {
    &:before {
      padding-top: 105%;
    }
  }
}

.future-booking {
  display: none;
}

.future-date {
  .DayPicker_weekHeaders,
  tbody {
    display: none;
  }

  .CalendarMonth_caption {
    // Dirty hack to position the month somewhat "okay". This needs a proper fix for mobile
    padding: 0px 0 25px 15px;
  }
  
  .future-booking {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex !important;
    height: 250px;
    width: 100%;
    background-color: $card-background-odd;
    flex-direction: column;
  }

  .datepicker .datepicker-switch {
    pointer-events: none;
  }
}

.special {
  background-size: 6px 6px !important;
  background-image: -webkit-linear-gradient(135deg, transparent 46%, #c4b6a7 49%, #c4b6a7 51%, transparent 55%);
  background-image: -o-linear-gradient(135deg, transparent 46%, #c4b6a7 49%, #c4b6a7 51%, transparent 55%);
  background-image: linear-gradient(-45deg, transparent 46%, #c4b6a7 49%, #c4b6a7 51%, transparent 55%);
}