
@use "sass:math";

.form-control {
    box-shadow: none;
    border-width: 1px;
    border-color: rgba($black, .5);
    font-family: $font-family-sans-serif;

    #sem_brochure_country {
        border-radius: 0 !important;
    }

    .form-inline & {
        color: $body-color !important;
    }
}

.pointer-events {
    pointer-events: none;
    button {
        pointer-events: all;
    }
}

.success-message-style {
    padding-left: 15px;
    white-space: normal;
    @media (max-width: 445px) {
        white-space: normal;
    }
}

.form-inline label {
    display: -webkit-box;
}

.checkbox, .radio {
    margin-top: 13px;
    margin-bottom: 13px;

    .checkbox {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.input-group {
    .btn-submit {
        @include size($input-height-base, $input-height-base);

        &:before {
            right: 0;
        }
    }
}

.form-group {
  .has-help-text {
      & input:not([type='radio'], [type='checkbox']), & textarea, & select {
          margin-bottom: 4px !important;
      }
  }
}

.help-block {
    @extend .m-t-sm;
    @extend .form-text;
    @extend .text-muted;

    font-size: 14px;
    font-style: italic;
    margin-bottom: .5rem;
}

.add-select {
    .btn {
        border-radius: 0;
    }
}

.custom-select {
    min-width: 175px;
    position: relative;
    padding: 0 !important;
    background: transparent !important;
    border: 0 !important;

    @include media-breakpoint-down(sm) {
        min-width: 0;
    }

    .form-control {
        height: auto;
        position: relative;
        z-index: 2;
        background: transparent !important;
        -moz-appearance: none;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        outline: 0 !important;
        padding: 0 2.5rem 0 1rem !important;
        border-width: 2px;
    }

    &:after {
        @include mi("\E313"); //.mi("keyboard_arrow_down");
        @include pos-t-r(50%, 10px);
        transform: translate(0, -50%);
        z-index: 1;
        font-size: $font-size-lead;
    }
}

select {
    color: #000;
    color: rgba(0,0,0,0);

    &:-moz-focusring {
        color: transparent;
    }
}

.form-horizontal {
    .control-label {
        @extend .text-left;

        &.required {
            &:after {
                margin-left: math.div($grid-gutter-width, 6);
                content: "*";
            }
        }
    }
}


// Actual form

.form-holder {
    @extend .pb-0;

    h2 {
        margin-bottom: $grid-gutter-width;
    }

    .form-group {
        @extend .row;
        @extend .mx-0;
    }

    .checkbox,
    .radio {
        display: block;
        margin: 0 !important;

        label {
            margin-bottom: .5rem !important;
        }

        i {
            cursor: pointer;
            // display: none !important;
        }
    }

    .checkbox {
        > label {
            .form-check {
                @extend .mb-0;
                display: inline-block;

                label {
                    @extend .mb-0;
                }
            }
        }
    }

    .radio {
        display: block;

        input[type="radio"] {
            margin-right: .5rem;
        }
    }

    // Conditional logic

    .form-group {
        display: flex;

        &[class*='arrangement_options'],
        &[class*='arrangements'],
        &[class*='supplements'],
        &[class*='extras'] {
            display: none;
        }
    }

    [class*='arrangements'] {
        .radio {
            i {
                position: relative;
                top: -3px;
                display: inline-block !important;
            }
        }
    }

    .form-group-visible {
        display: flex !important;
    }

    .has-danger {
        &:after {
            position: absolute;
            right: 0;
            margin: 0;
            @include media-breakpoint-down(md) {
                position: relative;
                right: 5px;
            }
        }
    }

    // This was commented since it removes the first option of a radio button list if the field is not required??
    // .control-label:not(.required) {
    //     + div {
    //         .radio:first-child {
    //             display: none;
    //         }
    //     }
    // }
}


.contact-form {
    .form-group {
        display: flex;

        .col-lg-8 {
            @include media-breakpoint-down(md) {
                float: left;
            }
        }

        div {
            div {
                .radio {
                    display: inline;
                    margin-right: 10px;
                }
            }

            textarea {
                min-height: 150px;
            }
        }

        .form-control {
            background-color: $white;
            border-width: 1px !important;
            border-color: rgba($black, .5);
            margin-bottom: 10px;
        }

        @include media-breakpoint-down(md) {
            display:block;
        }
    }
}

// .has-danger {
//     &:after{
//         @include mi('\E14c');
//         display: block;
//         width: 22px;
//         height: 22px;
//         color: $red;
//         font-size: 1.5rem;
//         line-height: 36px;
//         margin-left: -7px;

//         @include media-breakpoint-down(md) {
//             margin-left: 0px;
//             width: 0px;
//             height: 0px;
//         }
//     }
// }

.has-danger .form-control {
    border-color: $red !important;
}

.form-control:focus {
    border-color: #a9bd75!important;
}

.btn-send {
    float: right;
}

.error-boundary {
    border: 1px solid red;
}


//Placeholder
::-webkit-input-placeholder {
    color:    #c2c2c2;
}
:-moz-placeholder {
    color:    #c2c2c2;
}
::-moz-placeholder {
    color:    #c2c2c2;
}
:-ms-input-placeholder {
    color:    #c2c2c2;
}


// Navbar form

.navbar-form {
    display: flex;
    order: 4;

    .form-group {
        @extend .mb-0;
        transition: .5s ease-in-out;
        font-size: 1rem;

        &.set-width {
            width: 190px;
        }

        .form-control {
            border: 0;
        }

        @include media-breakpoint-up(lg) {
            width: 0;
            z-index: 1995;
            overflow: hidden;
            position: absolute;
            top: 3px;
            right: 40px;
        }
    }

    .btn {
        background: transparent;
        padding: .65rem .25rem
    }

    @include media-breakpoint-down(md) {
        order: 3;

        .form-group {
            input {
                height: 38px;
            }
        }

        .btn {
            background: white;
            margin: 0px;
            padding: 0 .5rem;
            height: 38px;

            .material-icons {
                position: relative;
                top: 2px;
            }
        }
    }

    @include media-breakpoint-between(sm, md) {
        width: 90%;

        .form-group {
            width: 100%;
        }
    }

    @include media-breakpoint-only(sm) {
        width: 85%;
    }

    @media (max-width: 320px) {
        width: 75%;
    }
}

.disable-click {
    pointer-events: none;
    touch-action: manipulation;
    cursor: not-allowed;
}

.selligent {
    text-align: center;

    .row {
        justify-content: center;
    }

    > p:nth-of-type(1) {
        max-width: 640px;
        margin: auto;
        padding-bottom: 25px;
    }

    .termRow {
        padding-bottom: initial;
    }

    form {
        text-align: initial;

        .termRow {
            padding-bottom: initial !important;
            &:nth-last-child(2) {
                padding-bottom: 25px !important;
            }
        }

        .col-md-8 {
            background-color: #f6f1e6;
            padding: 20px 50px 20px 50px;
            margin-right: 10px;
        }

        input[type="text"] {
            display: block;
            width: 100%!important;
            padding: .5rem .75rem!important;
            font-size: 1.125rem;
            line-height: 1.25;
            color: #464a4c;
            background-color: #fff;
            border: 1px solid rgba(0,0,0,.15);
            border-radius: 0;
            height: 40px !important;
        }

        input[type="submit"] {
            width: initial !important;
            height: initial !important;
        }
    }
}
