.carousel {
  .carousel-control {
    .icon-prev, .icon-next {
      &:before {
        content: "";
      }
    }

    &.left {
      .material-icons {
        color: #fff;
      }
    }
    
    &.right {
      .material-icons {
        color: #fff;
      }
    }
  }
}

// .carousel-item.active,
// .carousel-item-next,
// .carousel-item-prev {
//   display: block !important;
// }


// Nuka

.slider {
    .carousel-caption {
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: auto;
        width: 80%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }

    .slider-slide {
        &:before {
            @include size(100%, 100%);
            @include gradient-y(rgba(0,0,0,.15), rgba(0,0,0,.25));
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            content: '';

            .modal & {
                background: none;
            }
        }
    }

    .slider-decorator-0,
    .slider-decorator-1 {
        button {
            @include size(1px, 1px);
            @extend .p-0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0,0,0,0);
            border: 0;

            &:before {
                position: absolute;
                top: -2.375rem;
                font-family: "Material Icons";
                font-size: 3rem;
                color: #fff;

                .modal & {
                    top: -1rem;
                    font-size: 2rem;
                }
            }
        }
    }

    .slider-decorator-0 {
        button:before {
            left: .3125rem;
            content: "\E5CB";

            .modal & {
                left: .625rem
            }
        }
    }

    .slider-decorator-1 {
        button:before {
            right: .3125rem;
            content: "\E5CC";

            .modal & {
                right: .625rem;
            }
        }
    }

    .slider-decorator-2 {
        ul {
            top: .625rem !important;

            li {
                button {
                    color: #fff !important;
                    font-size: 2rem !important;
                }
            }
        }

        .modal & {
            display: none;
        }
    }
}