.close {
    background: rgba($black, 0);
    top: auto !important;

    span {
        @include size(20px, 20px);
        @include transition(.2s ease-in-out);
        display: block;
        line-height: .5;
    }

    &:hover,
    &:focus {
        span {
            transform: translate(1px, 1px) rotate(90deg);
        }
    }
}