.modal-cs {
    max-width: 650px;
    font-size: 1rem!important;
    .modal-body {
        padding: 50px 100px 0 100px;

        @include media-breakpoint-down(xs) {
            padding: 50px 30px 0 30px !important;
        }
    }

    .btn-absolute {
        @include size(50px, 50px);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
        padding-right: 0;
        padding-left: 0;
        background-color: $booking-close-button;
        border-color: $booking-close-button;
    }

    .form-group {
        &:nth-of-type(odd) {
            background: $modal-odd;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: $h4-font-size;
    }

    h6 {
        @extend .text-uppercase;
    }

    .modal-footer {
        padding: $grid-gutter-width-base;
        border-top: 0;
    }
}

.modal-tc {
    .form-group {
        &:nth-of-type(odd) {
            background: transparent;
        }
    }

    @include media-breakpoint-down(sm) {
        .modal-body {
            padding: 30px 15px !important; 
        }
    }

    @include media-breakpoint-down(xs) {
        h3 {
            width: 75%;
            margin: auto;
        }
    }
}

.modal-open {
    @include media-breakpoint-down(sm) {
        padding-bottom: 74px;
    }
}
