.receipt-holder {
    overflow-x: hidden;
    
    @include media-breakpoint-down(sm) {
        width: 100%;
        position: fixed;
        top: auto;
        bottom: 0;
        z-index: 1040;
        overflow-y: scroll;
        box-shadow: 0 -5px 5px rgba(0,0,0,.15);
        transition: 150ms ease-in-out;
        
        .btn {
            padding: $spacer $card-spacer-x;
        }

        .card {
            margin-bottom: 0;
        }

        &.toggled {
            height: 100%;
            top: 0;
            bottom: auto;
            padding-top: 90px;
        }
    }

    @include media-breakpoint-down(sm) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        &.toggled {
            display: block;
        }

        .card {
          height: 100%;
          // padding-bottom: 54px; // Green total price height
        }

        .total {
          // position: fixed;
          width: 100%;
          bottom: 0;
          background-color: $beige-light;
        }
    }

    @include media-breakpoint-only(sm) {
        width: 540px;

        .btn {
            width: 540px;
        }
    }
}

.btn-specifications {
    .material-icons {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

.toggled {
    .btn-specifications {
        position: fixed;
        top: 0;
        z-index: 10;
    }
}

.share-button {
    float: left;
    margin-right: 5px;

    &:last-child {
        margin: 0px;
    }

    .social-icon {
        @include size(32px, 32px);
        line-height: 32px;
        border-radius: 50%;
        text-align: center;
        display: block;
        cursor: pointer;

        &:hover, &:active {
            text-decoration: none;
        }

        &.email {
            background: #0068C4;
            color: #FFF;
        }

        i {
            position: relative;
            font-size: 18px;
        }

        .material-icons {
            line-height: inherit;
        }
    }
}

.share-buttons {
    content: '';
    display: table;
    clear: both;
}