.text-taupe {
  color: $taupe;
}

.text-brown {
  color: $brown;
}

.text-black {
  color: $black;
}

.text-green {
  color: $green;
}

.text-orange {
  color: $orange;
}

.text-orange-light {
  color: $orange-light;
}

.text-preference-info {
  color: $black !important;
  font-size: 1.125rem;
  font-weight: 700;
}
