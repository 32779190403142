.table-table {
    display: table;
}

.table-table-row {
    display: table-row;
}

.table-table-cell {
    display: table-cell;
}

.table-stretch {
    width: 100%;
}

.table-booking-form {
    .table-table-cell {
        padding-right: ceil(($spacer-x * 0.5));
        &:last-of-type {
            @extend .pr-0;
        }
    }
}

.table-cell-label {
    color: $body-color;
    vertical-align: middle;
}

.table-cell-arrival {
    input:disabled {
        @include size(140px, auto);
        @extend .small;
        border: 0 none;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        font-style: oblique;
    }
}

.table {
    &.table-striped {
        thead {
            th {
                border-color: $table-striped-header-border;
                border-top: 0;
                font-weight: normal;
            }
        }

        tbody {
            tr {
                &:nth-of-type(odd) {
                    background: $table-striped-odd-bg;
                }
            }
        }

        td {
            border: 0;
            border-bottom: 1px solid $table-striped-border;
        }
    }
}

.mpFormTable {
    td {
        border: 0;
    }
}
