@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  width: $track-width;
  height: $track-height;
  cursor: pointer;
  transition: all .2s ease;
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  @supports (-ms-accelerator:true) { margin-top: 0px };
  border: $thumb-border-width solid $thumb-border-color;
  height: $thumb-height;
  width: $thumb-width;
  border-radius: $thumb-radius;
  background: $thumb-color;
  cursor: pointer;
}
