.alert-cookie {
    padding: 2rem 0rem 1.5rem !important;
    background: rgba(0, 0, 0, 0.5);
    text-align: right;

    .container {
        margin: auto;
        align-items: center;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    @include media-breakpoint-down(md) {
        text-align: center;
        padding: 1rem 0rem 1.2rem;
        
        p {
            margin-bottom: 1rem !important;
            margin-right: 0;
            font-size: .875rem;
            width: 100%;
        }
    }
    @include media-breakpoint-down(sm) {
        p {
            margin-right: 0px !important;
        }
    }

    .btn-white {
        border: 2px solid $white !important;
    }

    p {
        @extend .mr-4;
        display: inline-block;
        font-weight: 400;

        a {
            text-decoration: underline;

            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }

    a {
        color: $white;
    }
}
.add-cookiewall {
    display: table;
    margin: auto;
}