.form-control-feedback {
    font-size: $font-size-sm;
}

.form-control-success {
    background-size: 1rem;
    background-position: center right 0.725rem
}

.form-control[readonly] {
    background: #fff;
}

.has-danger {
    .form-check {
      .custom-checkbox {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .form-check-label {
        color: $gray-dark;
    }
}

.terms {
    [class^="col-"] {
        .form-check {
            p {
                margin-bottom: 0;
            }

            &:first-child() {
                margin-bottom: 0;
            }
            
        }
    }
}

.form-check-label {
    text-align: left;
}

.form-check-input {
    @extend .ml-0;

    form & {
        margin-left: -1.25rem !important;
    }
}

.form-check-inline {
    @extend .mb-0;
}

.form-group {
    .paw-radio-group {
        margin-top: 7px;
    }

    .custom-select {
        width: auto;
    }
}

// Rich card check boxes
.booking-form {
    .card {
        &.disabled {
            opacity: .4;

            [type="checkbox"] {
                + span {
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }

        [type="checkbox"] {
            &:checked {
                + span {
                    @include size(auto, auto);
                    display: block;
                    background-color: transparent;
                    border-color: transparent;

                    &:before {
                        position: absolute;
                        top: -16px;
                        left: -25px;
                        font-family: 'Material Icons';
                        font-size: $h1-font-size;
                        content: '\E5CA';
                        color: $brand-success;
                    }
                }
            }

            + span {
                @include size(20px, 20px);
                position: absolute;
                top: 15px;
                right: 15px;
                display: block;
                background-color: $form-rich-check-bg;
                border-width: 2px 0 0 2px;
                border-style: solid;
                border-color: darken($form-rich-check-bg, 10%);
            }
        }

        .selected-sm {
            position: absolute;
            top: 15px;
            right: 15px;
        }

        .selected-lg {
            position: absolute;
            top: 25px;
            right: 25px;
            font-size: 32px;
        }
    }
}


.react-numeric-input {
    @include size(120px, 40px);
    float: right;

    .form-control {
        height: 40px;
        padding: .425rem .75rem;
        border: 0;
        background-color: $form-numeric-bg;
        font-weight: 700;
    }

    b {
        bottom: 0 !important;
        width: 38px !important;
        padding: $spacer;
        top: 0 !important;
        border: 0 !important;
        border-radius: 0 !important;
        background: $form-numeric-btn-bg !important;
        cursor: pointer !important;

        &:nth-child(2) {
            right: 0 !important;
        }

        &:nth-child(3) {
            left: 0 !important;
        }
    }
}

.paw-radio-group {
    .custom-radio {
        @extend .pl-0;
        margin-right: 0;
        margin-left: 1rem;
    }

    .custom-control-indicator {
        @include size(1.25rem, 1.25rem);
        @extend .bg-faded-light;
        position: relative;
        top: .1rem;
        margin-right: .35rem;
        border: 2px solid $custom-control-border-color;
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-indicator {
                background-image: none;
                background-color: #fff;
                border-color: $brand-primary;
                box-shadow: none;

                &:before {
                    @include size(10px, 10px);
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    content: '';
                    border-radius: 50%;
                    background: $brand-primary;
                }
            }
        }
    }
}

form {
    .paw-radio-group {
        .custom-radio {
            margin-right: 1rem;
            margin-left: 0;
        }

        .custom-control-indicator {
            @include size(1rem, 1rem);
            top: .2rem;
            background-color: #fff;
            border: 1px solid #c1c1c1;
        }

        .custom-control-input {
            &:checked {
                ~ .custom-control-indicator {
                    border-color: #c1c1c1;
                    box-shadow: none;

                    &:before {
                        @include size(8px, 8px);
                        background: #000;
                    }
                }
            }
        }
    }
}

input::-webkit-calendar-picker-indicator{
    display: none;
}

input[type="date"]::-webkit-input-placeholder{ 
    visibility: hidden !important;
}

input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

input[type="date"]{
  &.form-control-danger {
    padding-right: 0;
  }
}

.form-check {
  .custom-checkbox {
    display: flex;
    flex-direction: column-reverse;  
  }
}

