.well-message {
    @include pos-t-r(-95px, 0);
    width: 100%;
    padding: $panel-body-padding;
    border: 0;
    box-shadow: none;

    .inner {
        @extend .text-center;
        padding: $padding-base-vertical 0;
        background: fade($black, 50%);
        color: #fff !important;
        font-style: italic;
        font-size: $font-size-lead;
    }

    span {
        color: $secret-deal-color !important;
    }
}