$assets_path : '../../../../assets';

.lang {
   // text-indent: -999em;
    background-repeat: no-repeat;
    background-position: center;
    color: #ab9681;

    @include media-breakpoint-down(sm) {
        @include size(50px, 50px);
    }
}

.lang-nl { @include retina-image($assets_path + '/img/lang/lang-nl.png', $assets_path + '/img/lang/lang-nl.png', 24px, 24px); }
.lang-en { @include retina-image($assets_path + '/img/lang/lang-en.png', $assets_path + '/img/lang/lang-en.png', 24px, 24px); }
.lang-de { @include retina-image($assets_path + '/img/lang/lang-de.png', $assets_path + '/img/lang/lang-de.png', 24px, 24px); }
.lang-fr { @include retina-image($assets_path + '/img/lang/lang-fr.png', $assets_path + '/img/lang/lang-fr.png', 24px, 24px); }

.lang i {
    margin-left:30px;

    @include media-breakpoint-down(md) {
        margin-left:35px;
    }
}

.btn-lang {
    @include size(50px, 40px);
    display: block;
    background: transparent;
    //text-indent: -999em;

    span {
        display: block;
    }

    @include media-breakpoint-down(md) {
        @include size(50px, 40px);
    }

    @include media-breakpoint-down(sm) {
        background: #fff;

        span {
            width: auto;
            height: auto;
        }
    }
}