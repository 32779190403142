.modal {
    .modal-title, .modal-footer {
        p {
            @extend .mb-0;
        }
    }
}

.modal-fit {
    display: table;
    overflow-y: auto;
    overflow-x: auto;
    width: auto;
    max-width: 600px;
    min-width: 300px;

    .modal-body {
        @extend .p-0;
    }

    @include media-breakpoint-down(sm) {
        width: calc(100% - #{$grid-gutter-width-base});
        max-width: none;
        min-width: 0;
        margin: $grid-gutter-width-base * 0.5;
    }
}

.close {
    position: relative;
}

.modal-cover {
    @include size(100%, 100%);
    @extend .m-0;
    position: relative;
    max-width: none;
    background: #fff;

    .modal-close {
      @extend .text-muted;
      left: $spacer;
      top: 0;
      right: auto;

      @include media-breakpoint-down(md) {
        top: $spacer * 0.5;
        left: 0;
      }
    }
}

.modal-clip {
  .modal-body {
    @extend .p-0;
  }
}

// Gallery modal
.modal-gallery {
    margin: 0;

    .modal-header {
        position: absolute;
        right: 0;
        border: 0;
        z-index: 900;

        .close {
            font-weight: 100;
            color: #FFF;
            text-shadow: none;
            opacity: 1;
        }
    }

    .modal-content{
        height: 100%;
        background: #000;
        border: 0;
        border-radius: 0;
    }

    .modal-body {
        padding: 0;

        .video-container {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translate(0, 60%);
        }
    }
}

.modal-body {
  .tabs {
    .tab-content {
      margin-top: 0px !important;
    }
  }
}
