.js-popup-block-inner {
    position: absolute;
    width:  100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.4);
    z-index: 5000;
    display: flex;
    justify-content: center;
    &.non-blocking-warning {
        background: none;
        position: fixed;
        width:  auto;
        height: auto;
        bottom: 0;
        right: 0;
        left: unset;
        top: unset;

        .js-popup-holder {
            padding: 12px;
            margin: 24px;
            margin: 24px;
            margin: 24px;
            width: 100%  !important;
            border: 1px solid white;
            box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.36);

            @media (min-width: 576px) {
                padding: 24px;
                width: 500px!important;
            }

            .title {
                padding: 5px 0 0 0;
            }

            .html-holder {
                padding: 15px 0;
            }

            .btn-lg {
                margin-left: 0;
                margin-bottom: 0;
            }

            a {
                font-weight: bold;
                color: #EA5B1A;
            }
            .btn-close {
                background-color: #AB9580;
                border: none;
                border-radius: 50% !important;
                margin-right: 12px;
                margin-top: 12px;
            }
        }
    }

    .js-popup-holder {
        position: relative;
        margin: auto;
        background-color: #f6f1e6;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.2);
        width: 500px;
        border-radius:5px;

        @media (max-width: 768px) {
            width: 70%;
        }

        @media (max-width: 411px) {
            width: 90%;
        }

        .btn-absolute {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 3px 0 0;
            padding: 5px;
        }

        .btn-lg {
            margin-right: 8px;
        }

        .html-holder {
            padding: 15px;
            margin-top: 10px;
        }

        .title {
            padding: 5px 0 0 15px;
        }
    }
}

.lock {
    overflow-y: hidden;
}
