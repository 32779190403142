// Sticky page sharer (might get deprecated)
.sharer {
    @include pos-t-r(25%, 0);
    z-index: 998;

    &.affixed-parent {
        top: calc(25% - 73px);
    }

    .count {
        @extend .hidden-xs-up;
    }

    .btn {
        @include size(50px, 50px);
        @extend .py-0;
        @extend .mt-0;
        display: block;
        overflow: hidden;
        line-height: 50px;
        font-size: $font-size-lg;
        background: $green;
        color: $white;
        
        &:before {
            margin-left: -2px;
        }

        span {
            display: block;
            line-height: 50px;

            &:before {
                display: block;
            }
        }

        &:hover {
            background-color: #8b956f !important;
            color: $white !important;
        }
    }

    .fa-facebook:before {
        @include fa($fa-var-facebook);
    }

    .fa-twitter:before {
        @include fa($fa-var-twitter);
    }

    .fa-google:before {
        @include fa($fa-var-google-plus);
    }

    .fa-whatsapp:before {
        @include fa($fa-var-whatsapp);
    }

    .fa-mail:before {
        @include fa($fa-var-envelope);
    }

    .fa-share-alt:before {
        @include fa($fa-var-share-alt);
    }

    @include media-breakpoint-down(md) {
        .btn {
            @include size(50px, 50px);
            line-height: 50px;
            font-size: 18px;

            span {
                line-height: 50px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        @include pos-t-r(27%, 0);
        z-index: 998;
        
        .btn {
            @include size(50px, 50px);
            line-height: 50px;
            font-size: $font-size-base;

            span {
                line-height: 50px;
            }
        }
    }
}

