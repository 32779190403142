// BEM method: Listing

// .ListBlock {
//     @extend .card-deck;
//     @extend .row;

//     .card {
//         @extend .mb-3;
//         // @include make-col(4);
//         // @include make-col-ready;

//         // @include media-breakpoint-down(sm) {
//         //     @include make-col(12);
//         // }
//     }

//     .card-block {
//         flex: 1 1 auto;
//     }

// }

.ListBlock--cards-bleed {
    .card {
        border-radius: 0;
        @extend %card-bleed;
    }

    .card-img {
        border-radius: 0;
    }
}

.ListBlock--cards-overlay {
  @extend .row;
  -webkit-box-pack: justify;
  justify-content: space-between;

  .list-item {
    @extend .card;
    width: calc(33.333333% - 15px);
  }
  .card {
    margin-bottom: 0px;
  }
  .card,
  .card-img {
      border-radius: 0
  }

  .card-img-overlay {
    &:before {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.25);
      content: '';
    }

    .card-caption {
      position: relative;
    }
  }

  .card-title a {
    color: $white;
    
    &:hover {
      text-decoration: none;
    }
  }
}

%card-bleed {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
