.Toastify {
  .Toastify__toast-container {
    &.Toastify__toast-container--top-center {
      top: 0em;
      left: 0;
      margin-left: 0;
      width: 100%;
      padding: 0;

      .Toastify__toast {
        min-height: auto;

        &.Toastify__toast--warning {
          background: $alert-warning-bg;
          color: rgba($black, .6) !important;

          .Toastify__toast-body {
            padding: 2px 0;
          }

          .Toastify__close-button--warning {
            display: none;
          }
        }
      }

    }
  }
}

.Toastify__bounce-enter {
  &--top-left,
  &--bottom-left {
    animation-name: none !important;
  }
  &--top-right,
  &--bottom-right {
    animation-name: none !important;
  }
  &--top-center {
    animation-name: none !important;
  }
  &--bottom-center {
    animation-name: none !important;
  }
}

.Toastify__bounce-exit {
  &--top-left,
  &--bottom-left {
    animation-name: none !important;
  }
  &--top-right,
  &--bottom-right {
    animation-name: none !important;
  }
  &--top-center {
    animation-name: none !important;
  }
  &--bottom-center {
    animation-name: none !important;
  }
}
