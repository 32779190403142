.save-booking {
    margin-bottom: 22px;
    border-bottom: 1px solid gainsboro;
    border-top: 1px solid gainsboro;
  
    .h5 {
      padding-top:10px;
    }
  
    .input {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  
    .btn-green {
      background-color: #596831;
      border-color: #596831;
      color: #fff;
      font-family: "sofia-pro", "Century Gothic", sans-serif;
      font-weight: 700;
  
      &.less {
        margin-bottom: 10px;
      }
  
      &.more {
        margin-bottom: 15px;
      }
  
      &:hover {
          background-color: #8b966f;
          border-color: #8b966f;
          color: #fff;
      }
    }
  
    .feedback {
      padding-bottom: 15px;
    }
  }