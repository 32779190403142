.img-enlarge {
    position: relative;
    cursor: pointer;
    transition: .15s ease-in-out;

    &:after {
        @include size(100%, 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        display: block;
        content: '';
        opacity: 0;
        visibility: hidden;
        transition: .15s ease-in-out;
    }

    img {
      width: 100%;
    }

    span {
        z-index: 200;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        visibility: hidden;
        font-size: 4rem;
        color: #fff;
        transition: .15s ease-in-out;
    }

    &:hover {
        &:after, span {
            opacity: 1;
            visibility: visible;
        }
    }
}
