.nav-tabs {
    @extend .mb-0;
    border: 0;

    > li {
        > a {
            z-index: 10;
            border-radius: 0;
            text-decoration: none;
        }
    }
}

.nav-stacked {
    @include make-col(4);
    @extend .px-0;
    flex-wrap: wrap;
    border-color: transparent;

    @include media-breakpoint-down(sm) {
        flex: inherit;
        flex-wrap: nowrap;
        max-width: none;
        -webkit-overflow-scrolling: touch;
        margin-left: -15px;
        margin-right: -15px;

        li:nth-child(1){
            padding-left: 15px;
            padding-right: 0px;
        }
        li {
            padding-left: 15px;
            padding-right: 15px;
        }

        &:after {
            @include size(30px, 58px);
            @include pos-t-r(0, 15px);
            // @include gradient-x(transparent, #fff);
            display: block;
            z-index: 100;
            content: '';
        }
    }

    li {
        @extend .mb-0;
        width: 100%;
        z-index: 10;   

        a {
            display: block;
            padding: $spacer;
            background: #fff;
            border-width: 2px 0 2px 2px !important;
            border-style: solid;
            border-color: transparent !important;

            &.active, &:hover {
                z-index: 100;
                margin-right: -2px;
                border-color: $list-features-border-color !important;
                border-right: 2px solid #fff !important;
                font-weight: $nav-stacked-weight;
            }

            @include media-breakpoint-down(sm) {
                margin: 0 !important;
                border-width: 2px 2px 0 2px !important;

                &.active, &:hover {
                    margin-right: 0;
                    border-right: 2px solid $list-features-border-color !important;
                    border-bottom: 2px solid $white !important;
                }
            }
        }

        &:hover, &:focus {
            a {
                font-weight: $nav-stacked-weight;
            }            
        }
    }

    // @include media-breakpoint-down(md) {
    //     width: auto;
    //     overflow-x: scroll;
    //     overflow-y: hidden;
    //     white-space: nowrap;
    //     text-align: center;
    //     flex: auto;
    //     max-width: 100%;
    //     flex-wrap: nowrap!important;
    // }

    @include media-breakpoint-down(sm) {
        width: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        text-align: center;

        li {
            display: inline-block;

            a {
                margin: 0 0 -1px 0;
            }
        }
    }
}

.img-box {
    position: relative;
    
    .btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: 0 0;
        opacity: 0;
        visibility: hidden;
    }

    &:hover {
        .btn {
            opacity: 1;
            visibility: visible;
        }
    }
}

.tab-content {
    padding: $spacer;
    border: 1px solid transparent;

    .tab-pane {
        // min-height: 206px;

        .list-checked {
            @extend .mb-0;

            li {
                margin-bottom: 5px;
                font-style: oblique;
            
                @include media-breakpoint-only(md) {
                    font-size: 14px;
                }

                @include media-breakpoint-only(sm) {
                    padding-left: 25px;
                    font-size: 12px;
                }
            }
        }
    }
}

.tabs-vertical {
    .tab-content {
        @include make-col(8);
        border: 2px solid $list-features-border-color;
        padding: $grid-gutter-width;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            position: relative;
            margin-top: -2px;
            padding: 15px;
            border: 2px solid $list-features-border-color;
        }
    }
}

.tabs-horizontal {
    .nav-tabs {
        width: 100%;
        // margin-bottom: .5rem !important;
        justify-content: center;
        @extend .text-center;
        
        @include media-breakpoint-down(sm) {
            display: flex;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
            flex-direction: column;
            justify-content: flex-start;
        }

        > li {
            background-color: $tabs-bg !important;
            margin-left: 1rem;

            @include media-breakpoint-down(md) {
                margin-right: 1rem;
            }

            @include media-breakpoint-down(sm) {
                margin-right: 0 !important;
                margin-left: 0 !important;
                margin-bottom: 10px;  
            }
            
            a {
                color: #fff !important;
                font-size: 0.875rem;
                padding: 0.65rem 1rem;
                font-weight: 600;
                cursor: pointer;
                text-transform: uppercase !important;

                &.active {
                    background-color: $tabs-bg-active !important;
                }

                &:hover {
                    font-weight: 600;
                    background-color: $tabs-bg-hover !important;         
                }
            }

            &:nth-child(2) {
                border-left: 0 !important;
                border-right: 0 !important;
            }

            @include media-breakpoint-down(sm) {
                display: block;
            }
        }

        .nav-link {
            border: none;
        }
    }
    .tab-content {
        @include make-col(12);
        padding: $spacer 0;
        border-width: 1px 0 0;
        border-style: solid;
    }
}

.tabs-centered {
    .nav-tabs {
        @extend .text-center;

        li {
            float: none;
            display: inline-block;

            a {
                font-family: $headings-font-family;
                text-decoration: uppercase;

                &:hover {
                    background: transparent;
                }
            }

            &.active {
                a {
                    background: transparent;
                }
            }
        }
    }

    .tab-content {
        border: 0;
        @extend .pl-0;
    }
}

.row-properties {
    .card {
        background-color: transparent !important;
        border: 0 !important;
    }
}
