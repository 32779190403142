.booking-map {
  width: 100%;
  padding: 0px;
  @include media-breakpoint-up(md) {
    padding-top: 0px !important;
  }

  .object-preference-map {
    height: 400px;
  }

  .booking-map-btn {
    background-color: #ae9786;
    padding: 15px 10px;
    margin-top: 15px;
    
    @include media-breakpoint-down(sm) {
      width: 100vw;
      padding: 15px;
      margin-left: -50vw;
      left: 50%;
      text-align: left;
      position: relative;
      background-color: #ae9786;
    }

    .arrow {
      margin-top: -5px;
      @media (max-width: 320px) {
       margin-top: -25px;
      }
    }
  }

  .collapse.show {
    @include media-breakpoint-down(sm) {
      width: 100vw;
      position: relative;
      margin-left: -50vw;
      left: 50%;
    }
  }
  
  .panel {
    border: 1px solid #ccc;
  }

  .panel-collapse.collapse.in {
    display: block;
  }
}

.booking-map-title {
  @include media-breakpoint-down(sm) {
    text-align: center;
    margin: 0 auto;
  }
}

.object-preference-field {
  @include media-breakpoint-up(md) {
    top: 18px;
    padding-right: 0;
  }
  @include media-breakpoint-up(xl) {
    top: 10px;
    padding-right: 0;
  }

  // This breaks the width of the of the receiptwrapper button on mobile
  // select {
  //   width: auto;
  // }

  .form-group {
    width: 100%;
    height: 50px;
    padding-top: 20px;
    @include media-breakpoint-down(sm) {
      width: auto;
    }

    select {
      float: right;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 auto;
      padding: 10px 0px;
    }
  }
}
