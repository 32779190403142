// Compare item
@use "sass:math";
.compare {
    position: relative;
    cursor: pointer;
    margin-bottom: $grid-gutter-width !important;

    .card-media {
        position: relative;

    }
}

// Compare bar
.compare-bar {
    background: #fff;
    box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.05);

    .dropup {
        .dropdown-menu {
            bottom: 62px;
        }
    }
}

.compare-item {
    @include media-breakpoint-up(lg) {
        @include make-col-ready;
        @include make-col(3);
    }

    @include media-breakpoint-down(md) {
        @include make-col-ready;
        @include make-col(4);
    }

    min-height: 95px !important;
    position: relative;
    padding-top: $panel-body-padding;
    padding-bottom: $panel-body-padding;
    border-left: 1px solid $gray-lighter;

    .remove {
        @include pos-t-r(13px, 11px);
        cursor: pointer;

        .material-icons {
            font-size: $font-size-lead;
        }
    }

    &.compare-select {
        order: 5;
        margin-left: auto;

        .dropup {
            height: 100%;
        }

        .btn {
            @extend .text-center;
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
            display: block;

            span {
                transform: translate(0, -2px);
            }
        }

        .dropdown-toggle {
            &:after {
                display: none;
            }
        }
    }
}

.compare-select {
    @extend .p-0;

    .btn {
        margin-top: $grid-gutter-width;
        color: #000;

        span {
            transform: translate(0, math.div($grid-gutter-width, 6));
            margin-right: math.div($grid-gutter-width, 6);
            color: $brand-success !important;
        }

        &:focus {
            text-decoration: none;
        }
    }

    .dropdown-menu {
        bottom: 88px !important;
        max-height: 250px;
        width: 270px;
        overflow-y: scroll;
        padding: 10px 0;
        box-shadow: 0 -3px 10px 3px rgba(0,0,0,.05);
        background: #fff;
        z-index: 999;
        padding: 0 !important;

        &.show {
            display: block;
        }

        li {
            padding: .5rem 1rem;
            cursor: pointer;
            font-size: 1rem;

            &:hover {
                background: $gray-lighter;
            }
        }
    }
}

.compare-image {
    @include media-breakpoint-up(md) {
        @include make-col-ready;
        @include make-col(4);
    }

    @extend .pr-0;
    @extend .hidden-lg-down;
}

.compare-content {
    @include media-breakpoint-up(md) {
        @include make-col-ready;
        @include make-col(8);
    }

    padding-right: $grid-gutter-width !important;

    h3 {
        @extend .my-0;
        font-family: $headings-font-family;
        font-size: 1rem;
        font-weight: 700 !important;
        -webkit-font-smoothing: antialiased;

        a {
            text-decoration: none;
        }
    }
}

.compare-results {
    @include media-breakpoint-up(md) {
        @include make-col-ready;
        @include make-col(9);
    }

    @include media-breakpoint-up(lg) {
        @include make-col-ready;
        @include make-col(10)
    }

    .holder {
        @include make-row;
    }
}

.compare-button {
    @include media-breakpoint-up(md) {
        @include make-col-ready;
        @include make-col(3);
    }

    @include media-breakpoint-up(lg) {
        @include make-col-ready;
        @include make-col(2);
    }

    min-height: 95px;
    padding: $panel-body-padding;
    border-left: 1px solid $gray-lighter;
    border-right: 1px solid $gray-lighter;
    line-height: 90px;

    .btn {
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        margin-top: 0;
    }
}

.alert-compare {
    @include size(100%, auto);
    position: fixed;
    top: 0; // Height affix bar
    left: 0;
    z-index: $zindex-tooltip;

    a {
        color: rgba($black, .6) !important;
        text-decoration: underline;
    }
}

.result-selected {
    @include size(100%, 100%);
    @include pos-t-l;
    display: none;
    cursor: not-allowed;
    background: rgba($white, .75);

    span {
        @include size(50px, 50px);
        @include pos-t-l;
        display: block;
        background: $green;

        &:before {
            @include mi('\E5CA');
            @include pos-c-c;
            z-index: 20;
            color: white;
            font-size: $font-size-h2;
        }
    }

    &.in {
        display: block;
    }

    .holder-sb & {
        @include size(50px, 50px);
        top: 57px;
        left: 15px;

        @include media-breakpoint-only(lg) {
            top: 54px;
        }

        @include media-breakpoint-down(md) {
            top: 44px;
        }
    }
}


/* Compare results */
.compare-resources {

    .card-group {
        @include media-breakpoint-only(md) {
            flex-direction: row !important;
        }
    }

    .card-white {
        background: none;

        @include media-breakpoint-only(md) {
            flex: 0 0 33%;
            max-width: 33%;
        }
    }

    .card-header {
        margin-bottom: 2rem;
        padding: 0 .5rem !important;
        height: 40px;

        h4 {
            font-size: $font-size-h5;

            @include media-breakpoint-down(md) {
                span {
                    display: none;
                }
            }
        }

        .compare-remove {
            position: absolute;
            right: 10px;
            top: 0;
            color: $btn-primary-color;
            font-size: 1.25rem;
            cursor: pointer;
        }
    }

    .card-block {
        background: $white;
        max-height: none !important;

        .paragraph, .pricing {
            font-size: 1rem;
        }

        .date {
            font-size: .9rem;
        }

        .all-in {
            .material-icons {
                color: $orange-yellow;
                font-size: 1rem;
                cursor: pointer;
            }
        }
    }

    .card-media {
        .slick-arrow {
            display: none;
        }

        &:hover {
            .slick-arrow {
                display: block;
            }
        }
    }

    .btn {
        position: relative !important;
        bottom: auto !important;
        margin: 1rem 0;
    }

    .btn-more {
        display: block;
        font-weight: 600;

        &:after {
            left: -2px;
        }
    }

    .date {
        &:before {
            color: #c4b6a7;
        }
    }

    .list-groups {
        h3 {
            @extend .h4;
            @extend .text-left;
            margin: 1.5rem 0rem 0rem;
            padding: .5rem 0rem;
        }

        .list-facilities {
            border-top: 2px solid $beige-light;

            li {
                position: relative;
                padding: .75rem 1rem .75rem 0;
                border-bottom: 2px solid $beige-light;
                font-size: .9rem;
            }


            span {
                @extend .text-center;
                width: 15px;
                position: absolute;
                right: 0;
            }

            .material-icons {
                margin-top: 5px;
                font-size: .9rem;
                color: $btn-primary-color;
                -webkit-font-smoothing: auto;

                &.red {
                    color: $red;
                }
            }
        }
    }

    .card-add {
        margin-bottom: 60px;

        // @include media-breakpoint-down(md) {
        //     display: none;
        // }

        .card-block {
            @extend .p-0;

            a {
                position: relative;
                display: block;
                height: 255px;
                background: $result-body;
                text-align: center;

                @include media-breakpoint-only(lg) {
                    height: 210px;
                }

                @include media-breakpoint-only(md) {
                    height: 210px;
                }

                .btn-add {
                    @include pos-t-l(50%, 50%);
                    width: 100%;
                    transform: translate(-50%,-50%);
                    font-weight: 600;
                    line-height: 28px;
                    color: $text-add;

                    .material-icons {
                        margin-top: 10px;
                        font-size: 48px;
                        color: $btn-primary-color;
                    }
                }
            }
        }

    }
}

#comparable-resources {
    h2 {
        @extend .mb-4;
    }

    .card-group-compare {
        @include media-breakpoint-only(md) {
            flex-direction: row !important;
        }

        .card {
            padding: 0 15px !important;
            flex: 0 0 25%;
            background: $white;
        }

        .card-block {
            @extend .pb-0;
        }
    }

}

#compared-resources {
    &.isIE {
        .price-tag {
            .price-tag {
                top: 40px !important;
            }
        }
    }
    .row-disabled {
        .extra-info {
            display:flex;
            justify-content: center;
            .price-tag.zero-top {
                all: unset
            }
        }
    }
}
