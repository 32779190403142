h1 {
    line-height: 1.1;
}

h2 {
    line-height: 1.125;
}

h3 {
    word-spacing: 0;
    letter-spacing: -.72px;
    line-height: 1.3;
}

h4 {
    word-spacing: 0;
    letter-spacing: 0;
    font-weight: 400;
    letter-spacing: -.72px;
    .headingwhite {
        color: #fff!important;
        a{
            color:#fff!important;
        }
    }
}

h5 {
    letter-spacing: 0;
    font-weight: 400;
    line-height: 1.3;
    word-spacing: 0;
}

p {
    letter-spacing: -.17px;
    word-spacing: -.10px;
    color: rgba($black, .9);

    &.lead {
        letter-spacing: -.17px;
        word-spacing: -.10px;
    }
}

strong, b {
  font-family: $font-family-sans-serif;
  font-weight: $headings-font-weight;
}

section {
    p {
        a:not(.btn) {
            text-decoration: underline;
        }
        //  a:not(.btn-compare) {
        //      text-decoration: underline;
        //  }
    }
}

.jumbotron {
    h1, h2, h3, h4, h5 {
        @extend .anton;
    }
}

.list-checked {
    @extend .pl-0;
    list-style: none;

    .caption &, .usp & {
        @extend .mx-0;
        @extend .px-0;
    }

    > li {
        position: relative;
        padding-left: ($panel-body-padding * 2);

        &:before {
            @include size(22px, 22px);
            @include pos-t-l(4px, 0);
            @include mi('\E5CA');
            @extend .md-18;
            display: block;
            color: $checked-items-color;
            -webkit-font-smoothing: auto;
        }

        a {
            text-decoration: underline;
        }
    }

    footer & {
        > li {
            margin-bottom: 20px;
            padding-left: ($padding-large-horizontal * 2);
            font-size: $font-size-lead;

            &:before {
                @include size(32px, 32px);
                top: -2px;
                font-size: 22px;
            }
        }
    }
}

.list-inline {
    li {
        @extend .list-inline-item;
    }
}

small, .small {
  font-size: 90%;
}

a.btn-primary:not([href]) {
  color: $btn-primary-color;
}

legend {
  font-size: $font-size-base;
}

.lead {
  letter-spacing: 0;
}
