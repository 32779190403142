@use "sass:math";

.price-clean {
    .price-label,
    .price-content {
        display: block;
        white-space: nowrap;
        @extend .text-center;
    }

    .price-label {
        font-size: $font-size-small;
        font-style: italic;
    }

    .price-content {
        font-family: $headings-font-family;
        font-size: $font-size-large;
        @extend .font-weight-bold;
    }
}

.price-tag {
    @include size($price-width-sm, $price-height-sm);
    position: relative;
    padding: 15px;
    // background: $price-bg-image-sm $price-bg-position;
    background-size: $price-bg-size;
    background-color: $price-tag-background;
    -webkit-font-smoothing: antialiased;

    .gallery-right & {
        margin-bottom: $price-tag-spacing;
    }

    .price {
        font-family: $price-font-family;
        font-weight: 800 !important;
        display: block;
        margin: -(math.div($grid-gutter-width, 6)) 0;
        color: $black;

    }

    s {
        display: block;
        margin-top: -(math.div($grid-gutter-width, 6));
        color: $price-strike-color !important;
        font-size: 16px;
        text-align: right;
    }

    .material-icons {
        @include pos-t-r(9px, 7px);
        display: block;
        font-size: $font-size-base;
        cursor: pointer;
    }

    &.compact {
        @extend .mt-0;
        padding-bottom: 15px;

        .price {
            margin: -7px 0 !important;

            .row-disabled & {
                text-align: center !important;
                font-size: 1rem !important;
            }

            @include media-breakpoint-only(lg) {
                margin: -(math.div($grid-gutter-width, 3)) 0;
            }

            @include media-breakpoint-down(md) {
                margin: -8px 0;
            }
        }

        .price-label {
            @extend .mb-0;
            @extend .text-uppercase;
            font-size: $font-size-small;
        }

        .price-span {
            display: block;
            margin-top: -(math.div($grid-gutter-width, 3)) !important;
            margin-bottom: -(math.div($grid-gutter-width, 3)) !important;
        }

        s {
            margin-top: -(math.div($grid-gutter-width, 6));
        }

        .material-icons {
            @include size(12px, 12px);
            @include pos-t-r(-5px, 90px);
            border-radius: 50%;
            text-indent: -2px;
            background: #fff;
            font-size: $font-size-base;
            line-height: 12px;
            color: $orange-yellow;

            @include media-breakpoint-down(sm) {
                left: 90px;
            }
        }
    }
}

.price-label {
    @extend .mb-0;
    display: block;
}

.price-large {
    @include size($price-width, $price-height);
    // background: $price-bg-image $price-bg-position;
    background-color:$yellow-light;
    padding: $price-padding;
    position: static;
    margin-top: $price-margin;

    .price {
        color: $price-color;
    }

    .price-label {
        @extend .text-center;
        @include pos-t-r(-10px, 15px);
        width: 70px;
        display: block;
        color: $price-label-color !important;
        font-size: $font-size-base;
        text-transform: none;

        span {
            top: 0;
            right: -5px;
            color:$orange-yellow !important;
        }
    }
}

.all-in {
    .material-icons {
        cursor: pointer;
    }
}
