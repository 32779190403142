// Alignment
.mrtl, .mltr {
    @include make-row;

    .card-media {
        @include make-col(6);
    }

    .card-block {
        @include make-col(6);
    }
}

.mrtl {
    -webkit-flex-direction: row-reverse; 
    flex-direction: row-reverse;
}

.mltr {
    -webkit-flex-direction: row; 
    flex-direction: row;
}

.mbtt {
    -webkit-flex-direction: column-reverse; 
    flex-direction: column-reverse;
    -webkit-box-orient: vertical
}

.col-wrap {
    max-height: 100%;
    display: flex;
    flex-flow: column wrap;

    .filters & {
        max-height: 150px;
    }
}
