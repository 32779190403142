.popover-share {
    border: 0;
    border-radius: 0;
    background: $green;

    .popover-content {
        .list-social {
            @extend .mb-0;
        }
    }

    &.popover-top, &.bs-tether-element-attached-bottom {
        &:before {
            border-top-color: transparent;
        }
        
        &:after {
            border-top-color: $green;
        }
    }
}

.popover-inner {
    &.popover-share {
        margin: -2px;

        &:before {
            border-top-color: transparent;
        }

        &:after {
            @include size(0, 0);
            position: absolute;
            bottom: -22px;
            left: 50%;
            z-index: 100;
            display: block;
            margin-left: -11px;
            content: "";
            border-width: 11px;
            border-color: transparent;
            border-style: solid;
            border-top-color: $green;
        }

        .popover-content {
            padding: 5px 10px 0;
        }

        svg rect, svg circle {
            fill: transparent;
        }

        .SocialMediaShareButton {
            @include size(36px, 36px);
            overflow: hidden;
            cursor: pointer;

            &:hover {
                g:last-child path {
                    fill: darken($gray-lighter, 10%) !important;
                }

                a {
                    color: darken($gray-lighter, 10%) !important;
                }
            }
        }

        .SocialMediaShareButton--mail {
            a {
                @include size(36px, 36px);
                display: block;
                text-align: center;
                line-height: 36px;
                color: $white;
                text-decoration: none;

                i {
                    position: relative;
                    top: -3px;
                }
            }
        }
    }
}