
@use "sass:math";
$assets_path : '../../assets';

@font-face {
    font-family: 'Anton';
    font-style: normal;
    font-weight: normal;
    src: url($assets_path + '/fonts/anton-regular.eot'); /* For IE6-8 */
    src: local('Anton'),
    local('anton-regular'),
    url($assets_path + '/fonts/anton-regular.woff2') format('woff2'),
    url($assets_path + '/fonts/anton-regular.woff') format('woff'),
    url($assets_path + '/fonts/anton-regular.ttf') format('truetype');
}

// Libema icon font
@font-face {
    font-family: 'Libema';
    font-style: normal;
    font-weight: normal;
    src: url($assets_path + '/fonts/libema.eot'); /* For IE6-8 */
    src: local('libema'),
    local('libema'),
    url($assets_path + '/fonts/libema.woff') format('woff'),
    url($assets_path + '/fonts/libema.ttf') format('truetype');
}

// Animal properties icon font
@font-face {
    font-family: 'Libema Icons';
    font-style: normal;
    font-weight: normal;
    src: url($assets_path + '/fonts/libema-icons.eot'); /* For IE6-8 */
    src: local('Libema Icons'),
    local('Libema Icons'),
    url($assets_path + '/fonts/libema-icons.woff') format('woff'),
    url($assets_path + '/fonts/libema-icons.ttf') format('truetype');
}

@font-face {
    font-family: 'Libema Icons 2';
    font-style: normal;
    font-weight: normal;
    src:
      url($assets_path + '/fonts/libema-icons/Libema-Icons-2.ttf') format('truetype'),
      url($assets_path + '/fonts/libema-icons/Libema-Icons-2.woff') format('woff'),
      url($assets_path + '/fonts/libema-icons/Libema-Icons-2.svg#Libema-Icons-2') format('svg');
}

@font-face {
    font-family: 'Newyse';
    font-style: normal;
    font-weight: normal;
    src: url($assets_path + '/fonts/newyse.eot'); /* For IE6-8 */
    src: local('newyse'),
    local('newyse'),
    // url(/fonts/newyse.woff2) format('woff2'),
    url($assets_path + '/fonts/newyse.woff') format('woff'),
    url($assets_path + '/fonts/newyse.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  src: url('../../assets/fonts/robotocondensed-bold.eot');
  src: url('../../assets/fonts/robotocondensed-bold.woff') format('woff'),
       url('../../assets/fonts/robotocondensed-bold.ttf') format('truetype'),
       url('../../assets/fonts/robotocondensed-bold.svg#robotocondensed-bold') format('svg'),
       url('../../assets/fonts/robotocondensed-bold.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}


//== Google Material Icons
//
//  Copyright 2011 Google Inc. All Rights Reserved.
//
//	Apache License
//	Version 2.0, January 2004
//	http://www.apache.org/licenses/
//
//  Created the font here, because de path reference is incorrect for inserting it via components -> gulp
//  Only a .scss (Sass) file was given to compile with path variable.
//  https://design.google.com/icons/
//===============================================

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url($assets_path + '/fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url($assets_path + '/fonts/MaterialIcons-Regular.woff2') format('woff2'),
    url($assets_path + '/fonts/MaterialIcons-Regular.woff') format('woff'),
    url($assets_path + '/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v22/2fcrYFNaTjcS6g4U3t-Y5UEw0lE80llgEseQY3FEmqw.woff2) format('woff2');
}

@font-face {
    font-family: 'FontAwesome';
    src: url('~font-awesome/fonts/fontawesome-webfont.eot?v=#{$fa-version}');
    src: url('~font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('~font-awesome/fonts/fontawesome-webfont.woff2?v=#{$fa-version}') format('woff2'),
    url('~font-awesome/fonts/fontawesome-webfont.woff?v=#{$fa-version}') format('woff'),
    url('~font-awesome/fonts/fontawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
    url('~font-awesome/fonts/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
    //  src: url('@{fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
    font-weight: normal;
    font-style: normal;
}

.icon {
    font-family: 'libema' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.newyse {
    font-family: 'newyse' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-flex;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.md-18 { font-size: 18px; }
}

// Font requirements
.material-icons {
  @include material-icons;
}

%material-icons {
  @include material-icons;
}

.libema-icons {
    font-family: 'Libema Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
}

.libema-icons-2 {
    font-family: 'Libema Icons 2';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
}

.fa-font {
    display: inline-block;
    font: normal normal normal math.div($fa-font-size-base, $fa-line-height-base) FontAwesome; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin mi($content) {
  // TODO: This was changed to an `include` because of an error: You may not @extend selectors across media queries
  // after moving to nextjs
  @include material-icons;
  content: $content !important;
}

@mixin fa($content) {
    @extend .fa-font;
    content: $content;
}

@mixin icon($content) {
    @extend .icon;
    content: $content;
}

@mixin newyse($content) {
    @extend .newyse;
    content: $content;
}

.anton {
  font-family: $font-family-secondary;
  line-height: 1.25;
  font-weight: $headings-font-weight;
}

h1, h2, h3, h4, h5, h6, strong {
  -webkit-font-smoothing: antialiased;
}

.material-sm-align {
  position: relative;
  top: -1px;
}

.material-md-align {
  position: relative;
  top: -2px;
}

.material-lg-align {
  position: relative;
  top: -3px;
}

//== Icons
// --------------------------------------------------

.ic {
    display: inline-block;
    @include size(36px, 36px);
}

//== Image -> Arrows
// --------------------------------------------------

// .ic-arrow-down {
//     transform: translate(0, 5px);
//     @include mask("url('/img/icons/ic-arrow-down.svg') center center/100% no-repeat", $brand-secondary);
// }
