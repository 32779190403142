body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

h1, h2 {
    font-family: $headings-font-family;
}

h3, h4, h5, h6 {
    font-family: $font-family-sans-serif;
}

.h3 {
  font-size: $h3-font-size;
}

/* Typography for responsive */
@include media-breakpoint-down(sm) {
    h3, .h3 {
        font-size: $h4-font-size;
    }

    .modal {
        h3, .h3 {
            font-size: $h2-font-size;
        }
    }
}

@media (max-width: 374px) {
    h3, .h3 {
        font-size: $h5-font-size;
    }

    .btn-lg {
        font-size: $font-size-base;
    }
}

.text-small {
  font-size: $font-size-sm;
}

.bg-receipt {
    ul {
        &.list-unstyled {
            padding: 0rem 1rem 0rem 1rem;
            margin-bottom: 0;
        }
    }

    .included-wrapper {
        padding: 0rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        ul {
            padding-right:0;
        }
    }
}

.text-lato {
    font-family: $font-family-sans-serif;
}

.text-company {
    &:after {
        content: ',\0020';
    }

    &:last-child {
        &:after {
            content: '';
        }
    }
}

hr {
    border-width: 2px;
    border-color: $hr-color;
}

.list-checked {
    @extend .list-unstyled;

    li {
        position: relative;
        margin-bottom: .2rem;
        padding-left: 1.65rem;

        &:before {
            position: absolute;
            top: 0px;
            left: 0;
            font-family: 'Material Icons';
            font-size: 18px;
            content: '\E5CA';
            color: $list-checked-color;
            -webkit-font-smoothing: auto;

        }
    }
}

a {
  cursor: pointer;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.text-roboto {
  font-family: $headings-font-family;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.font-normal {
  font-weight: normal !important;
}

.list-inline {
  li {
    display: inline-block;
  }
}