#webchat {
  .chat-button {
    position: fixed;
    z-index: 10;
    bottom: 0px;
    right: 0px;
    border-radius: 5px 5px 0 0;
    background-color: #596831;
    border-color: #596831;
    color: #fff;
    padding: 5px;
    width: 280px;
    height: 30px;
    position: fixed;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.6) !important;
  
    i {
      padding-left: 15px;
    }
  
    p {
      color: white;
      text-align: left;
      padding: 0px 15px;
      font-weight: 600;
      font-size: 15px;
    }
    &:hover {
      height: 37px;
    }
  }
  
  .close-button {
    position: fixed;
    right: 3px;
    bottom: 384px;
    background-color: transparent;
    z-index: 99;
    border:none;
  }
  
  
  .chat-window {
    height: 425px;
    width: 325px;
    position: fixed;
    bottom: -5px;
    right: -8px;
    z-index: 20;
    background-color: #fff;
    border: 2px solid #949087;
    background: #eae1c8;
  
    &.with-padding {
      padding: 35px;
    }
    
    .connect-button {
      background-color: #ea5b1a;
      padding: 5px;
      border: none;
      width: 155px;
      color: white;
      font-weight: 700;
      margin-top: 0.5rem;
      height: 32px;
      font-size: 16px;
      font-family: "sofia-pro", "Century Gothic", sans-serif;
    }
  
    .error {
      border: 1px solid red;
    }
  
    .form-group {
      margin-bottom: 0.75rem;
  
      label {
        margin-bottom: 0.2rem;
      }

      input {
        height: 38px;
      }
    }
  }  
}