
@use "sass:math";

.sorting-row {
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
        margin-top: 0 !important;
        margin-bottom: 30px;
    }

    @include media-breakpoint-down(md) {
        padding-left: 0 !important;
    }

    @include media-breakpoint-only(lg) {
        margin-left: 0 !important;
    }

    .select-holder {
        @include media-breakpoint-up(md) {
            @include make-col-ready;
            @include make-col(7);
        }

        @include media-breakpoint-up(xl) {
            @include make-col-ready;
            @include make-col(4);
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            padding: 0 $spacer;

            .custom-select {
                margin-top: $spacer * 0.5;
            }
        }

        .btn {
            margin-right: ($grid-gutter-width * 0.5);

            .preferences-edit {
                &.hidden {
                    display: none;
                }
            }

            .preferences-close {
                &.hidden {
                    display: none;
                }
            }

            &.active {
                @extend .btn-beige;
                border-color: $btn-beige;
            }
        }

        .custom-select,
        .form-control {
            height: 48px !important;
            font-size: 1rem;
            border-color: $btn-beige;

            &:hover,
            &:focus {
                border-color: $beige-dark !important;
            }
        }

        .form-control {
            height: 48px !important;
            font-size: 1rem;
        }

        .btn-outline {
            padding-top: 0;
            padding-bottom: 0;

            .material-icons {
                position: relative;
                top: 3px;
                margin-right: math.div($grid-gutter-width, 6);
            }
        }

        @include media-breakpoint-only(md) {
            .custom-select, .form-control {
                right: 0;
                left: auto;
                height: 38px;
            }
        }

        @include media-breakpoint-down(sm) {
            .btn-filter {
                width: 100%;
                margin-bottom: math.div($grid-gutter-width, 3);
                margin: 0 !important;
            }

            .custom-select, .form-control {
                right: auto;
                left: 0;
                width: 100%;
            }
        }
    }

    .view-holder {
        @include media-breakpoint-down(md) {
            @include make-col-ready;
            @include make-col(5);
        }

        @include media-breakpoint-up(lg) {
            @include make-col-ready;
            @include make-col(5);
        }
        @include media-breakpoint-up(xl) {
            @include make-col-ready;
            @include make-col(8);
        }

        @extend .text-right;
        font-size: 16px;

        .btn {
            i {
                font-size: 24px;
                position: relative;
                top: 1px;
            }
        }
    }
}


// Search results

.search-list {
    @extend .m-b-0;
    @extend .px-0;
    margin-top: $grid-gutter-width !important;
    border-bottom: 2px solid $beige-dark;


    .media {
        display: flex;
        margin-bottom: $grid-gutter-width;
        padding-top: $grid-gutter-width;
        border-top: 2px solid $beige-dark;
        @include media-breakpoint-down(sm) {
            flex-direction: column !important;
        }
    }

    .media-img {
        @include media-breakpoint-up(md) {
            @include make-col(3);
        }
        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
        padding: 0 ($grid-gutter-width * 0.5);
    }

    .media-body {
        @include media-breakpoint-up(md) {
            @include make-col(9);
        }
        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding-top: 5px;
        }
        max-width: 100% !important;
        padding: 0 ($grid-gutter-width * 0.5);

        h2 {
            @extend .m-t-0;
            font-size: 1.25rem;
            color: $black;
        }
    }
}

.search-form {
    max-width: 740px;
    margin: auto;
    @include media-breakpoint-up(xl) {
    padding-bottom: 15px;
    }
    .form-control {
        border: none;
        background-color: $beige-light;
        padding: 18px 0px 18px 20px;
    }
    .btn {
        background-color: $beige-light;
        border: none;
        .fa {
            color: #fff !important;
        }
    }
}

// *** Legend icons ***

// Accommodations
.panel-accommodations {
    dt {
        position: relative;
        margin-bottom: math.div($grid-gutter-width, 6); // 5px
        padding-left: 50px; // Icon width + 7px
        line-height: 35px; // Icon height
    }
}
