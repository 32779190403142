.card {
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
  border-radius: $border-radius;

  .card-block {
    p:last-child {
      @extend .mb-0;
    }
  }

  &.text-xs-center {
    .card-img-overlay {
      padding: ($spacer * 1.25) ($spacer * 2);
    }
  }
}

.card-block {
  .list-group {
    margin: $card-spacer-x (-$card-spacer-x);
  }
}

// .card-title {
//     word-break: break-all;
// }

.card-footer {
  background: none;
}

[class^="card-outline"] {
  border-width: 1px;
}

.card-primary,
.card-success,
.card-info,
.card-warning,
.card-danger {
  @include card-block-inverse;
}

// Listing card column setters
.card-sm {
  .column {
    @extend .col-md-6;
    @extend .col-lg-6;
    @extend .col-xl-3;
  }
}

.card-md {
  .column {
    @extend .col-md-6;
    @extend .col-lg-6;
    @extend .col-xl-4;
  }
}

.card-lg {
  .column {
    @extend .col-md-6;
  }
}

.card-overlay {
  position: relative;
}

.modal-card {
  @extend .modal-lg;

  .modal-body {
    @extend .p-0;
  }
}

// Card image overlay
.card-img-overlay {
  .card-xs-top & {
    bottom: auto;
  }

  .card-xs-middle & {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }

  .card-xs-bottom & {
    top: auto;
    bottom: 0;
  }
}

.card-xs-bottom {
  .card-block {
    position: absolute;
    bottom: 0;
  }
}

// Card styles
.card-img-bg {
  @include card-block-inverse;

  .card-block {
    @extend .card-img-overlay;
    background: rgba(0,0,0,.15);
  }
}

.card-inverse {
  @include card-block-inverse;
}

.card-xs-center {
  @extend .text-xs-center;

  .column {
    float: none;
    display: inline-block;
  }

  .card {
    @extend .text-md-left;
  }
}

.card-transparent {
  .card {
    background: none;
    box-shadow: none;
  }
}
