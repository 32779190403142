
@use "sass:math";

.marker {
    @include size(15px, 15px);
    @extend .text-center;
    position: absolute;
    margin-top: -14px;
    margin-left: -14px;
    cursor: pointer;
    border-radius: 50%;
    background: $taupe;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;

    .marker-id {
        color: $white;
        display: none;
    }

    &.zoom-17 {
        @include size(15px, 15px);
        margin-top: -14px;
        margin-left: -14px;
        display: block;

        .marker-id {
            display: none;
        }
    }

    &.zoom-18 {
        @include size(27px, 27px);
        margin-top: -25px;
        margin-left: -25px;
        display: block;

        .marker-id {
            display: block;
            margin-top: 3px;
            font-size: 14px;
        }
    }

    &.marker-cluster {
        position: absolute;
        display: block;
        width: 122px;
        min-height: 25px;
        height: auto;
        color: $white;
        background-color: #6f6926;
        border: 2px solid $white;
        border-radius: 2px;
        margin-left: -60px;
        margin-top: -26px;
        padding: math.div($panel-body-padding, 3);

        &:after {
            @include retina-image('/img/map/clustermarker-point.png', '/img/map/clustermarker-point@2x.png', 122px, 11px);
            @include pos-b-l(-26px, 50%);
            @include size(122px, 20px);
            content: "";
            display: block;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
        }

        .laurel & {
            background: #4b5e2c;

            &:after {
                @include retina-image('/img/map/clustermarker-point-db.png', '/img/map/clustermarker-point-db@2x.png', 122px, 11px);
            }
        }

        .marker-label {
            margin-top: 1px;
            margin-left: 1px;
            font-size: 13px;
            font-weight: 500;
            color: $white;
        }

        .cluster-background {
            @include size(25px, 25px);
            position: absolute;
            background-color: black;
            color: $white;
            left: -12px;
            top: -12px;
            border-radius: 50%;
        }

        .marker-id {
            font-size: 15px;
            display: block;
        }
    }

    &.marker-unavailable {
        background: darken($gray-lighter, 10%) !important;

        .marker-label {
            color: $gray-light !important;
        }

        &:after {
            @include retina-image('/img/map/clustermarker-point-unavailable.png', '/img/map/clustermarker-point-unavailable@2x.png', 122px, 11px);
            .laurel & { @include retina-image('/img/map/clustermarker-point-unavailable.png', '/img/map/clustermarker-point-unavailable@2x.png', 122px, 11px); }
        }
    }

    &.hidden {
        display: none;
    }
}


// Marker color setters
.marker-default { background: $taupe; }
.marker-taupe { background: $taupe; }
.marker-gray { background: #020200; }
.marker-red { background: #E30613; }
.marker-blue { background: #0092D1; }
.marker-lila { background: #95529B; }
.marker-purple { background: #831F82; }
.marker-mint { background: #055554; }
.marker-orange { background: #D26833; }
.marker-sepia { background: #644E35; }
.marker-army { background: #656a56; }

.marker-db-blue { background: #007999; }
.marker-db-winered { background: #a54242; }
.marker-db-lightpurple { background: #6f47ad; }
.marker-db-orange { background: #a54242; }
.marker-db-darkblue { background: #2c61ad; }
.marker-db-brown { background: #935f25; }
.marker-db-aubergine { background: #6d1545; }
.marker-db-green { background: #2e5100; }
.marker-db-darkred { background: #ad0e25; }
.marker-db-darkpurple { background: #6d1545; }
.marker-db-darkgray { background: #222222; }
.marker-db-gray { background: #445759; }
.marker-db-default { background: $taupe; }
.marker-db-taupe { background: $taupe; }
.marker-db-red { background: #a80000; }
.marker-db-lila { background: #95529B; }
.marker-db-purple { background: #504c9c; }
.marker-db-mint { background: #00845e; }
.marker-db-sepia { background: #644E35; }
.marker-db-army { background: #656a56; }
.marker-db-lightgray { background: #d5d5d5; }
.marker-db-darkgreen { background: #2a471a; }

.icon-resort {
    position: absolute;
    left: 0;

    &:before, &:after {
        @include size(44px, 35px !important);
        @include pos-t-l;
        display: block;
        content: "";
    }

    &:before {
        z-index: 20;
        background-repeat: no-repeat;
    }

    &:after {
        z-index: 10;
        background: url(/img/icons/icon-background.svg);
    }
}

.icon-jungalow-giraf:before { background-image: url(/img/icons/icon-jungalow-giraf.svg); }
.icon-kidsjungalow-giraf:before { background-image: url(/img/icons/icon-kidsjungalow-giraf.svg); }
.icon-jungalow-zebra:before { background-image: url(/img/icons/icon-jungalow-zebra.svg); }
.icon-kidsjungalow-zebra:before { background-image: url(/img/icons/icon-kidsjungalow-zebra.svg); }
.icon-jungalow-luipaard:before { background-image: url(/img/icons/icon-jungalow-luipaard.svg); }
.icon-jungalow-luipaard-plus:before { background-image: url(/img/icons/icon-jungalow-luipaard-plus.svg); }
.icon-chalet:before { background-image: url(/img/icons/icon-chalet.svg); }
.icon-chalet-plus:before { background-image: url(/img/icons/icon-chalet-plus.svg); }
.icon-groepsjungalow:before { background-image: url(/img/icons/icon-groepsjungalow.svg); }
.icon-blokhut:before { background-image: url(/img/icons/icon-blokhut.svg); }
.icon-tent-comfort-plus:before { background-image: url(/img/icons/icon-tent-comfort-plus.svg); }
.icon-tent-comfort:before { background-image: url(/img/icons/icon-tent-comfort.svg); }
.icon-standaardplaatsen:before { background-image: url(/img/icons/icon-standaardplaatsen.svg); }
.icon-plaats-zonder-stroom:before { background-image: url(/img/icons/icon-plaats-zonder-stroom.svg); }
.icon-comfortplaatsen:before { background-image: url(/img/icons/icon-comfortplaatsen.svg); }
.icon-luxe-plaatsen:before { background-image: url(/img/icons/icon-luxe-plaatsen.svg); }
.icon-tent-big-five:before { background-image: url(/img/icons/icon-tent-big-five.svg); }
.icon-tent-kenia:before { background-image: url(/img/icons/icon-tent-kenia.svg); }

.icon-fazant:before { background-image: url(/img/icons/icon-fazant.svg); }
.icon-bungalow-das-4p:before { background-image: url(/img/icons/icon-bungalow-das-4p.svg); }
.icon-bungalow-das-6p:before { background-image: url(/img/icons/icon-bungalow-das-6p.svg); }
.icon-bungalow-das-8p:before { background-image: url(/img/icons/icon-bungalow-das-8p.svg); }
.icon-damhert:before { background-image: url(/img/icons/icon-damhert.svg); }
.icon-damhert-kids:before { background-image: url(/img/icons/icon-damhert-kids.svg); }
.icon-damhert-met-hottub:before { background-image: url(/img/icons/icon-damhert-met-hottub.svg); }
.icon-studio-vlinder:before { background-image: url(/img/icons/icon-studio-vlinder.svg); }
.icon-chalet-eekhoorn:before { background-image: url(/img/icons/icon-chalet-eekhoorn.svg); }
.icon-boomhut-koekoek:before { background-image: url(/img/icons/icon-boomhut-koekoek.svg); }
.icon-villa-alpaca:before { background-image: url(/img/icons/icon-villa-alpaca.svg); }
.icon-luxe-groepsbungalow:before { background-image: url(/img/icons/icon-luxe-groepsbungalow.svg); }
.icon-luxe-lodgetent-pauw:before { background-image: url(/img/icons/icon-luxe-lodgetent-pauw.svg); }
.icon-tent-comfortplus-roodborst:before { background-image: url(/img/icons/icon-tent-comfortplus-roodborst.svg); }
.icon-comfort-kampeerplaats:before { background-image: url(/img/icons/icon-comfort-kampeerplaats.svg); }
.icon-luxe-kampeerplaats:before { background-image: url(/img/icons/icon-luxe-kampeerplaats.svg); }
.icon-vip-kampeerplaats:before { background-image: url(/img/icons/icon-vip-kampeerplaats.svg); }

// Areas
.area {
    @include size(22px, 22px !important);
    @extend .text-center;
    display: inline-block;
    margin-right: math.div($grid-gutter-width, 6);
    border: 2px solid transparent;
    color: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    border-radius: 0 !important;
    background: transparent !important;

    .marker-id {
        @extend .hidden-xs-up;
    }
}

// Beekse Bergen area colors
.area-lightblue { border-color: #7a9fba; color: #7a9fba !important; }
.area-blue { border-color: #2c5185; color: #2c5185 !important }
.area-darkblue { border-color: #013042; color: #013042 !important; }
.area-lightorange { border-color: #dc8606; color: #dc8606 !important; }
.area-orange { border-color: #e84f13; color: #e84f13 !important; }

// Dierenbos area colors
.area-purple { border-color: #641d6b; color: #641d6b !important; }
.area-seablue { border-color: #1d546b; color: #1d546b !important; }
.area-brown { border-color: #6b3b1d; color: #6b3b1d !important; }

.area-1:before { content: "1"; }
.area-2:before { content: "2"; }
.area-3:before { content: "3"; }
.area-4:before { content: "4"; }
.area-5:before { content: "5"; }
.area-6:before { content: "6"; }
.area-7:before { content: "7"; }
.area-8:before { content: "8"; }
.area-9:before { content: "9"; }
.area-10:before { content: "10"; }
.area-11:before { content: "11"; }
.area-12:before { content: "12"; }
.area-13:before { content: "13"; }
.area-14:before { content: "14"; }
.area-15:before { content: "15"; }
.area-16:before { content: "16"; }
.area-17:before { content: "17"; }
.area-18:before { content: "18"; }
.area-19:before { content: "19"; }
.area-20:before { content: "20"; }
.area-21:before { content: "21"; }
.area-22:before { content: "22"; }
.area-23:before { content: "23"; }
.area-24:before { content: "24"; }
.area-25:before { content: "25"; }
.area-26:before { content: "26"; }
.area-27:before { content: "27"; }
.area-28:before { content: "28"; }
.area-29:before { content: "29"; }
.area-30:before { content: "30"; }
.area-31:before { content: "31"; }
.area-32:before { content: "32"; }
.area-33:before { content: "33"; }
.area-34:before { content: "34"; }
.area-35:before { content: "35"; }

.area-vip-kampeerplaatsen { background-image: url(/img/icons/icon-vip-kampeerplaatsen.svg) !important; }
.area-1-huisdier-toegestaan { background-image: url(/img/icons/icon-1-huisdier-toegestaan.svg) !important; }

// Symbols
.panel-symbols {
    dt {
        position: relative;
        margin-bottom: math.div($grid-gutter-width, 3); // 10px
        padding-left: 30px; // Icon width + 7px
        line-height: 21px; // Icon height - 2px
    }
}

.icon-symbol {
    @include size(23px, 23px !important);
    position: absolute;
    left: 0;
    margin-right: math.div($grid-gutter-width, 6);
    background-color: transparent !important;
    background-repeat: no-repeat;

    .marker-id {
        @extend .hidden-xs-up;
    }
}

.icon-junglebad { background-image: url(/img/icons/icon-junglebad.svg); }
.icon-restaurant { background-image: url(/img/icons/icon-restaurant.svg); }
.icon-cafetaria { background-image: url(/img/icons/icon-cafetaria.svg); }
.icon-animatie { background-image: url(/img/icons/icon-animatie.svg); }
.icon-midgetgolf { background-image: url(/img/icons/icon-midgetgolf.svg); }
.icon-verwarmd-sanitairgebouw { background-image: url(/img/icons/icon-verwarmd-sanitairgebouw.svg); }
.icon-sanitairgebouw { background-image: url(/img/icons/icon-sanitairgebouw.svg); }
.icon-toilet-mindervaliden { background-image: url(/img/icons/icon-toilet-mindervaliden.svg); }
.icon-parkeerplaats { background-image: url(/img/icons/icon-parkeerplaats.svg); }
.icon-ion-oplaadpunt { background-image: url(/img/icons/icon-ion-oplaadpunt.svg); }
.icon-supermarkt { background-image: url(/img/icons/icon-supermarkt.svg); }
.icon-milieustraat { background-image: url(/img/icons/icon-milieustraat.svg); }
.icon-wasserette { background-image: url(/img/icons/icon-wasserette.svg); }
.icon-verhuurbalie { background-image: url(/img/icons/icon-verhuurbalie.svg); }
.icon-eurocamp { background-image: url(/img/icons/icon-eurocamp.svg); }
.icon-opstapplaats-treintje { background-image: url(/img/icons/icon-opstapplaats-treintje.svg); }
.icon-safari-shuttle { background-image: url(/img/icons/icon-safari-shuttle.svg); }
.icon-opstapplaats-boot { background-image: url(/img/icons/icon-opstapplaats-boot.svg); }
.icon-hondentoilet { background-image: url(/img/icons/icon-hondentoilet.svg); }
.icon-visstek { background-image: url(/img/icons/icon-visstek.svg); }
.icon-camper { background-image: url(/img/icons/icon-camper.svg); }
.icon-ehbo { background-image: url(/img/icons/icon-ehbo.svg); }
// Added the marker-icon prefix to avoid conflicts with the already existing
// icon-wifi class in features.less
.marker-icon.icon-wifi { background-image: url(/img/icons/icon-wifi.svg); }
.icon-fietsenverhuur { background-image: url(/img/icons/icon-fietsenverhuur.svg); }
.icon-zwembad-overdekt { background-image: url(/img/icons/icon-zwembad-overdekt.svg); }
.icon-bowlingbaan { background-image: url(/img/icons/icon-bowlingbaan.svg); }
.icon-dierenweide { background-image: url(/img/icons/icon-dierenweide.svg); }
.icon-buitenpodium { background-image: url(/img/icons/icon-buitenpodium.svg); }
.icon-jeux-de-boules { background-image: url(/img/icons/icon-jeux-de-boules.svg); }
.icon-kids-club { background-image: url(/img/icons/icon-kids-club.svg); }
.icon-sportveld { background-image: url(/img/icons/icon-sportveld.svg); }

