// Set basic styles
.#{$lib-css-prefix} {
    font-size: $font-size-lg;
}

// Set icons
.#{$lib-css-prefix}-arrow-up:before { content: $lib-arrow-up; }
.#{$lib-css-prefix}-arrow-right:before { content: $lib-arrow-right; }
.#{$lib-css-prefix}-arrow-down:before { content: $lib-arrow-down; }
.#{$lib-css-prefix}-arrow-left:before { content: $lib-arrow-left; }
.#{$lib-css-prefix}-arrow-up-rich:before { content: $lib-arrow-up-rich; }
.#{$lib-css-prefix}-arrow-right-rich:before { content: $lib-arrow-right-rich; }
.#{$lib-css-prefix}-arrow-down-rich:before { content: $lib-arrow-down-rich; }
.#{$lib-css-prefix}-arrow-left-rich:before { content: $lib-arrow-left-rich; }
.#{$lib-css-prefix}-arrow-up-round:before { content: $lib-arrow-up-round; }
.#{$lib-css-prefix}-arrow-right-round:before { content: $lib-arrow-right-round; }
.#{$lib-css-prefix}-arrow-down-round:before { content: $lib-arrow-down-round; }
.#{$lib-css-prefix}-arrow-left-round:before { content: $lib-arrow-left-round; }
.#{$lib-css-prefix}-check:before { content: $lib-check; }
.#{$lib-css-prefix}-leaf:before { content: $lib-leaf; }
.#{$lib-css-prefix}-groenhagen:before { content: $newyse-icon-groenhagen; }
.#{$lib-css-prefix}-oppervlakte:before { content: $newyse-icon-oppervlakte; }
.#{$lib-css-prefix}-sanitair:before { content: $newyse-icon-sanitair; }
.#{$lib-css-prefix}-vrijstaand:before { content: $newyse-icon-vrijstaand; }
.#{$lib-css-prefix}-watertappunt:before { content: $newyse-icon-watertappunt; }