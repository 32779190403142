/**
* Beekse Bergen custom styles file
* All separate styles for: colors, branding, positioning, etc.
**/

.result {
  .text-discount {
    color: $black;
  }
}

.result-mid {
  span {
    color: $orange-yellow;
  }
}

.arrival-date,
.departure-date,
.resorts, 
.travel-company, 
.travel-duration {
    background: $filter-unfocused;
}