.cke_editable {
     padding: 10px !important;

    [class*="hidden"] {
        display: block !important;
    }

    .btn-white {
        @extend .btn-black;
        border-color: rgba(0,0,0,.5) !important;
        color: #000 !important;
    }
}

body.cke_ltr:before {
    background: none !important;
}

// specific classes for hidden sm down
.cke_panel_listItem p.hidden-sm-down {
    display: block!important;
    color: black;
}

.cke_panel_listItem p.hidden-md-down {
    display: block!important;
    color: black;
}