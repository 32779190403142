/* 
** Table of contents
** Use following values for backgrounds in CardBlock
**
** orange
** turqouise
** brown-earth
** green
** green-light
** green-dark
** yellow
** yellow-sun
** taupe
** blue
** beige-dark
** beige
** beige-light
** beige-lighter
** white 
*/

.bg-orange {
    background-color: $background-orange;
}

.bg-orange-light {
    background-color: $background-orange-light;
}

.bg-brown-earth {
    background-color: $background-brown-earth;
}

// .bg-turquoise {
//     background-color: $turquoise;
// }

.bg-green {
    background-color: $background-green;
}

.bg-green-light {
    background-color: $background-green-light;
}

.bg-green-dark {
    background-color: $background-green-dark;
}

.bg-yellow {
    background-color: $background-yellow;
}

.bg-yellow-sun {
    background-color: $background-yellow-light;
}

.bg-taupe {
    background-color: $background-taupe;
}

.bg-taupe-light {
    background-color: $background-taupe-light;
}

.bg-blue {
    background-color: $background-blue;
}

.bg-beige-sand, .bg-beige-dark {
    background-color: $background-beige-dark;
}

.bg-beige {
    background-color: $background-beige;
}

.bg-beige-light {
    background-color: $background-beige-light;
}

.bg-beige-lighter {
    background-color: $background-beige-lighter;
}

.bg-white {
    background-color: $background-white;
}

.bg-red-light {
    background-color: $background-red-light;
}