// Table of Contents
//
// Card square: "card-square"
// Card portrait small: "card-portrait card-sm"
// Card portrait large: "card-portrait card-lg"
// Card landscape small image left: "card-landscape card-sm image-left"
// Card landscape small image right: "card-landscape card-sm image-right"
// Card landscape large image left: "card-landscape card-lg image-left"
// Card landscape large image right: "card-landscape card-lg image-right"
// Card landscape pano image left: "card-landscape card-pano"
// Card landscape pano image right: "card-landscape card-pano pano-right"
// Card overlay: "card-overlay"

// Card settings imports
//
@import "card_alignment";
@import "card_backgrounds";
@import "card_group";
// @import "card_themes";

.card {
    .card-header {
        @extend .p-0;
        border: 0;
        background: transparent;
    }

    .card-block {
        h4 {
            @extend .card-title;
        }

        p:last-child {
            @extend .mb-0;
        }
    }

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    .card-block.price-list {
        max-height: 365px;
        overflow: hidden;

        @include media-breakpoint-only(xl) { max-height: 380px !important }
        @include media-breakpoint-only(lg) { max-height: 380px  !important }
        @include media-breakpoint-only(md) { max-height: none  !important }

        .tab-content & {
            @include media-breakpoint-only(xl) { max-height: 325px }
        } 
    }
}

.card-media {
    picture, source, img {
        @extend .d-block;
        width: 100%;
    }
}

.gallery-image { 
    img {
        @extend .d-block;
        width: 100%;
    }
}

.card-square {
    @include make-col(6);
    @extend .card-inverse;

    .card-block {
        @extend .card-img-overlay;
        position: absolute !important;
    }

    @include media-breakpoint-down(md) {
        @include make-col(12);
    }
}

.card-portrait {
    &.card-sm {
        @include make-col(3);

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }

    &.card-lg {
        @include make-col(4);

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
}

.card-landscape {
    &.image-left {
        @extend .mltr;
    }

    &.image-right {
        @extend .mrtl;
    }

    &.card-sm {
        .card-media {
            @include make-col(5);
        }

        .card-block {
            @include make-col(7);
        }
    }

    &.card-lg {
        .card-media, .card-block {
            @include make-col(6);
        }
    }

    &.card-pano {
        .card-media {
            @include make-col(12);
        }

        .card-block {
            @include make-col(4);
            @include pos-t-r;
            height: 100%;
        }
    }
}

.card-overlay {
    @extend .card-inverse;

    .card-block {
        @extend .card-img-overlay;
        position: absolute !important;
    }
}

.card-overlay {
    .card-block {
        @include size(100%, 100%);
        @include pos-t-l;
    }
}

.no-image {
    .card-media {
        @extend .hidden-xs-up;
    }
}

// Group classes
.flex-off {
    flex: 0;
    -webkit-flex: 0;
}

.flex-on {
    flex: 1;
    -webkit-flex: 1;
}

.row-reverse {
    -webkit-flex-direction: row-reverse; 
    flex-direction: row-reverse;
}

// Highlight animal icons
.card-media {
    .animal-highlights {
        z-index: 0;
    }    
}
.animal-highlights {
    @extend .list-inline;
    position:absolute;
    z-index: 100;

    li {
        @include size(50px, 50px);
        @extend .m-0;
        @extend .p-0;
        position: relative;

        &:before {
            @extend .libema-icons;
            position: absolute;
            top: 50%;
            left: 50%;
            bottom: auto;
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            font-size: 2.2rem;
            color: #fff;

        }

        &.big_five {
            background: $yellow-light;

            &:before {
                content: '\E904';
                color: $black;
            }
        }

        &.birth {
            background: $red-light;
            color: $white;

            &:before {
                content: '\E906';
            }
        }

        &.new_species {
            background: $green-light;
            color: $white;

            &:before {
                content: '\E90a';
            }
        }
    }
}

.animal-property {
    margin-bottom: 1.5rem;
    position: relative;

    @include media-breakpoint-only(lg) {
      font-size: 1rem;
    }

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    @include media-breakpoint-only(md) {
      display: flex;
      flex-direction: column;
    }

    @include media-breakpoint-only(lg) {
      margin-bottom: .5rem !important;
    }

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }

    /** The icon class is used for the old icons which are loaded from a font. New icons should just be rendered as an svg */
    .icon {
      &:before {
        @extend .libema-icons;
        color: $black;
        font-size: 3rem;

        @include media-breakpoint-down(lg) {
          font-size: 2.5rem;
        }
      }
    }

    .icon-area {
        &:before {
            content: '\E908';
        }
    }

    .icon-nutrition {
        &:before {
            content: '\E90c';
        }
    }

    .icon-age {
        &:before {
            content: '\E90b';
        }
    }

    .icon-weight {
        &:before {
            content: '\E907';
        }
    }

    .icon-offspring {
        &:before {
            content: '\E909';
        }
    }

    .icon-bearing {
        &:before {
            content: '\E905';
        }
    }

    .icon-see {
        &:before {
            content: '\E90d';
        }
    }

    .icon-guide {
        &:before {
          content: '\E913';
        }
    }

    .icon-wet {
        &:before {
          content: '\E914';
        }
    }

    .icon-length {
        &:before {
          content: '\E915';
        }
    }

    .icon-family {
        &:before {
          content: '\E916';
        }
    }

    .icon-check {
        &:before {
          content: '\E917';
        }
    }

    .icon-shoe {
        &:before {
          content: '\E918';
        }
    }

    .icon-pay {
        &:before {
          content: '\E919';
        }
    }

    .icon-iucn {
      &:before {
        content: '\E900';
      }
    }

    .icon-iucn-2 {
      &:before {
        content: '\E901';
      }
    }

    .icon-eep {
      &:before {
        content: '\E902';
      }
    }

    .icon-broeden {
      &:before {
        content: '\E903';
      }
    }

    .icon-gathering-place:before { @extend .libema-icons-2; content: "\e900"; }
    .icon-start-time:before { @extend .libema-icons-2; content: "\e901"; }
    .icon-cost:before { @extend .libema-icons-2; content: "\e902"; }
    .icon-calendar:before { @extend .libema-icons-2; content: "\e903"; }
    .icon-group-size:before { @extend .libema-icons-2; content: "\e904"; }
    .icon-expensive:before { @extend .libema-icons-2; content: "\e905"; }

    .property-name {
      font-weight: 600;
    }
}

.card-usp {
    li {
        font-style: unset !important;
        color: rgba(0,0,0,.9);
        letter-spacing: -.17px;
        font-size: 17px;
    }
}

.hr-usp {
    margin-top: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}