// Search and Book map

@use "sass:math";

.sebm-google-map-container {
    display: block;
    height: 100%;
}

.section-map {
    @extend .pb-0;

    .section-content {
        &.container-fluid {
            @extend .px-0;
        }
    }
}

.marker-tooltip {
    width: 280px !important;
    height: 50px;
    position: relative;
    background: $infowindow-bg;
    border: 3px solid $white;
    border-radius: 3px;
    font-size: 14px;
    margin-left: -12px;
    text-align: center;

    img {
        @extend .hidden-xs-up;
    }

    .tooltip-id {
        @include pos-t-l(9px, 15px);
        color: $white;
        font-size: 18px;
        font-weight: 700;
        font-family: $font-family-base;
    }

    .tooltip-data {
        position: relative;
        top: 12px;
        color: $white;
        font-family: $font-family-base;
    }

    &:after {
        @include retina-image('/img/map/tooltip-point.png', '/img/map/tooltip-point@2x.png', 64px, 21px);
        @include pos-b-l(-26px, 50%);
        transform: translate(-50%, -50%);
        content: "";
        display: block;
        width: 70px;
        height: 22px;
        background-repeat: no-repeat;

        .laurel & {
            @include retina-image('/img/map/tooltip-point-db.png', '/img/map/tooltip-point@2x-db.png', 64px, 21px);
        }
    }
}

.infoBox {
    > img {
        @extend .hidden-xs-up;
    }
}

.gm-style {
    & img {
        max-width: 100%;
    }
}

.infobox {
    width: 310px;
    margin-left: -24px;
    padding: $panel-body-padding;
    position: relative;
    background: $poiwindow-bg;
    border: 3px solid $white;
    font-size: 14px;
    border-radius: 3px;

    h3 {
        @extend .mt-0;
        font-size: $font-size-h4;
    }

    p {
        @extend .mb-0;
        font-family: $font-family-base;
        font-size: 14px;
        font-style: italic;
    }

    &:after {
        @include retina-image('/img/map/tooltip-poi.png', '/img/map/tooltip-poi@2x.png', 64px, 21px);
        @include pos-b-l(-26px, 50%);
        transform: translate(-50%, -50%);
        content: "";
        display: block;
        width: 70px;
        height: 22px;
        background-repeat: no-repeat;

        .laurel & {
            @include retina-image('/img/map/tooltip-poi-db.png', '/img/map/tooltip-poi@2x-db.png', 64px, 21px);
        }
    }
}

// Search and Book map

.book-map {
    @extend .p-0;

    .holder-sb {
        height: 100%;
    }

    .section-content {
        @include size(auto, 100%);
        @extend .container-fluid;
        padding: 0 $panel-body-padding;
    }

    .sorting-row {
        @include pos-t-r(15px, 0px);
        width: auto;
        margin: 0;
        z-index: 100;

        .row {
            @extend .mb-0;
        }

        .select-holder {
            @extend .hidden-xs-up;
        }

        .view-holder {
            width: auto;
            color: $white;
        }
    }

    .btn-outline {
        background: $white;
        border: 0;
    }

    .filters {
        @include pos-t-l(15px, 0px);
        width: 315px;
        z-index: 100;
        padding: 0;
        overflow-y: scroll;
        background: $white;

        @include media-breakpoint-only(lg) {
            width: 275px;
        }

        label {
            font-size: 14px;
        }

        // Filter tabs
        .tab-content {
            background: $white;

            .tab-pane {
                padding: ($panel-body-padding * 0.5) 0;
            }

            .tab-pane-legend {
                .panel {
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    padding-bottom: 15px;

                    &.panel-symbols {
                        border: 0;
                        padding-bottom: 0;
                    }
                }

                .panel-heading {
                    background: transparent;
                }

                .panel-title {
                    margin-bottom: math.div($grid-gutter-width, 3) !important;

                    a {
                        position: relative;
                        display: block;

                        &:after {
                            @include pos-t-r(-4px, -5px);
                            @include mi('\E316');
                        }

                        &.collapsed {
                            &:after {
                                @include pos-t-r(-4px, -5px);
                                @include mi('\E313');
                            }
                        }
                    }
                }

                dl {
                    margin-bottom: 0;
                }
            }
        }

        .nav-tabs {
            > li {
                width: 50%;

                &.active {
                    > a {
                        background: $white;
                        color: $filter-tabs-color;

                        &:hover {
                            background: $white;
                            color: $filter-tabs-color;
                        }
                    }
                }

                > a {
                    @extend .text-center;
                    margin: 0;
                    border: 0;
                    text-transform: $filter-tabs-text-transform;
                    background: $filter-tabs-bg;
                    color: $white;
                    font-family: $headings-font-family;
                    font-weight: $headings-font-weight;
                    font-size: 18px;
                    -webkit-font-smoothing: antialiased;

                    &:hover {
                        background: darken($filter-tabs-bg, 5%);
                        color: $white;
                    }
                }
            }
        }

        // Slider (price slider)
        .price-slider {
            padding: 0 $panel-body-padding !important;
        }
    }

    .form-dropdown {
        @extend .mb-0;
        padding: 0 $panel-body-padding;

        &:after {
            @include size(100%, 1px);
            display: block;
            margin: 15px 0;
            background: $gray-lighter;
            content: "";
        }
    }

    .dropdown-subjects {
        width: 270px;
        min-width: 0 !important;
    }

    subjects-picker {
        .form-dropdown {
            border-bottom: 0;
        }
    }

    .panel-group {
        padding: 0 $panel-body-padding;
    }

    .panel-title {
        font-size: $font-size-base;
        margin-top: 20px !important;
    }

    .datepicker-inline {
        width: 210px;
    }
}

.map {
    @extend .p-0;
    width: 100%;
}

.title-booking-map {
  font-size: 0.9rem;
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}

/* Styles taken from leaflet.fullscreen */
.fullscreen-icon { background-image: url(icon-fullscreen.png); }
.leaflet-retina .fullscreen-icon { background-image: url(icon-fullscreen-2x.png); background-size: 26px 26px; }
.leaflet-container {
  width: 100%;
}
/* one selector per rule as explained here : http://www.sitepoint.com/html5-full-screen-api/ */
.leaflet-container:-webkit-full-screen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-container:-ms-fullscreen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-container:full-screen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-container:fullscreen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-pseudo-fullscreen { position: fixed !important; width: 100% !important; height: 100% !important; top: 0px !important; left: 0px !important; z-index: 99999; }
