.label-vpbb,
.label-srbb {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 20px 15px 62px;
    font-size: 1.25rem;

    i {
      position: absolute;
      top: 10px;
      left: 15px;
      font-size: 36px;
    }

    @include media-breakpoint-only(md) {
        padding: 10px 15px 10px 55px;
        i {
            top: 6px;
            font-size: 30px;
        }

        .gallery-holder & {
            padding: 15px 20px 15px 62px;

            i {
              position: absolute;
              top: 10px;
              left: 15px;
              font-size: 36px;
            }
        }
    }
}
