// Ranqe slider

$track-color: #fff;
$thumb-color: $brand-primary;

$thumb-radius: 50%;
$thumb-height: 15px;
$thumb-width: 15px;
$thumb-shadow-size: 0;
$thumb-shadow-blur: 0;
$thumb-shadow-color: transparent;
$thumb-border-width: 0;
$thumb-border-color: $brand-primary;

$track-width: 100% !default;
$track-height: 5px;
$track-shadow-size: 0;
$track-shadow-blur: 0;
$track-shadow-color: transparent;
$track-border-width: 0;
$track-border-color: transparent;

$track-radius: 5px !default;
$contrast: 5% !default;

[type=range] {
  -webkit-appearance: none;
  margin: $thumb-height * 0.5 0;
  width: $track-width;
  padding: 1.5rem 1.5rem;
  background: transparent;
  box-shadow: none !important;
  padding: .5rem 0;

  &:focus {
    background: transparent;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding: 0.75rem 1.5rem;
  }
  @supports (-ms-accelerator:true) {
    padding: 0.75rem 1.5rem;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    @include track();
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb();
    -webkit-appearance: none;
    margin-top: ((-$track-border-width * 2 + $track-height) * 0.5) - ($thumb-height * 0.5);
  }

  &:focus::-webkit-slider-runnable-track {
    background: lighten($track-color, $contrast);
  }

  &::-moz-range-track {
    @include track();
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
  }

  &::-moz-range-thumb {
    @include thumb();
  }

  &::-ms-track {
    @include track();
    background: transparent;
    border-color: transparent;
    border-width: $thumb-width 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: darken($track-color, $contrast);
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius * 2;
  }

  &::-ms-fill-upper {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius * 2;
  }

  &::-ms-thumb {
    @include thumb();
  }

  &:focus::-ms-fill-lower {
    background: $track-color;
  }

  &:focus::-ms-fill-upper {
    background: lighten($track-color, $contrast);
  }
}

.has-danger {
  .form-control-danger {
    &[type=range] {
      background-image: none;

      &::-webkit-slider-runnable-track {
        background: $brand-danger;
      }

      &:focus::-webkit-slider-runnable-track {
        background: lighten($brand-danger, 5%);
      }

      &::-moz-range-track {
        background: $brand-danger;
      }

      &::-ms-fill-lower {
        background: darken($brand-danger, 10%);
      }

      &::-ms-fill-upper {
        background: $brand-danger;
      }
    }
  }
}
