.media {
    display: block;

    > .media-item {
        margin: ($grid-gutter-width * 0.5) 0;
        position: relative;

        @include media-breakpoint-down(sm) {
            margin-bottom: $grid-gutter-width;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }

    a {
        text-decoration: none;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: $grid-gutter-width;
    }
}

.media-left {
    @include make-col-ready;
    @include make-col(3);

    @include media-breakpoint-down(sm) {
        padding-right: 0 !important;
    }
}

.media-middle {
    @include make-col-ready;
    @include make-col(6);
    padding-left: 0 !important;

    @include media-breakpoint-down(sm) {
        padding-left: $grid-gutter-width * 0.5 !important;
    }
}

.media-right {
    @include make-col-ready;
    @include make-col(3);
    @extend .p-t-md;

    @include media-breakpoint-only(md) {
        padding-right: 0 !important;
        padding-left: 0 !important;

        .price-label {
            text-align: left !important;
        }

        .price-content {
            text-align: left !important;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 0 !important;
        padding-left: 0 !important;

        .price-label {
            white-space: normal !important;

        }
    }
}

.media-push {
    @include make-col(9);
}


.media-body {
    @extend .text-left;

    @include media-breakpoint-down(sm) {
        width: auto;
        display: block;
        padding-left: $panel-body-padding;
    }
}

.media-lg {
    .media-object {
        width: $media-object-width-lg;
    }
}
.media-md {
    .media-object {
        width: $media-object-width-md;
    }
}
.media-sm {
    .media-object {
        width: $media-object-width-sm;
    }

    .media-content {
        height: 55px;
        overflow: hidden;
    }
}

.media-heading {
    @extend .my-0;
    font-family: $font-family-base;
    margin-bottom: 5px !important;

    @include media-breakpoint-only(md) {
        width: 85%;
        word-wrap: break-word;
    }
}

.slider-testimonial {
    .media {
        display: inline-block;
    }

    .media-left {
        @include size(90px, 90px !important);
        @extend .rounded;
    }

    .media-label {
        color: $body-color !important;
    }
}

// See https://github.com/aFarkas/lazysizes#tip-specifying-image-dimensions-minimizing-reflows-and-avoiding-page-jumps
.lazyload,
.lazyloading {
	min-height: 200px;
}
