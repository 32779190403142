
@use "sass:math";

.accordion {
    @extend .mt-3;
    @extend .mb-5;

    &.panel-group {
        position: relative;
        margin-top: $spacer;
        margin-bottom: (math.div($grid-gutter-width, 3) * 4);


        .panel-heading {
            + .panel-collapse {
                > .panel-body {
                    border-top: 2px solid $accordion-border-color;
                }
            }
        }
    }

    .panel {
        box-shadow: none;
        border: 0;
        border-top: 2px solid $accordion-border-color;

        &:first-child {
            border-bottom: 2px solid $accordion-border-color !important;
            border-color: $accordion-border-color !important;
        }

        + .panel {
            @extend .mt-0;
            border-top: 0;
            border-bottom: 2px solid $accordion-border-color;
        }
    }

    .panel-heading {
        padding: 0;
    }

    .panel-title {
        @extend .mt-0;
        font-size: $font-size-h5;
        cursor: pointer;

        a {
            position: relative;
            display: block;
            padding: 15px 20px;
            text-decoration: none;
            font-weight: bold;
            color: $accordion-title;

            &:after {
                @include mi("\E316"); //.mi("keyboard_arrow_up");
                @include pos-t-r(15px, 20px);
                color: $accordion-arrow;
                font-size: 30px;
            }
        }

        .collapsed {
            color: $accordion-title-collapsed;
            font-weight: normal;

            &:after {
                @include mi("\E313"); //.mi("keyboard_arrow_down");
                color: $accordion-arrow-collapsed;
                font-size: 30px;
            }
        }
    }

    .panel-body {
        padding: 0 $padding-large-horizontal ($padding-large-vertical * 2);
        border: 0 !important;

        p {
            &:last-child {
                @extend .mb-0;
            }
        }

        a {
            color: $brand-primary;
        }
    }
}

.panel {
    box-shadow: none;
    border: 0;

    .panel-heading {
        @extend .pt-0;
    }
}
