//
// Bootstrap 3 variables
//

@use "sass:math";

$grid-gutter-width:                             $grid-gutter-width-base;

$panel-body-padding:                            15px;

$padding-base-vertical:                         6px;
$padding-base-horizontal:                       12px;
$padding-large-vertical:                        10px;
$padding-large-horizontal:                      20px;
$padding-small-vertical:                        3px;
$padding-small-horizontal:                      6px;

$border-radius-base:                            0;
$border-color:                                  #bbbbaf;

$font-size-small:                               $font-size-sm;
$font-size-large:                               $font-size-lg;
$font-size-lead:                                19px;

$btn-font-size:                                 $font-size-lead;

$btn-floating-bg:                               #fff;
$btn-floating-icon:                             $brand-secondary;
$btn-floating-hover-bg:                         $btn-floating-bg;
$btn-floating-hover-icon:                       darken($brand-secondary, 10%);

$btn-socialmedia-color:                         #6eaeb8;
$btn-socialmedia-bg:                            #364e58;
$btn-socialmedia-border:                        darken($btn-socialmedia-bg, 5%);
$btn-socialmedia-border-radius:                 50%;

$input-height-base:                             42px;                                  //** Default `.form-control` height
$input-height-large:                            ceil(($input-height-base * 1.5));      //** Large `.form-control` height
$input-height-small:                            ceil(($input-height-base * 0.666));    //** Small `.form-control` height

$feature-color:                                 #fff;
$feature-icon-color:                            $brand-secondary;

//
// Custom variables
//

$spacer-ratio-sm:                               0.333;
$spacer-ratio-md:                               0.5;
$spacer-ratio-lg:                               2;

$zindex-content:                                1;
$zindex-image:                                  100;
$zindex-jumbotron:                              1000;
$zindex-floating-btn:                           1000;
$zindex-navbar:                                 2000;
$zindex-navbar-fixed:                           2100;
$zindex-modal-background:                       3000;
$zindex-modal:                                  3100;

$nav-stacked-weight:                            700;
$nav-stacked-active-color:                      $body-color;

// Set prefix
$lib-css-prefix:                                lib;

// Set font
$lib-arrow-up:                                  "\e90b";
$lib-arrow-right:                               "\e908";
$lib-arrow-down:                                "\e902";
$lib-arrow-left:                                "\e905";
$lib-arrow-up-rich:                             "\e909";
$lib-arrow-right-rich:                          "\e906";
$lib-arrow-down-rich:                           "\e900";
$lib-arrow-left-rich:                           "\e903";
$lib-arrow-up-round:                            "\e90a";
$lib-arrow-right-round:                         "\e907";
$lib-arrow-down-round:                          "\e901";
$lib-arrow-left-round:                          "\e904";
$lib-check:                                     "\e90c";
$lib-leaf:                                      "\e90d";

$newyse-icon-groenhagen:                        "\f900";
$newyse-icon-oppervlakte:                       "\f901";
$newyse-icon-sanitair:                          "\f902";
$newyse-icon-vrijstaand:                        "\f903";
$newyse-icon-watertappunt:                      "\f904";

$slider-font-family:                            inherit;
$slider-loader-path:                            "/img";
$slider-border-radius:                          $border-radius-base;
$slider-prices-height:                          120px;
$slider-prices-heading-color:                   $gray;
$slider-prices-bg:                              $gray-light;
$slider-prices-color:                           #fff;
$slider-prices-lowest-price-bg:                 darken($slider-prices-bg, 15%);
$slider-prices-lowest-price-color:              #fff;
$slider-prices-hover-bg:                        darken($slider-prices-bg, 20%);
$slider-prices-hover-color:                     #fff;
$slider-prices-font-size:                       $h2-font-size;
$slider-arrow-color:                            $brand-primary;
$slider-dot-color:                              $brand-primary;
$slider-dot-color-active:                       #fff;
$slider-dot-size:                               20px;
$slider-prev-character:                         $lib-arrow-left-rich;
$slider-next-character:                         $lib-arrow-right-rich;
$slider-dot-character:                          "•"; //** Default: "•";
$slider-opacity-default:                        1;
$slider-opacity-on-hover:                       1;
$slider-opacity-not-active:                     1;

$gallery-height-sm:                             100px;
$gallery-height-md:                             260px;
$gallery-height-lg:                             460px;
$gallery-body-bg:                               #faf5e8;
$gallery-body-color:                            $body-color;
$gallery-border-color:                          #e8dcbd;
$gallery-footer-color:                          $brand-primary;
$gallery-footer-icon-color:                     $brand-primary;
$gallery-loading-color:                         $body-color;

$form-dropdown-bg:                              #fff;
$form-dropdown-color:                           $brand-tertiary;
$form-dropdown-icon-color:                      $brand-primary;
$form-dropdown-label-color:                     $body-color;
$form-dropdown-label-color-light:               $body-color;
$form-dropdown-border:                          $border-color;
$form-dropdown-transform:                       uppercase;
$form-dropdown-input-height:                    40px;
$form-dropdown-min-width:                       300px;
$form-dropdown-font-size:                       $font-size-base;

$result-top-offset:                             ($padding-large-vertical * 2);
$result-inner-top-offset:                       ($panel-body-padding * 2);
$result-price-top-offset:                       0;
$result-feature-spacing:                        math.div($grid-gutter-width, 6);
$result-secret-deal:                            "/img/secret.png";
$result-secret-deal2x:                          "/img/secret@2x.png";

$result-date-spacing:               8px 0;
$result-btn-spacing:                $result-date-spacing;

//== Price
// --------------------------------------------------

$price-bg:                  $brand-tertiary;
$price-bg-image:            url('/img/price.svg') no-repeat;
$price-bg-image-full:       url('/img/price-full.png') no-repeat;
$price-bg-position:         left bottom;
$price-bg-position-full:    bottom;
$price-btn-bg:              inherit;
$price-color:               $white;
$price-full-color:          $price-color;
$price-grid-full-color:     $price-color;
$price-grid-padding:        5px 0;
$price-grid-full-padding:   $price-grid-padding;
$price-strike-color:        fade($body-color, 50%);
$price-strike-size:         $font-size-base;
$price-label-color:         $brand-tertiary;
$price-rule-bg:             $body-color;
//$price-font-size:           $font-size-h2;
$price-font-size:           23px;
$price-font-size-sm:        32px;
$price-font-size-grid:      $price-font-size;
$price-font-size-full:      $price-font-size;
$price-font-family:         $font-family-sans-serif;
$price-width:               115px;
$price-height:              auto;
$price-margin:              -$grid-gutter-width;
$price-padding:             $padding-large-vertical $panel-body-padding;
$price-tag-spacing:         $grid-gutter-width; // 30px
$price-tag-spacing-full:    ($padding-large-vertical * 2) 0; // 30px 0 30px 0
$price-alignment-full:      center;
$price-tag-pos-y:           0;
$price-tag-pos-x:           15px;
$price-position:            -8px 0;
$price-tag-margin-top:      -15px;

$price-bg-image-sm:         $price-bg-image;
$price-bg-size:             100%;
$price-bg-size-sm:          80%;
$price-bg-pos:              center bottom;
$price-bg-pos-affix:        $price-bg-position;
$price-width-sm:            $price-width;
$price-height-sm:           $price-height;
$price-width-affix:         144px;
$price-height-affix:        62px;
$price-padding-sm:          $price-padding;
$price-color-sm:            $price-color;
$price-text-discount:       $brand-primary;

//== Secret deal
//
// Define secret deal properties.
// --------------------------------------------------

$secret-modal-banner:           '/img/secret-deal-modal.png';
$secret-modal-banner-2x:        '/img/secret-deal-modal$2x.png';
$secret-modal-btn-bg:           #867e3a;
$secret-modal-title-color:      $brand-primary;
$secret-modal-text-color:       $brand-primary;

$secret-deal-outline:           '/img/secret-deal-outline.svg';
$secret-deal-sb-width:          80%;
$secret-deal-sb-height:         90%;
$secret-deal-sb-top:            5%;
$secret-deal-sb-left:           10%;
$secret-deal-sb-grid-height:    80%;
$secret-deal-sb-grid-top:       10%;
$secret-deal-sb-grid-left:      10%;

$secret-deal-price-bg:          '/img/secret-deal-price.svg';
$secret-deal-price-height:      102px;
$secret-deal-price-grid-width:  inherit;

$secret-deal-backdrop:          '/img/secret-deal-backdrop.jpg';
$secret-deal-emblem:            '/img/secret-deal-emblem.svg';
$secret-deal-emblem-width:      130px;
$secret-deal-emblem-height:     130px;
$secret-deal-emblem-pos-x:      -55px;
$secret-deal-emblem-pos-y:      -55px;
$secret-deal-color:             #ffd237;
$secret-deal-heading-color:     #623227;
$secret-deal-transform:         none;


$subheadings-font-family: $font-family-sans-serif;
$subheadings-font-weight: normal;

//== Filter styles
// --------------------------------------------------

$filter-tabs-bg:                    #88803d;
$filter-tabs-color:                 $brand-primary;
$filter-tabs-text-transform:        uppercase;

$filter-btn-bg:                     #88803d;
$filter-slider-handle-bg:           $brand-primary;
$filter-slider-track-bg:            $brand-tertiary;

$infowindow-bg:                     $brand-primary;
$poiwindow-bg:                      #ebd492;

$result-date-spacing:               8px 0;
$result-btn-spacing:                $result-date-spacing;

$cluster-bg:                        #6f6926;

$alert-padding:                     15px;

$alert-searchtag-bg:                fade(#000, 60%);
$alert-searchtag-text:              #fff;
$alert-searchtag-close:             $brand-primary;
$alert-searchtag-border:            transparent;

// $paragraph-body-bg:                 $body-bg;
// $paragraph-footer-bg:               $body-bg;

$paragraph-border-radius:           $border-radius-base;
$paragraph-border-color:            #ddd;
$paragraph-color:                   $gray-dark;

$paragraph-sm-height:               140px;      //** In the small variant paragraph is collapsed with read more button.


$media-object-width-sm:             96px;
$media-object-width-md:             120px;
$media-object-width-lg:             144px;
